import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { faEye, faEllipsisH, faRefresh} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import EvaluationForm from './EvaluationForm';

const config = require('../../config.json');

export default function ListEvaluation(props) {
    const {applicationDetails, setApplicationDetails, status, ref} = props;
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, handleAssessmentSubmission } = useContext(ProductContext);
    const [selectedItem, setSelectedItem]=useState();
    const [loaded, setLoaded] = useState(false);
    const [evaluateCollapsed, setEvaluateCollapsed] = useState(true);

    useEffect(()=>{
        //console.log("props : " , props);
        alertClose();
        fetchPendingEvaluationRecords();
    },[])

    const fetchPendingEvaluationRecords=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //const clientID="VJDEPTH";
            //const applicationStatus="Pending Evaluation";
            const selectedBatch="All";
            await axios.get(`${config.api.invokeURL}/dashboard/scores/evaluation?fetchid=${status}&fetchOption=${selectedBatch}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
             // console.log("Response : ", response);
                setApplicationDetails(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - Records Found"
                });  
                //console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        }
    }

    const ViewResponse=(event, item)=>{
      event.preventDefault();
      //console.log("Item : ", item);
      setSelectedItem(item);
      evaluateToggle();
    }

    const evaluateToggle=()=>{
      setEvaluateCollapsed(!evaluateCollapsed);
    }

    const handleReset=async(event, item)=>{
      event.preventDefault();
      try{
        //console.log("Item : ", item);
        setLoaded(false);
        const todayDate=generateDate();
        //const tassessmentID=questionID.questionID+"#"+loggedUser.nickname;
        const params={
          assessmentID:item.assessmentID,
          assessmentStatus:'ReInitiated',
          assessmentResponse:[],
          updatedBy:loggedUser.nickname,
          updatedOn:todayDate
      }
       //console.log("Params :", params);
      await handleAssessmentSubmission(params);
      //toggle();
        fetchPendingEvaluationRecords();
        setLoaded(true);
    
      }catch(error){
    
      }

    }

  return (
    <> 
    <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
          {/*Edit*/}
        <Modal toggle={evaluateToggle} isOpen={!evaluateCollapsed} style={{top:'10%', maxWidth:'800px'}}>
                    <ModalHeader toggle={evaluateToggle}>
                      Evaluation Sheet
                    </ModalHeader>
                    <ModalBody>    
                    <div>
                      <EvaluationForm selectedItem={selectedItem} evaluateToggle={evaluateToggle} fetchPendingEvaluationRecords={fetchPendingEvaluationRecords}/>                        
                      </div>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={evaluateToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Registration No</th>
                <th>Assessment ID</th>
                <th>Course Name</th>
                <th>Exam Type</th>
                <th>Exam Date</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {applicationDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.registrationNo}</td>
            <td>{item.assessmentID}</td>
            <td>{item.courseName}</td>
            <td>{item.examType}</td>
            <td>{item.examDate}</td>
           <td>
           {status==="Pending Evaluation"?<Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{ViewResponse(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> Evaluate
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>:
          <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={(event)=>{handleReset(event, item)}} >
              <FontAwesomeIcon icon={faRefresh} className="me-2" /> Reset
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>}
            </td>
            </tr>)}
            </tbody>
        </Table>
    </>
  )
}
