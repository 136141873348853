import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import RegistrationForm from "../Registrations/RegistrationForm";
import AcademicInfo from "../Registrations/AcademicInfo";
import FileAttachments from "../../components/attachments/FileAttachments";
import IDProof from "../Registrations/IDProof";
import CertificateProof from "../Registrations/CertificateProof";
import ScoreView from "../assessment/ScoreView";
import { Auth } from "aws-amplify";
import CourseDetailsForm from "../CoursesInfo/CourseDetailsForm";
import { CSVLink } from "react-csv";
import { downloadHeaders } from "../../ProData";
import LessonJourney from "../exam/LessonJourney";
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

//import { TransactionsTable } from "../../components/Tables";

export default () => {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, handleImageError } = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('Batch');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [courseCollapsed, setCourseCollapsed] = useState(true);
  const [viewCourseCollapsed, setViewCourseCollapsed] = useState(true);
  const [viewJourneyCollapsed, setViewJourneyCollapsed]=useState(true);
  const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
    occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
    addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
    const [idProof, setIDProof]=useState('');
  const [certProof, setCertProof]=useState('');
  const [idProofFile, setIDProofFile]= useState('');
  const [certProofFile, setCertProofFile]=useState('');
  const [imageFile, setImageFile] = useState('');
  const [selectedCourse, setSelectedCourse]=useState({courseName:'',courseOption:'', courseStatus:'', courseResult:'', courseCompletedOn:'', duration:'', fees:'', feesOpted:'', payOptions:'', payStatus:'', grade:''});
  const [selectedItem, setSelectedItem] = useState('');
  const [batchYear, setBatchYear]= useState([]);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }

  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }

  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSearchValue(date_year);
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=(event, item)=>{
    event.preventDefault();
    try{
    //  console.log("Item : ", item);
      const titem=item;
      if(titem.degreeDetails===undefined){
        titem.degreeDetail=[];
      }
      if(titem.idProofFile===undefined){
        titem.idProofFile="";
      }
      if(titem.certProofFile===undefined){
        titem.certProofFile="";
      }
      if(titem.avatar===undefined){
        titem.avatar="";
      }
    //  console.log("TITEM : " , titem);
      setProfileDetails(titem);
      const tapplicationNo= item.applicationNo
     // console.log("item : ", item.idproofFile);
     
      if(item.idproofFile===""||item.idproofFile===undefined||item.idproofFile===null){
        setIDProof('');
      }else{
        const tIDProofName=item.idproofFile;
       setIDProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
      }
      if(item.certproofFile===""||item.certproofFile===undefined||item.certproofFile===null){
       setCertProof('');
      }else{
     //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
      const tcertProofName=item.certproofFile;
      setCertProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
      }
 toggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }

  }

  const handleApplicationEdit=(event, item)=>{
    event.preventDefault();
    try{
   //   console.log("Item : ", item);
      const titem=item;
      if(titem.degreeDetails===undefined){
        titem.degreeDetails=[];
      }
      if(titem.idproofFile===undefined){
        titem.idproofFile="";
      }
      if(titem.certproofFile===undefined){
        titem.certproofFile="";
      }
      if(titem.avatar===undefined){
        titem.avatar="";
      }
  //    console.log("TITEM : " , titem);
      setProfileDetails(titem);
      const tapplicationNo= item.applicationNo
      if(item.idproofFile===""||item.idproofFile===undefined||item.idproofFile===null){
        setIDProof('');
      }else{
        const tIDProofName=item.idproofFile;
       setIDProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
      }
      if(item.certproofFile===""||item.certproofFile===undefined||item.certproofFile===null){
       setCertProof('');
      }else{
     //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
      const tcertProofName=item.certproofFile;
      setCertProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
      }
      newToggle();
    }catch(error){
      //console.log("Error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }

  }

  const handleAssignCourse =(event, item)=>{
    event.preventDefault();
    try{
    /*  console.log("Item : ", item);
      if(item.selectedCourse!==undefined){
        setSelectedCourse(item.selectedCourse);
      }*/
      setSelectedCourse(item.selectedCourse);
      setSelectedItem(item);
      courseToggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);

    }
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const courseToggle = () => {
  //   console.log("clicked");
        setCourseCollapsed(!courseCollapsed);
  }

  const journeyToggle = () => {
    //   console.log("clicked");
          setViewJourneyCollapsed(!viewJourneyCollapsed);
  }

  const viewScoresToggle =(item)=>{
    //console.log("item : ", item);
    setProfileDetails(item);
    setViewCourseCollapsed(!viewCourseCollapsed);
  }

  const viewJourneyToggle =(item)=>{
    //console.log("item : ", item);
    setProfileDetails(item);
    setViewJourneyCollapsed(!viewJourneyCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
      await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       //console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
       //     console.log("order status")
            setSearchResults(response.data);

        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - student's records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  /*const validateFields=()=>{
    const {title,firstName, dateOfBirth, gender, maritialStatus,
      occupation, religion, mobileNumber, emailID, addressStreet, addressState,
      addressPincode, nationality, motherTongue, englishProficiency}=profileDetails;
      var returnValue="passed";
      if(title===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Title cannot be Empty"
      }); 
      returnValue="failed";
    }else  if(firstName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Name cannot be Empty"
    }); 
    returnValue="failed";
  }else  if(dateOfBirth===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Date of Birth cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(gender===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Gender cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(maritialStatus===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Maritial Status cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(occupation===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Occupation cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(religion===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Religion cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(mobileNumber===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mobile Number cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(emailID===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Email Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(addressStreet==="" || addressPincode==="" || addressState===""){
      console.log(addressStreet, addressState, addressPincode);
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(nationality===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Nationality cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(motherTongue===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mother Tongue cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(englishProficiency===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "English Proficiency cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(imageFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach your Photo. Photo is must for registration!"
  }); 
  returnValue="failed";
  }else  if(idProofFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach one Govt approved photo ID proof! Its must."
  }); 
  returnValue="failed";
  }else  if(certProofFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach UG/PG certificate. It's mandatory for registration."
  }); 
  returnValue="failed";
  }else  {
    returnValue="passed";
  
  }
  window.scrollTo(0,0);
  return returnValue;
  }*/

  const postOnS3 = async(applicationno,timageFile,inputType)=>{
    try{
        //  console.log("NewImage : ", newImage);
        var tKeyName="";
        var newFileName="";
        if(inputType==="Profile"){
          tKeyName="avatar/"+applicationno;   
          newFileName=applicationno+".jpeg";
        }else if (inputType==="IDProof"){
          tKeyName="avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_idproof.jpeg"; 
        }else if (inputType==="CERTProof"){
          tKeyName="avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_certproof.jpeg"; 
        }   
      
      //console.log("newfilename :", newFileName);
     // const newImage="";
      var file = timageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const bucketName=config.s3.bucketName + tKeyName;
     // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
      await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
         //  console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
           // console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
     // console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    }
  
  }
  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
    alertClose();
   // const validationResult=validateFields();
   const validationResult="passed";
    if(validationResult==="passed"){

      setLoaded(false);
      const {registrationNo, title,firstName, middleName, lastName, dateOfBirth, gender, maritialStatus,
      occupation, religion, denomination, diocese, areaCode, mobileNumber, emailID, landlineNumber, addressStreet, addressState,
      addressPincode, nationality, motherTongue, englishProficiency, degreeDetails, idproofFile, avatar, certproofFile}=profileDetails;
      const clientID = "VJDEPTH";
      const applicationno= registrationNo;
      const tupdatedOn=await generateDate();
      //const applicationStatus="Pending Approval";
      var tavatar="";
      var tidproofFile="";
      var tcertproofFile="";
      if(imageFile){
        tavatar= applicationno+".jpeg";
      }else{
        tavatar=avatar;
      }
      if(idProofFile){
        tidproofFile=applicationno+"_idproof.jpeg"; 
      }else{
        tidproofFile=idproofFile;
      }
      if(certProofFile){
        tcertproofFile=applicationno+"_certproof.jpeg"; 
      }else{
        tcertproofFile=certproofFile;
      }
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const tapplicantName=firstName+" "+middleName+ " "+lastName;
      const params={
        clientID:clientID, registrationNo: applicationno, 
        title:title,firstName:firstName, middleName:middleName, lastName:lastName, dateOfBirth:dateOfBirth, gender:gender, maritialStatus:maritialStatus,
        occupation:occupation, religion:religion, denomination:denomination, diocese:diocese, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, landlineNumber:landlineNumber, addressStreet:addressStreet, addressState:addressState,
        addressPincode:addressPincode, nationality:nationality, motherTongue:motherTongue, englishProficiency:englishProficiency, selectedCourse:selectedCourse, degreeDetails:degreeDetails, updatedBy:loggedUser.nickname, updatedOn:tupdatedOn,
        idproofFile:tidproofFile, avatar:tavatar, certproofFile:tcertproofFile, applicantName:tapplicantName};
    //  console.log("Params : ", params);
      await axios.patch(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
       //   console.log("response : ", response); 
       if(imageFile){
          await postOnS3(applicationno, imageFile,"Profile");
       }
       if(idProofFile){
          await postOnS3(applicationno, idProofFile,"IDProof");
       }
       if(certProofFile){
          await postOnS3(applicationno, certProofFile,"CERTProof");
       }
       await handleSearch();
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: applicationno + " - Successfully Updated."
        }); 
        window.scrollTo(0,0);
        newToggle();
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      
      }catch(error){
  //      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }
  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  return (
  <>
    <Helmet>
      <title>Student Search - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Student Search - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Student Search - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
        
    {/*View*/}
    <div >
      <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
      <div  ref={componentModelRef}>
          <ModalHeader  toggle={toggle}>
            Registration Form - View
                </ModalHeader>
                <ModalBody >    
                    <RegistrationForm  formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails}/> 
                    <AcademicInfo  formType={"View"} form={profileDetails} setForm={setProfileDetails} />  
                    <FileAttachments  idProof={idProof} certProof={certProof} />    
                </ModalBody>
                <ModalFooter>
        {' '}
        <ReactToPrint
    content={reactToPrintContentModal}
    documentTitle="AwesomeFileName"
    
    trigger={reactToPrintTriggerModal}
  />
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>
      </div>
                
    </Modal>
    </div>
        
    {/*Edit*/}
    <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
                <ModalHeader toggle={newToggle}>
                Registration Form - Edit
                </ModalHeader>
                <ModalBody>    
                <div>
                  <RegistrationForm formType={"Edit"} profileDetails={profileDetails} setProfileDetails={setProfileDetails} setImageFile={setImageFile}/>
                  <Row>
                          <AcademicInfo  formType={"Edit"} form={profileDetails} setForm={setProfileDetails} alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
                        </Row>
                        <Row className="p-4">
                          <Col md={6}>
                          <IDProof fileName="test"  alertDetails={alertDetails} setAlertDetails={setAlertDetails} setIDProofFile={setIDProofFile}/>
                        
                          </Col>
                          <Col md={6}>
                          <CertificateProof fileName="test" alertDetails={alertDetails} setAlertDetails={setAlertDetails} setCertProofFile={setCertProofFile}/>

                          </Col>
                        </Row>
                        <FileAttachments idProof={idProof} certProof={certProof} />    
                        
                        <div className="mt-3 text-center">
                          <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
                        </div>
                    
                  </div>
                </ModalBody>
                <ModalFooter>
        {' '}
        <Button onClick={newToggle}>
            Close
        </Button>
        </ModalFooter>
    </Modal>

    {/*Assign Course*/}
    <Modal toggle={courseToggle} isOpen={!courseCollapsed} style={{top:'10%'}}>
      <ModalHeader toggle={courseToggle}>
      </ModalHeader>
      <ModalBody>    
      <div className="text-center">
        <CourseDetailsForm courseToggle={courseToggle} handleSearch={handleSearch} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} selectedItem={selectedItem}/>
        </div>
      </ModalBody>
    </Modal>

    {/*View Scores*/}
    <div>
    <Modal toggle={viewScoresToggle} isOpen={!viewCourseCollapsed} style={{top:'10%', maxWidth:'800px'}}>
        <ModalHeader toggle={viewScoresToggle}>
          Mark Sheet of {profileDetails.registrationNo}
        </ModalHeader>
        <ModalBody>    
        <div className="text-center">
        <ScoreView viewScoresToggle={viewScoresToggle} profileDetails={profileDetails}/>
          </div>
        </ModalBody>
        <ModalFooter>
        <Button onClick={viewScoresToggle}>
        Close
        </Button>
        </ModalFooter>
    </Modal>
    </div>

    {/*View Journey*/}
    <div>
    <Modal toggle={journeyToggle} isOpen={!viewJourneyCollapsed} style={{top:'10%'}}>
                <ModalHeader toggle={journeyToggle}>
                  Journey of {profileDetails.registrationNo}
                </ModalHeader>
                <ModalBody>    
                <div className="text-center">
                <LessonJourney profileDetails={profileDetails}/>
                  </div>
                </ModalBody>
                <ModalFooter>
                <Button onClick={journeyToggle}>
            Close
        </Button>
        </ModalFooter>
    </Modal>
    </div>

    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item>Students Details</Breadcrumb.Item>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
        <h4>Student Details</h4>
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
          />
        </ButtonGroup>
      </div>
    </div>
    <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
      <Col md={4} className="mb-3">
            <Form.Group id="searchstud">               
              <Form.Select defaultValue="ApplicationID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
              <option value="Batch">Batch</option>
              <option value="RegistrationID">Registration No</option>
              <option value="ApplicationID">Application No</option>
                <option value="CourseStatus">Course Status</option>
                <option value="FeeStatus">Fee Status</option>
              </Form.Select>
            </Form.Group>
          </Col>
        <Col  className="mb-3" md={8}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            {searchTopic==="Batch"?           
            <Form.Select value={searchValue} 
                onChange={(event)=>{setSearchValue(event.target.value)}}>
                {batchYear.map(item => {
                  return (
                  <option key={item} value={item}>{item}</option>
                  );
                })}
          </Form.Select>:
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
            <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
          </InputGroup>
        </Col>
      </Row>
    </div>

    <Table ref={componentRef} hover responsive>
            <thead>
              <tr>
              <th>
                  #
              </th>
              <th>
                  {searchTopic==="ApplicationID"? "Application No": "Registration No"}
              </th>
              <th>  
                  Name
              </th>
              <th>
                  Payment Status
                  </th>
              <th>
                  Course Type
              </th>
              <th>
                  Action
              </th>
              
              </tr>
          </thead>
          <tbody>
          {searchResults.map((item, index)=>
          <tr key={index}>
          <th scope="row">{index+1}</th>
          <td>{item.registrationNo}</td>
          <td><img id="profile" src={`${config.s3.profileUrl}${item.applicationNo}${"/"}${item.avatar}`} 
          onError={handleImageError}
          alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%"}} /> {item.title}.{item.applicantName}</td>
          <td>{item.coursePayment==="Partial"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Partial</p>:
          item.coursePayment==="Full"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Completed</p>:
          <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Pending</p>}</td>
          <td>{item.selectedCourse===undefined?null:<p> {item.selectedCourse.courseOption}</p>}</td>
          <td>
          <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
              <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{handleApplicationEdit(event, item)}}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{handleAssignCourse(event, item)}}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Update Course
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{viewScoresToggle(item)}}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> View Marks
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{viewJourneyToggle(item)}}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> View Journey
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
          </td>
          </tr>)}
          </tbody>
    </Table>
  </>
  );
};
