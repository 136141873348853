import React, {useEffect, useContext} from 'react';
import { Row, Col} from 'reactstrap';
import CoursePaymentInsights from './CoursePaymentInsights'
import RegistrationInsights from './RegistrationInsights'
import { ProductContext } from '../../ProductProvider';
//import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

export default function InsightsOverview() {
  //let history=useHistory();
  const { loggedUser, validateLogin, alertClose} = useContext(ProductContext);

  useEffect(()=>{
    alertClose();
    if(!loggedUser.isAuthenticated){
      validateLogin();
      /*const validationResult=validateLogin();
      if(validationResult==="Failed"){
        history.push(Routes.Signin.path);
      }else if(validationResult==="NOT Authorized"){
        history.push(Routes.Signin.path);
      }else{
        history.push(Routes.DashboardOverview.path);
      }*/
      }
  },[loggedUser])

  return (
      <>
          <Helmet>
      <title>Insights and Analytics - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Insights and Analytics - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Insights and Analytics - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

          <div className='container'>Insights Overview
            <Row>
            <Col md={6}>
                <RegistrationInsights />
            </Col>
            <Col md={6}>
                <CoursePaymentInsights />
            </Col>
          </Row> 
  </div>
      </>
  )
}
