import React, { useState, useEffect } from "react";
import Preloader from "../../components/Preloader";
import {Alert  } from "reactstrap";
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';
//import AddDoctor from "./AddDoctor";


export default function AddNewGallery(props) {
    const {setNewProjectName, setNewProjectDate, setImageFile, profileImage, alertDetails, alertClose}=props;
    const inputRef = React.useRef();
    const [loaded, setLoaded] = useState(true);

    useEffect(()=>{
      alertClose();
  },[])

   
    const handleFileSelection = (event)=>{
      setLoaded(false);
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
          setImageFile(event.target.files[0]);
          //console.log("selected file : ", event.target.files[0].name);
          var tprofile = document.getElementById("profile");
          tprofile.src=URL.createObjectURL(event.target.files[0]);
          /*setAlertDetails({
           ...alertDetails,
           style:"info",
           open:true,
           message:"File Selected - "+ event.target.files[0].name
       }); */  
      }
      setLoaded(true);
     }

  return (
    <div>
       <Preloader show={loaded ? false : true} />
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Gallery information</h5>
        <Alert color={alertDetails.style} fade="true" isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Gallery Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Gallery name"  onChange={(event)=>{setNewProjectName(event.target.value)}}/>
              </Form.Group>
            </Col>
           {/*} <Col md={6} className="mb-3">
              <Form.Group id="projectStatus">
                <Form.Label>Project Status</Form.Label>
                <Form.Select defaultValue="New" onChange={(event)=>{setNewProjectStatus(event.target.value)}}>
                  <option value="New">New</option>
                  <option value="Ongoing">Ongoing</option>
                  <option value="Completed">Completed</option>
                </Form.Select>
              </Form.Group>
  </Col>*/}
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="projectDate">
                <Form.Label>Date</Form.Label>
                <Form.Control required type="date" onChange={(event)=>{setNewProjectDate(event.target.value)}} />
              </Form.Group>
            </Col>
            {/*<Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Project Size</Form.Label>
                <Form.Select defaultValue="G" onChange={(event)=>{setNewProjectSize(event.target.value)}}>
                  <option value="G">G</option>
                  <option value="G-1">G-1</option>
                  <option value="G-2">G-2</option>
                  <option value="G-3">G-3</option>
                  <option value="G-4">G-4</option>
                  <option value="G-5">G-5</option>
                  <option value="G-6">G-6</option>
                  <option value="G-7">G-7</option>
                  <option value="G-8">G-8</option>
                  <option value="G-9">G-9</option>
                  <option value="G-10">G-10</option>

                </Form.Select>
              </Form.Group>
</Col>*/}
          </Row>
          <Row>
          <div style={{textAlign:"center"}}> 

          <img id="profile" src={profileImage} 
         //   onError={handleImageError}
            alt="Gallery Photo" style={{width:"12rem", height:"12rem"}} />
            <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
         // style={{ display: "none" }}
        />
        </div>
          </Row>
          
          {/*<Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Birthday</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={setBirthday}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={birthday ? moment(birthday).format("MM/DD/YYYY") : ""}
                        placeholder="mm/dd/yyyy"
                        onFocus={openCalendar}
                        onChange={() => { }} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select defaultValue="0">
                  <option value="0">Gender</option>
                  <option value="1">Female</option>
                  <option value="2">Male</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="name@company.com" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control required type="number" placeholder="+12-345 678 910" />
              </Form.Group>
            </Col>
          </Row>*/}

         {/*} <h5 className="my-4">Address</h5>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter your home address" />
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Number</Form.Label>
                <Form.Control required type="number" placeholder="No." />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" placeholder="City" />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Select state</Form.Label>
                <Form.Select id="state" defaultValue="0">
                  <option value="0">State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control required type="tel" placeholder="ZIP" />
              </Form.Group>
            </Col>
        </Row>*/}
        </Form>
      </Card.Body>
    </Card>

    </div>
  )
}
