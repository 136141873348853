import React,{ useState , useEffect} from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';

export default function OptedCourseDetails(props) {
    const {selectedCourse, formType} = props;
    const [viewOnly, setViewOnly] = useState(true);

    useEffect(() => {
        if(formType==="Edit"){
          setViewOnly(false);
        }else{
          setViewOnly(true);
        }
      }, [])
      
  return (
      <>
    <div>Course Details</div>
    <Row>
        <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Course</Form.Label>
                <Form.Control required type="email" value={selectedCourse.courseName}
                                  disabled={viewOnly}
                                //  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
        </Row>
        <Row>
        <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Course ID</Form.Label>
                <Form.Control required type="email" value={selectedCourse.courseID}
                                  disabled={viewOnly}
                                //  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>  <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Course Type</Form.Label>
                <Form.Control required type="email" value={selectedCourse.courseOption}
                                  disabled={viewOnly}
                                //  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
    </Row>
    <Row>
    <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Duration</Form.Label>
                <Form.Control required type="email" value={selectedCourse.duration}
                                  disabled={viewOnly}
                                //  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Fees</Form.Label>
                <Form.Control required type="email" value={selectedCourse.fees}
                                  disabled={viewOnly}
                                //  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
    </Row>
    </>
  )
}
