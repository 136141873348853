import React from 'react'
import { Card, CardBody } from 'reactstrap';
import workinprog from '../assets/img/work-in-progress.gif';


export default function InProgress() {
  return (
    <div className='container text-center'>
        <Card>
            <CardBody>

              
                <h1 className='text-info' style={{fontFamily:'sans-serif'}}>Work is In Progress... </h1>
                <img src={workinprog} alt='work-in-prog' />
            </CardBody>
        </Card>
        
    </div>
  )
}
