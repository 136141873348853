
export const Routes = {
    // pages
    //Presentation: { path: "/" },
    LandingPage: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    CourseMaterialInfo: { path: "/CourseMaterialInfo" },
    AssignMaterials:{path:"/assignMaterial"},
    CourseVideosInfo: { path: "/CourseVideosInfo" },
    CourseVideosAdd: { path: "/CourseVideosAdd" },

    CourseSchedular: { path: "/CourseSchedular" },
    StudentsDetails: {path: "/StudentsDetails"},
    StudentMgmt:{path:"/account"},
    ManageFee: {path: "/Fees/ManageFee"},
    ListFees: {path:"/Fees/List"},
    ManageRegistration: { path: "/ManageRegistration" },
    NewRegistration: {path:"/NewRegistration"},
    WelcomeDash: { path: "/welcome" },

    //inbox
    InboxNewNotification:{path:"/inbox/notification/new"},
    InboxOpenQuery:{path:"/inbox/query/open"},

    //exam
    ExamInfo: {path: "/examinfo"},
    AssignExam:{path: "/examassignment"},
    LessonJourney:{path: "/lessonjourney"},

    //auth
    Signin: { path: "/dashboard/sign-in" },
    Signup: { path: "/dashboard/sign-up" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/reset-password" },
    Lock: { path: "/dashboard/lock" },
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },
    InProgress: {path:"/dashboard/inprogress"},

    //evaluation
    evaluation:{path:"/dashboard/evaluation"},

    //Images
    ImageViewer: { path: "/imageviewer" },
    SliderViewer: { path: "/dashboard/slider-view" },
    GalleryViewer: { path: "/dashboard/gallery-view" },

    //InfoDesk
    InfoDesk:{path:"/dashboard/news-section"},

    //Students
    CoursesOffered: { path: "/courses-offered" },
    MyProfile: { path: "/dashboard/my-profile" },
    MyPayments: { path: "/dashboard/my-payments" },
    MyCourses: { path: "/dashboard/my-courses" },

    //Order
    OrderStatus:{path: "/studentcorner/order/orderstatus"},

    //staff
    StaffRegistration:{path:"/dashboard/admin/registration"},
    StaffProfile:{path:"/dashboard/admin/myprofile"},

    //insights
    Insights:{path:"/dashboard/insights"},
    StudentsPic:{path:"/dashboard/studentspic"}


};