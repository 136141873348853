import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Auth } from "aws-amplify";
import ImageDisplay from '../../components/manageimage/ImageDisplay';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { Button, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
import AddSlider from './AddSlider';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

//import constIcon from "../../assets/img/const-icon.png";

const config = require('../../config.json');


export default function SliderViewer() {
    const { validateLogin, alertClose , loggedUser, alertDetails, setAlertDetails} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [galleryImages, setGalleryImages] = useState('');
    const [collapsed, setCollapsed] = useState(true);

    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState('vjdepth-logo.png');
    const [newFileName, setNewFileName]=useState('');
    //const [newDoctorDept, setNewDoctorDept]=useState('');
    //const [newProjectStatus, setNewProjectStatus]=useState('');
    //const [newProjectDate, setNewProjectDate]=useState('');


    useEffect(() => {
        alertClose();
        if(loggedUser.isAuthenticated){
            fetchGalleryImages();  
        }else{
            validateLogin();
        }
    }, [loggedUser]);

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        setLoaded(false);
      
       // const imageid=imageName.substring(12,imageName.length);
       try{
           if(galleryImages.length<2){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Delete not allowed! Only one image left in Slider"
            }); 
           }else{
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            console.log("image : ", imageName  );
        await axios.get(`${config.api.invokeURL}/dashboard/images/remove?imageName=${imageName}`,{
            headers:{
                Authorization: access_token,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            //console.log(response);
            //setGalleryImages(response.data);
            //setDispMsg("Deleted Successfully");
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message:"Deleted Successfully"
            });   
            window.scrollTo(0,0);
            fetchGalleryImages();
        }).catch((error)=>{
           // setDispMsg(error.message);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }

    const toggle = () => {
     //   console.log("clicked");
          setCollapsed(!collapsed);
      }

    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
          //const fileName='images/gallery/';
         // const bucketName="images.stjosephhospitaldindigul.in";
      //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
        //const fileName='images/doctor/';
        const fileName='images/slider';
        const bucketName="images.vjdepth.in";
      await axios.get(`${config.api.invokeURL}/dashboard/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log("Response : ", response);
            const finalList=[];
            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
                const tPathName=tValue.split("/");
                const tBreakUp=tPathName[2];
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;
               // const tdoctorName=tBreakUp[1];
               // const tempDName=tdoctorName.split(".");
               // tempValue.doctorName=tempDName[0];
               // console.log("tempvalue : ", tempValue);
               // tempValue.projectSize=tBreakUp[2];
                //tempValue.projectDate=tBreakUp[3];
                finalList.push(tempValue);
              }
            }
      
           // console.log(finalList);
            setGalleryImages(finalList);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message:response.data.length + " - Images are found in Slider section"
            }); 
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
            //console.log(error.message);
            //setDispMsg(error.message);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }catch(error){
       // setDispMsg(error.message);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
}

/*const addImageOnS3=async()=>{
    try{ 
        setLoaded(false);
      
        const fileName=projectName+"_"+new Date().getTime();
      const newFileName=fileName+".jpeg";
      const bucketName=config.s3.bucketName+keyName;
      //console.log("bucket name : ", bucketName);
      //console.log("file name : ", newFileName);

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("access token : ", access_token);
      await axios.get(`${config.api.invokeURL}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
            // console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 toggle();

             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }
    setLoaded(true);

}*/

const postOnS3 = async(event)=>{
    event.preventDefault();
    try{ 
        if(imageFile===""||newFileName==="")
        {
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter all the details before Adding!"
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   

        }else{
        setLoaded(false);
        const fileName=newFileName
        //const fileName=projectName+"_"+new Date().getTime();
     // const newFileName=newDoctorDept+"$"+newDoctorName+".jpeg";
     const tnewFileName=fileName+".jpeg";
     const keyName='images/slider';

      const bucketName=config.s3.bucketName+keyName;
      //console.log("bucket name : ", bucketName);
      //console.log("file name : ", newFileName);

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("access token : ", access_token);
      await axios.get(`${config.api.invokeURL}/dashboard/images/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           //console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
            // console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 toggle();

             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }
    setLoaded(true);

  }

    return (
        <div> 
                <Helmet>
      <title>Slider - Website - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Slider - Website  - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Slider - Website - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

<h3>Slider</h3>
        <Preloader show={loaded ? false : true} />
              <Alert color={alertDetails.style} fade="true" isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
  {/* Add Doctor Images */}
            <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
                Add images            
            </ModalHeader>
            <ModalBody>
                {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} /> */}
                <AddSlider setNewFileName={setNewFileName} setImageFile={setImageFile} profileImage={profileImage} alertDetails={alertDetails} alertClose={alertClose}/>
    
            </ModalBody>
            <ModalFooter>
      <Button
        color="primary"
        onClick={(event)=>postOnS3(event)}
      >
        Add Image
      </Button>
      {' '}
      <Button onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter>
            </Modal>
 
            <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{toggle(event)}} variant="secondary" className="text-dark me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
       
            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', textAlign:'center'}}>
            {galleryImages.length>0? galleryImages.map((images, index)=><ImageDisplay 
            imageSrc={images.fullPathName} doctorName={images.fileName} key={images.fileName} handleDeleteImage={handleDeleteImage}/>):
            <div>No Images found in your Image Gallery</div>} 
            </div>

        </div>
    )
}
