import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {Breadcrumb, BreadcrumbItem  } from "reactstrap";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faHome, faEdit, faTrash, faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import {Modal, ModalBody, ModalHeader, ModalFooter, Table, Alert  } from "reactstrap";
//import AddImge from '../../components/manageimage/AddImge';
import ImageLoader from '../../components/manageimage/ImageLoader';
//import AddProject from '../../components/manageimage/AddProject';
//import EditGallery from '../../components/manageimage/EditProject';
import {Routes} from '../../routes';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import AddNewGallery from './AddNewGallery';
//import constIcon from "../../assets/img/const-icon.png";
import EditGallery from './EditGallery';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function GalleryViewer() {
    const [loaded, setLoaded] = useState(false);
    //const history=useHistory();
    const { validateLogin, alertClose , loggedUser, alertDetails, setAlertDetails} = useContext(ProductContext);
    const [galleryImages, setGalleryImages]=useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [newCollapsed, setNewCollapsed] = useState(true);
    const [editCollapsed, setEditCollapsed] = useState(true);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState('vjdepth-logo.png');
    const [keyName, setKeyName]=useState('');
    const [projectName, setProjectName]=useState('');
    const [projectDate, setProjectDate]=useState('');
    const [newProjectName, setNewProjectName]=useState('');
    const [newProjectSize, setNewProjectSize]=useState('');
    const [newProjectStatus, setNewProjectStatus]=useState('');
    const [newProjectDate, setNewProjectDate]=useState('');
    const [selectedProject, setSelectedProject]=useState('');

    useEffect(() => {
        //fetchGalleryImages();
        alertClose();  
       if(loggedUser.isAuthenticated){
            fetchGalleryImages();
        }else{
            validateLogin();
        }
            
    }, [loggedUser]);

    /*const postOnS3 = async()=>{
        try{
            const fileName="Gallery_"+new Date().getTime();
          const newFileName=fileName+".jpeg";
          const bucketName=config.s3.bucketName+"/gallery";
          //console.log("bucket name : ", bucketName);
          var file = imageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
          //console.log("New file : ", newFile);
          await axios.get(`${config.api.invokeUrl}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
               headers:{
                   //Authorization: access_token,
                  // 'x-api-key': config.api.key
               }
           }).then(async(response)=>{
            //   console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"image/jpeg"
                 },body: newFile});
                // console.log("Res : ", res);
                 if(res.status===200){
                    fetchGalleryImages();
                     setProfileImage(newFileName);
                     //var timestamp = new Date().getTime();     
                     var tprofile = document.getElementById("profile");
                     //var imgsrc="";
                     //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                     //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                     //console.log("tprofle : ", tprofile.src);
    
                 }
            }).catch((error)=>{
                console.log("error1:", error);
             // setDispMsg(error.message);
             // hideLoader();
           })
        }catch(error){
          console.log("error1:", error);
        }
    
      }*/

    const toggle = () => {
           //console.log("clicked");
             setCollapsed(!collapsed);
         }

    const newToggle = () => {
    //console.log("clicked");
        alertClose();
        setNewCollapsed(!newCollapsed);
    }

    const editToggle = () => {
        //console.log("clicked");
            setEditCollapsed(!editCollapsed);
        }
    

    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
           //console.log("Testing");
           const session = await Auth.currentSession();
           const access_token=session.idToken.jwtToken;
            const fileName='images/gallery/';
            const bucketName="images.vjdepth.in";
    
        await axios.get(`${config.api.invokeURL}/dashboard/images/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log(response.data);
            setGalleryImages(response.data);
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length + " - Project Images are available"
            });  
            setLoaded(true);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
        }).catch((error)=>{
            //console.log(error.message);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
            //setDispMsg(error.message);
        })
    }catch(error){
		//console.log(error.message);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0);   
        //setDispMsg(error.message);
    }
}

const handleProjectView=(event, item)=>{
    event.preventDefault();
    //console.log("key : ", item);
    const tfoldername=item.folderName.substring(0, item.folderName.length-1);
    //console.log("TFoldername : ", tfoldername);
    setKeyName(tfoldername);
    setProjectName(item.projectName);
    toggle();
}

const handleProjectEdit=(event, item)=>{
    event.preventDefault();
   // console.log("Item : ", item);
    setKeyName(item.folderName);
    setProjectName(item.projectName);
    setProjectDate(item.projectDate);
    setNewProjectName(item.projectName);
    setNewProjectStatus(item.projectStatus);
    setNewProjectSize(item.projectSize);
    setNewProjectDate(item.projectDate);
    setSelectedProject(item);
    editToggle();


}

const handleProjectDelete =async(event, item)=>{
    event.preventDefault();
    //console.log("Item : ", item);
    try{
        //const bucketName=config.s3.bucketName;
        const inputParams={
            fileName:item
        }
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        await axios.post(`${config.api.invokeURL}/dashboard/images/gallery/delgallery`, inputParams,{
    //await axios.get(`${config.api.invokeUrl}/images/projects/delproject?fileName=${item}&bucketName=${bucketName}`,{
        headers:{
            Authorization: access_token,
            'x-api-key': config.api.key
        }
    }).then(async(response)=>{
        //console.log("Response : ", response);
        fetchGalleryImages();
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message:"Project & Images successfully removed"
        }); 
        window.scrollTo(0,0);   
    }).catch((error)=>{
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);   

    });
}catch(error){
    setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);   
}

}

const postOnS3 = async()=>{
    try{
        
        if(newProjectDate===""||newProjectName===""||imageFile===""){
           // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter all details along with an image"
            }); 
            window.scrollTo(0,0); 
        }else{        
      setLoaded(false);
      const fileName=newProjectName+"_"+new Date().getTime();
      const newFileName=fileName+".jpeg";
      const newKeyName=newProjectName+"$"+newProjectDate;
      const tKeyName="images/gallery/"+newKeyName;
      const bucketName=config.s3.bucketName+tKeyName;
      //console.log("bucket name : ", bucketName);
      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;     
      await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
        //   console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             //console.log("Res : ", res);
             if(res.status===200){
              //  fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 setLoaded(true);
                 newToggle();
                 fetchGalleryImages();


             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }

  }

const editOnS3 = async()=>{
    try{
      //console.log("newProject name : ", newProjectName, newProjectDate);
        if(newProjectDate===""||newProjectName===""){
           // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Gallery Name or Gallery Date cannot be empty!"
            }); 
            window.scrollTo(0,0); 
            editToggle();
        }else if(newProjectDate===projectDate && newProjectName===projectName){
          // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
           setAlertDetails({
               ...alertDetails,
               style:"warning",
               open:true,
               message:"No Changes made to the Gallery"
           }); 
           window.scrollTo(0,0); 
           editToggle();
          }else{         
        setLoaded(false);
        //const fileName=newProjectName+"_"+new Date().getTime();
      //const newFileName=fileName+".jpeg";
      const newKeyName=newProjectName+"$"+newProjectDate;
      const tKeyName="images/gallery/"+newKeyName;
      //const bucketName=config.s3.bucketName+tKeyName;
      
      //console.log("bucket name : ", bucketName);
      //var file = imageFile;
      //var blob = file.slice(0, file.size, 'image/jpeg'); 
      //const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const inputParams={
        fileName:selectedProject,
        newKeyName:newKeyName
    }
    //console.log("Params : ", inputParams);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;       
    await axios.post(`${config.api.invokeURL}/dashboard/images/gallery/editgallery`, inputParams,{
    //  await axios.get(`${config.api.invokeUrl}/images/projects/newproject?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
        //   console.log("Response : ", response);
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: newProjectName+ "  - Successfully Modified"
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
        editToggle();
        fetchGalleryImages();

        /*  const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 newToggle();
                 fetchGalleryImages();
             }*/
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }

  }


  return (
    <div> 
    <Helmet>
      <title>Gallery - Website - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Gallery - Website - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Gallery - Website - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
      <FontAwesomeIcon icon={faHome} />
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      My Gallery
    </BreadcrumbItem>
  </Breadcrumb>
        <h3>My Gallery</h3>
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
  {/*show images*/}
            <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
                {projectName} Images            
            </ModalHeader>
            <ModalBody>
                {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} />     */}
                <ImageLoader keyName={keyName} projectName={projectName} alertClose={alertClose} alertDetails={alertDetails} setAlertDetails={setAlertDetails} orginalfetchGalleryImages={fetchGalleryImages}/>
            </ModalBody>
            <ModalFooter>
    {/*}  <Button
        color="primary"
        onClick={postOnS3}
      >
        Save Image
      </Button>
  {' '}*/}
      <Button onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
            </Modal>
{/*modal for creating new project*/}
            <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={newToggle}>
                Create New Gallery            
            </ModalHeader>
            <ModalBody>
                {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} />     */}
            <AddNewGallery setNewProjectName={setNewProjectName} setNewProjectSize={setNewProjectSize} setNewProjectStatus={setNewProjectStatus} setNewProjectDate={setNewProjectDate} setImageFile={setImageFile} profileImage={profileImage} alertDetails={alertDetails} alertClose={alertClose}/>
                    </ModalBody>
            <ModalFooter>
      <Button
        color="primary"
        onClick={postOnS3}
      >
        Create Gallery       
        </Button>
  {' '}
      <Button onClick={newToggle}>
        Close
      </Button>
    </ModalFooter>
            </Modal>
{/*modal for editing projects*/}
            <Modal toggle={editToggle} isOpen={!editCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={editToggle}>
                Edit Project - {newProjectName}          
            </ModalHeader>
            <ModalBody>
                {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} />     */}
            <EditGallery newProjectName={newProjectName} newProjectStatus={newProjectStatus} newProjectSize={newProjectSize} newProjectDate={newProjectDate} setNewProjectName={setNewProjectName} setNewProjectSize={setNewProjectSize} setNewProjectStatus={setNewProjectStatus} setNewProjectDate={setNewProjectDate} alertClose={alertClose}/>
                    </ModalBody>
            <ModalFooter>
      <Button
        color="primary"
        onClick={editOnS3}
      >
        Edit Project       
        </Button>
  {' '}
      <Button onClick={editToggle}>
        Close
      </Button>
    </ModalFooter>
            </Modal>
    

        <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{newToggle(event)}} variant="secondary" className="text-dark me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>Create New Gallery</span>
          </Dropdown.Toggle>
          </Dropdown>

       <Table hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Gallery Name
                </th>
                <th className='text-center'>
                    Gallery Date
                </th>
                <th className='text-center'>
                    Images Uploaded
                </th>
                <th>
                    Action
                </th>
                
                </tr>
            </thead>
            <tbody>
            {galleryImages.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.projectName}
            </td>
            <td className='text-center'>
                {item.projectDate}
            </td>
            <td className='text-center'>
                {item.fileName.length}
            </td>
            <td>
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleProjectView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View & Add Images
              </Dropdown.Item>
                <Dropdown.Item onClick={(event)=>{handleProjectEdit(event, item)}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Gallery Project
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleProjectDelete(event, item)}}>
                <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete Gallery
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>

            {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleProjectView(event, item)}}/>
            <FontAwesomeIcon icon={faEdit} className="me-2" onClick={(event)=>{handleProjectEdit(event, item)}}/>
            <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleProjectDelete(event, item)}}/>

            <button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>
            </tr>)}
            </tbody>
            </Table>

    </div>
  )
}
