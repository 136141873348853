import React, { useEffect, useState, useContext } from 'react';
//import { courseMaterialData } from '../../ProData';
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { faEye, faEdit, faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import TermScoreView from './TermScoreView';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import ScoreEdit from './ScoreEdit';
const config = require('../../config.json');

export default function ScoreView(props) {
    const { alertClose, alertDetails, setAlertDetails } = useContext(ProductContext);
    const {viewScoresToggle, profileDetails} = props;
    const [myAssessmentSheet, setMyAssessmentSheet]=useState([]);
    const [termAssessment, setTermAssessment] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [editCollapsed, setEditCollapsed] = useState(true);
    const [formType, setFormType] = useState("View");
    const [loaded, setLoaded] = useState(false);
    const [selectedScore, setSelectedScore]=useState();


    useEffect(()=>{
        processingData();
    },[])

    const processingData=async()=>{
      try{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        //const clientID="VJDEPTH";
        const registrationno=profileDetails.registrationNo;
        await axios.get(`${config.api.invokeURL}/dashboard/scores/${registrationno}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log("Res : ", response);
            setMyAssessmentSheet(response.data);
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length +" - Score Sheet Found"
            });  
            //console.log(response);
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        })  
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    }
    }

   /* const initializeValues=(tempData)=>{
        var uniqueArray = removeDuplicates("courseCode", tempData);
        var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        //setCoursesAvailable(sortedcourseArray);
       // console.log("Courses Name : ", sortedcourseArray);
      //  setUniqueCourseCode(sortedcourseArray);
       /* uniqueArray = removeDuplicates("courseOption");
        var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        setCourseOptionAvailable(sortedOptionArray);
        setOptedCourse({...optedCourse,
          courseName:sortedcourseArray[0].courseName,
          courseOption:sortedOptionArray[0].courseOption
        });
    }*/
  
    /*  const removeDuplicates=(tprop, rawData)=> {
        var newArray = [];
        var lookupObject  = {};
        for(var i in rawData) {
           lookupObject[rawData[i][tprop]] = rawData[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }*/

    const handleDetailsView=(event, item, Type)=>{
        event.preventDefault();
     //   console.log("Item : ", item);
        toggle();
        setFormType(Type);
        setTermAssessment(item);

    }

    const toggle = () => {
        //   console.log("clicked");
        
             setCollapsed(!collapsed);
    }

    const editToggle = () => {
      //   console.log("clicked");
      
           setEditCollapsed(!editCollapsed);
    }
  
    const handleAssignScorecardTemplate=async(event, mode, item)=>{
        event.preventDefault();
        if(mode==="View"||mode==="Edit"){
          setSelectedScore(item);
        }
        setFormType(mode);
        editToggle();
    }

//        try{
  //          var tcourseOpted="";
    //        var tselectedQ=[];
         //   if(selectedCourse===undefined){
           /* for(const i in courseMaterialData){
              //  console.log("selected Course : ", coursesOffered[i] );
                if(coursesOffered[i].courseName===optedCourse.courseName && coursesOffered[i].courseOption===optedCourse.courseOption){
                //  console.log("selected Course : ", coursesOffered[i] );
                  //setSelectedCourse(coursesOffered[i]);
                  tcourseOpted=coursesOffered[i];
                }
              }*/
            //   tcourseOpted=courseMaterialData.find(item=>item.courseTitle===optedCourse.courseName);
             //  tselectedQ=sampleQuestionTable.filter(item=>item.courseName===optedCourse.courseName);
  
            //} 
       //       const todayDate=generateDate();
            //  const session = await Auth.currentSession();
            //  const access_token=session.idToken.jwtToken;
             // const tquestionID = tcourseOpted.courseCode+"-"+(tselectedQ.length+1);
      /*        const params={
                //clientID:"VJDEPTH",
                  registrationNo:profileDetails.registrationNo,
                  courseCode:tcourseOpted.courseCode,
                  courseName:tcourseOpted.courseTitle,
                  assessmentStatus:'',
                  totalMarksObtained:'',
                  totalMarks:'',
                  Grade:'',
                  assessmentCategory:'',
                  assessmentDetails:[],
                  createdBy:loggedUser.nickname,
                  createdOn:todayDate,
                  updatedBy:loggedUser.nickname,
                  updatedOn:todayDate,
                  coursesOffered:courseMaterialData
              }
            //  console.log("Params : ", params);
            /*  await axios.post(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  console.log("Response : ", response);
                //fetchApplicationDetails();
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Successfully Updated"
                }); 
                window.scrollTo(0,0);
                //courseToggle();
                //handleSearch();
                  setLoaded(true);            
                })
                .catch((error)=>{
                  console.log("Error :", error);
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })*/
        /*      }catch(error){
                console.log("Error :", error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true); 
          }
    }*/

  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        {/*View Score*/}
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={toggle}>
                    </ModalHeader>
                    <ModalBody>  
                        <TermScoreView  termAssessment={termAssessment} formType={formType}/>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        
        {/*Edit Score*/}
        <Modal toggle={editToggle} isOpen={!editCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={editToggle}>
            </ModalHeader>
            <ModalBody>  
                <ScoreEdit formType={formType} selectedScore={selectedScore} profileDetails={profileDetails} editToggle={editToggle} processingData={processingData}/>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={editToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        {myAssessmentSheet.length>0?<Table hover responsive className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Course</th>
                <th>Status</th>
                <th>Marks</th>
               <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {myAssessmentSheet.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td><p>{item.courseCode}</p><p>{item.courseName}</p></td>
            <td>{item.assessmentResult==="PASS"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Pass</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Pending</p>}</td>
            <td>{item.totalMarksObtained}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
             <Dropdown.Item   onClick={(event)=>{handleAssignScorecardTemplate(event,"View", item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Scores
            </Dropdown.Item>
            <Dropdown.Item className="text-success" onClick={(event)=>{handleAssignScorecardTemplate(event,"Edit", item)}}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Scores
            </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
        </Table>  : <div><p>No Score Card Found</p></div>}
        <div>
        <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} variant="secondary" className="text-dark me-2" onClick={(event)=>{handleAssignScorecardTemplate(event, "New")}}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
</div>
    </>
  )
}
