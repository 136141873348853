import React, { useState } from "react";
import Preloader from "../../components/Preloader";
//import {Alert  } from "reactstrap";
import { Row, Card, Form } from '@themesberg/react-bootstrap';


export default function UploadCoursePDF(props) {
    const { setImageFile }=props;
    const inputRef = React.useRef();
    const [loaded, setLoaded] = useState(true);

    
   
    const handleFileSelection = (event)=>{
      setLoaded(false);

        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
          setImageFile(event.target.files[0]);
          //console.log("selected file : ", event.target.files[0].name);
       //   var tprofile = document.getElementById("profile");
        //  tprofile.src=URL.createObjectURL(event.target.files[0]);
          /*setAlertDetails({
           ...alertDetails,
           style:"info",
           open:true,
           message:"File Selected - "+ event.target.files[0].name
       }); */  
      }
      setLoaded(true);
     }

  return (
    <div>
       <Preloader show={loaded ? false : true} />
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Upload PDF Document</h5>
       {/*} <Alert color={alertDetails.style} fade="true" isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>*/}
        <Form>
          <Row>
           {/*} <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Slider Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Slider name"  onChange={(event)=>{setNewFileName(event.target.value)}}/>
              </Form.Group>
  </Col>*/}
           {/*} <Col md={6} className="mb-3">
              <Form.Group id="projectStatus">
                <Form.Label>Deptartment</Form.Label>
                <Form.Select defaultValue="New" onChange={(event)=>{setNewDoctorDept(event.target.value)}}>
                  <option value="New">General</option>
                  <option value="Ongoing">Ongoing</option>
                  <option value="Completed">Completed</option>
                </Form.Select>
              </Form.Group>
  </Col>*/}
          </Row>
         
          <Row>

            <input
          type="file"
          accept="application/pdf"
          ref={inputRef}
          onChange={handleFileSelection}
         // style={{ display: "none" }}
        />
          </Row>
          
        </Form>
      </Card.Body>
    </Card>

    </div>
  )
}
