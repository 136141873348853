import React,{useState, useContext, useEffect} from 'react';
//import VideoPlayer from './VideoPlayer';
import Preloader from "../../components/Preloader";
import {Helmet} from 'react-helmet-async';
import { Card} from 'react-bootstrap';
import { Col, Row, Button, Dropdown, Breadcrumb} from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { faHome, faBackward, faEdit, faPlayCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
//import { faReadme } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Routes} from '../../routes';
import { Link } from "react-router-dom";
import { ProductContext } from '../../ProductProvider';
import { Auth } from "aws-amplify";
import axios from 'axios';
import AddVideos from './AddVideos';
import AddSectionVideos from './AddSectionVideos';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import 'video-react/dist/video-react.css'; // import css

const config = require('../../config.json');

export default function DisplayVideos() {
    const { alertDetails, setAlertDetails, alertClose, videoFormType, setVideoFormType, setVideoDetails} = useContext(ProductContext);
    const [loaded, setLoaded]=useState(true);
    const [showVideo, setShowVideo]=useState(false);
    const [videoResults, setVideoResults] = useState([]);
    const [videoFile, setVideoFile]=useState(null);
    const [collapsed, setCollapsed] = useState(true);
    const [collapsedAdd, setCollapsedAdd] = useState(true);
    const [selectedItem, setSelectedItem] = useState();
    const [currentlyPlaying, setCurrentlyPlaying]=useState("A");
    const playerRef = React.useRef(null);

    useEffect(() => {
      fetchVideoDetails();
    }, [])

    const fetchVideoDetails=async()=>{
      try{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        //const clientID="VJJournal";
        const searchOption="Status";
        const searchValue="Active";
        await axios.get(`${config.api.invokeURL}/video/search?searchOption=${searchOption}&searchValue=${searchValue}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
          //console.log("Response : ", response);
          setVideoResults(response.data);
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length + " - " + searchValue + " Videos Found"
            });  
            //console.log(response);
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            //setDispMsg(error.message);
        })
    
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    }
    }

    const handleFetchVideo = async(e, item, tempFile) => {
      e.preventDefault();
      //console.log("fetch : ", tempFile);
      try{
        setLoaded(false);
        setVideoFile('');
        setCurrentlyPlaying(tempFile);
        const fetchFile=item.videoID+tempFile+".mp4";
        //console.log("fetch file :", fetchFile);
        const bucketName='docs.vjdepth.in/videos/'+item.videoID;
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        await axios.get(`${config.api.invokeURL}/video/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
       ).then(async(response)=>{
            //console.log("Response : ", response);
            setVideoFile(response.data.downloadURL);
            setSelectedItem(item);
            setShowVideo(true); 
            //console.log("plaer yer ref : ", playerRef);

            //playerRef.load();
            setLoaded(true);
            /*await axios.get(response.data.downloadURL,{
              responseType:'arraybuffer',
              headers: {
                "Content-Type": "application/json",
                "Accept":"application/pdf",
                //"content-disposition": "attachment; filename=test.pdf",
                "access-control-allow-origin" : "*",
              }
            })
            .then(function(pdfresponse){
             console.log("pdf response : ", pdfresponse);
             let blob = new Blob([pdfresponse.data], {type: 'application/pdf'});
             const link = document.createElement('a');
             link.href = URL.createObjectURL(blob);
             console.log("link.href : ", link);
            // setPdfFile(pdfresponse.data);
            // setShowPDF(true); 
   
            })*/
        }).catch((error)=>{
            //console.log("Error1 : ", error);
            setLoaded(true);
            //setDispMsg("Error Occured - ", error.message);
        })        
      }catch(error){
          //console.log("Error2 : ", error);
          setLoaded(true);
      }
  }

  const handleBack=(e)=>{
    e.preventDefault();
    setVideoFile('');
    setShowVideo(false); 
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const toggleAdd = (item) => {
    //   console.log("clicked");
         setCollapsedAdd(!collapsedAdd);
         setSelectedItem(item);
  }

  const handleEditVideo=(e, item,action)=>{
    e.preventDefault();
    try{
      setVideoFormType(action);
      setVideoDetails(item);
      toggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }
    }

    const handleAddVideo=(e,action)=>{
      e.preventDefault();
      try{
        setVideoFormType(action);
        //setVideoDetails(item);
        toggle();
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      }
      }
    
  return (
    <>
     <Helmet>
      <title>Course Videos - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Course Videos - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Course Videos - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>      <Link to={Routes.LandingPage.path}>
            <FontAwesomeIcon icon={faHome} /></Link>    </Breadcrumb.Item>
            <Breadcrumb.Item>Course</Breadcrumb.Item>
            <Breadcrumb.Item active>Videos</Breadcrumb.Item>
          </Breadcrumb>       
        </div>
       {/*} <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Evaluation"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadAssessmentHeaders}
              filename="download.csv"
              data={applicationDetails}
              ref={csvLinkEl}
            />
          </ButtonGroup>
  </div>*/}
       
      </div>

    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>

        {/*Add Videos*/}
        <div >
          <Modal toggle={toggleAdd} isOpen={!collapsedAdd} style={{top:'10%', maxWidth:'800px'}} >
          <div>
             <ModalHeader  toggle={toggleAdd}>
               Course Video Form - {videoFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <AddSectionVideos toggleAdd={toggleAdd}  selectedItem={selectedItem} setSelectedItem={setSelectedItem}/> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            
            <Button onClick={toggleAdd}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

     {/*AddorEdit Videos*/}
     <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div>
             <ModalHeader  toggle={toggle}>
               Course Video Form - {videoFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <AddVideos toggle={toggle} fetchVideoDetails={fetchVideoDetails}/> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

    <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{handleAddVideo(event,"New")}} variant="secondary" className="text-dark me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>Add New Course Category</span>
          </Dropdown.Toggle>
          </Dropdown>
          <Row> 

    {!showVideo && videoResults.length>0 ?<> {videoResults.map((item,index)=><>
               
        <Col md={4} key={index} className="mb-3">
         
            <Card className='p-2'>
            <Card.Img className='img-responsive'  variant="top" src={`${config.s3.imginvokeUrl}${item.videoID}.jpeg`} />
                <Card.Body className='d-flex justify-content-between'>
                <Card.Title>{item.videoTitle}<br/> <span style={{fontSize:"12px", textAlign:'right'}}>{item.publishDate}</span></Card.Title>
            
              <div className='p-3' style={{textAlign:'center', fontSize:"26px"}} ><FontAwesomeIcon icon={faPlayCircle} onClick={event=>{handleFetchVideo(event, item,"A")}}/> <br/>
                </div>  
                </Card.Body>
                <Card.Footer className='d-flex justify-content-between'>
                <span style={{fontSize:"12px", textAlign:'left'}}> No. Of Videos : {item.videoPartDetails.length} </span>
                    <FontAwesomeIcon icon={faEdit} onClick={event=>{handleEditVideo(event, item,"Edit")}}/>
                    <FontAwesomeIcon icon={faPlus} onClick={event=>{toggleAdd(item)}}/>
            </Card.Footer> 
    </Card>

    {/*
     <Card
        >
        <VideoPlayer videoFile={item.sourceURL}/>
        <p>{item.videoTitle}</p>
        <FontAwesomeIcon icon={faEdit} onClick={event=>{handleEditPDF(event, item,"Edit")}}/>

  </Card>*/}
        </Col>
        
    </>)}</>:null}
    </Row>
    {showVideo && <div style={{height:800}}>
      <div className='p-2'>
      <FontAwesomeIcon icon={faBackward} onClick={event=>{handleBack(event)}}/> Go Back
      </div>
      <Row >
        {/*    
         <Card>
         <Card.Img style={{ width: '18rem', height:'18rem' }} variant="top" src={`${config.s3.imginvokeUrl}${selectedItem.videoID}.jpeg`} />
             <Card.Body className='d-flex justify-content-between'>
             <Card.Title>{selectedItem.videoTitle}<br/> <span style={{fontSize:"12px", textAlign:'right'}}>{selectedItem.publishDate}</span></Card.Title>
             </Card.Body>
             <Card.Footer className='d-flex justify-content-between'>
             <span style={{fontSize:"12px", textAlign:'left'}}> No. Of Videos : {selectedItem.videoPartDetails.length} </span> <br/>

         </Card.Footer> 
      <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {selectedItem.videoPartDetails.map((item, index)=>
          <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.title}</td>
            <td>{currentlyPlaying===item.id?"Ready to Play": <FontAwesomeIcon icon={faPlayCircle} onClick={event=>{handleFetchVideo(event, selectedItem, item.id)}}/>} </td>
          </tr>)}
          </tbody>
        </Table>
        
 </Card>
*/}
 {/*
  <Card
     >
     <VideoPlayer videoFile={item.sourceURL}/>
     <p>{item.videoTitle}</p>
     <FontAwesomeIcon icon={faEdit} onClick={event=>{handleEditPDF(event, item,"Edit")}}/>

</Card>*/}
     </Row>
     {videoFile?<Card style={{ width: '20', height:'5' }} > 
     
  <div className='d-flex justify-content-between '>
   
   <Row className='p-2'> 
<h3 style={{textAlign:"left", fontSize:"15px"}}>{selectedItem.videoTitle}</h3>
     </Row>


   
<Row className='p-2'>  

{selectedItem.videoPartDetails.map((item, index)=>
            <>{currentlyPlaying===item.id?<Col style={{color:"blue", textDecoration:"underline", fontSize:"22px"}}>{index+1}</Col>:<Col style={{fontSize:"16px"}} onClick={event=>{handleFetchVideo(event, selectedItem, item.id)}}>{index+1} </Col>}</>)}
 

 </Row>
 
  
      
   
     
 </div>
 
    <Player ref={playerRef}
          autoPlay>
      <LoadingSpinner />
      
      <BigPlayButton position="center" />
      <source src={videoFile} />
    </Player>
  </Card>:null}

     {/*} <VideoPlayer videoFile={videoFile} selectedItem={selectedItem}/>*/}
      </div>}
    </>
  )
}
