import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEye, faHome, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Dropdown, Table } from '@themesberg/react-bootstrap';
//import {Input } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import FeeCollection from "./FeeCollection";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { feesBreakUpFull, feesBreakUpPhase1, feesBreakUpPhase2 } from '../../ProData';
import Preloader from "../../components/Preloader";
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { downloadFeeHeaders } from "../../ProData";

//import { TransactionsTable } from "../../components/Tables";
import HistoryView from "./HistoryView";
const config = require('../../config.json');

export default () => {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [batchYear, setBatchYear] = useState([]);
  const [selectedBatch, setSelectedBatch]=useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loaded, setLoaded]=useState(true);
  const componentRef = React.useRef(null);
  const [collapsed, setCollapsed]=useState(true);
  const [historyCollapsed, setHistoryCollapsed]=useState(true);
  const [selectedItem, setSelectedItem] = useState('');
  const [courseOpted, setCourseOpted] = useState('');
  const [payMsg, setPayMsg] = useState('');
  const [currentPayStatus, setCurrentPayStatus] = useState('');
  const [payAmount, setPayAmount] = useState('');
  const [enrollCourse, setEnrollCourse]=useState({});
  const [feeBreakUp, setFeeBreakUp] =useState([]); 
  const [searchParam] = useState(["firstName","applicationNo"]);
  const csvLinkEl = React.createRef();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSelectedBatch(date_year);
    fetchCurrentYearDetails(date_year);
  }

  const fetchCurrentYearDetails=async(searchValue)=>{
   // event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const searchTopic="BatchFeeStatus";
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
      //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {  
           headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        if(response.data.length>0)
        {
            //console.log("response :", response.data);
            setSearchResults(response.data);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Fee Details loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const fetchApplicationDetails=async(event, searchValue)=>{
    event.preventDefault();
    fetchCurrentYearDetails(searchValue);
   /* try{
      if(batchYear===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const searchTopic="Batch";
      await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
       //   Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
      // console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
       //     console.log("order status")
            setSearchResults(response.data);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Profile loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }*/
  }
  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
     }

     const historyToggle = () => {
      //   console.log("clicked");
           setHistoryCollapsed(!historyCollapsed);
       }
  

     const handlePayOptions=(event, newPayOption)=>{
      event.preventDefault();
      //console.log("new option : ", newPayOption);
    //  console.log("Enrolled course : ", enrollCourse);
      processPayAmount(newPayOption);
    }

    const processPayAmount=(newPayOption)=>{
         var tenrollCourse=enrollCourse;
         setPayMsg("");
         var tpayAmount="";
         console.log("current pay status : ", currentPayStatus);
         console.log("payoption : ", newPayOption, "-", tenrollCourse.courseOption);
      if(currentPayStatus==="Pending" && newPayOption==="Phase2" && tenrollCourse.courseOption==="Online"){
          setPayMsg("You cannot directly Pay Phase 2 without paying Phase 1");
          tpayAmount=3200;
         /* tenrollCourse.payOptions="Full";
          tenrollCourse.courseOption="Online";
          setPayAmount(6600);
          setEnrollCourse(tenrollCourse);*/
 
      }else if(currentPayStatus==="Pending" && newPayOption==="Phase2" && tenrollCourse.courseOption==="Correspondence"){
         setPayMsg("You cannot directly Pay Phase 2 without paying Phase 1");
      /*   tenrollCourse.payOptions="Full";
         tenrollCourse.courseOption="Correspondence";
         setPayAmount(7000);
         setEnrollCourse(tenrollCourse);*/
         tpayAmount=3400;
 
     }else if(currentPayStatus==="Partial" && newPayOption==="Full" && tenrollCourse.courseOption==="Online"){
      setPayMsg("Phase 1 payment already Completed. You cannot select Full as payment option now. Please select Phase 2");
      tpayAmount=6600;
     /* tenrollCourse.payOptions="Full";
      tenrollCourse.courseOption="Online";
      setPayAmount(6600);
      setEnrollCourse(tenrollCourse);*/
      console.log("current pay status : ", currentPayStatus);

  }else if(currentPayStatus==="Partial" && newPayOption==="Full" && tenrollCourse.courseOption==="Correspondence"){
    setPayMsg("Phase 1 payment already Completed. You cannot select Full as payment option now. Please select Phase 2");
    /*   tenrollCourse.payOptions="Full";
     tenrollCourse.courseOption="Correspondence";
     setPayAmount(7000);
     setEnrollCourse(tenrollCourse);*/
     tpayAmount=7000;
     console.log("current pay status : ", currentPayStatus);

 }else if(currentPayStatus==="Partial" && newPayOption==="Phase1" && tenrollCourse.courseOption==="Online"){
  setPayMsg("Phase 1 payment already Completed. You cannot select Phase 1 again as payment option now. Please select Phase 2");
  tpayAmount=3400;
 /* tenrollCourse.payOptions="Full";
  tenrollCourse.courseOption="Online";
  setPayAmount(6600);
  setEnrollCourse(tenrollCourse);*/
  console.log("current pay status : ", currentPayStatus);

}else if(currentPayStatus==="Partial" && newPayOption==="Phase1" && tenrollCourse.courseOption==="Correspondence"){
  setPayMsg("Phase 1 payment already Completed. You cannot select Phase 1 again as payment option now. Please select Phase 2");
/*   tenrollCourse.payOptions="Full";
 tenrollCourse.courseOption="Correspondence";
 setPayAmount(7000);
 setEnrollCourse(tenrollCourse);*/
 tpayAmount=3600;
 console.log("current pay status : ", currentPayStatus);
}
else{
      if(tenrollCourse.courseOption==="Online" && newPayOption==="Full"){
      tpayAmount=6600;   
      }else if(tenrollCourse.courseOption==="Online" && newPayOption==="Phase1"){
      tpayAmount=3400;   
      }
      else if(tenrollCourse.courseOption==="Online" && newPayOption==="Phase2"){
      tpayAmount=3200;   
      }
      if(tenrollCourse.courseOption==="Correspondence" && newPayOption==="Full"){
      tpayAmount=7000;   
      }else if(tenrollCourse.courseOption==="Correspondence" && newPayOption==="Phase1"){
      tpayAmount=3600;   
      }
      else if(tenrollCourse.courseOption==="Correspondence" && newPayOption==="Phase2"){
      tpayAmount=3400;      
      }
  
      var tfeeBreakup=[];
      if(newPayOption==="Full"){
          tfeeBreakup=feesBreakUpFull;
      }else if(newPayOption==="Phase1"){
          tfeeBreakup=feesBreakUpPhase1;

      }else if(newPayOption==="Phase2"){
          tfeeBreakup=feesBreakUpPhase2;
      }
      setFeeBreakUp(tfeeBreakup);
      tenrollCourse.feesOpted=tpayAmount;
      tenrollCourse.payOptions=newPayOption;
  //  tcart.push(tenrollCourse);
  //  console.log("Final course details : ", tpayAmount);
  //    console.log("Final course details : ", tenrollCourse);
  //    setOrderDetails(tenrollCourse);
      setPayAmount(tpayAmount);
      setEnrollCourse(tenrollCourse);
     }
          console.log("Final course details : ", tenrollCourse);

     }

     const handlePaymentPage=(event, item)=>{
       event.preventDefault();
       setCourseOpted(item.selectedCourse);
       setEnrollCourse(item.selectedCourse);
       setCurrentPayStatus(item.coursePayment);
       setPayAmount(item.selectedCourse.fees);
       setSelectedItem(item);
       toggle();
     }

     const handleHistoryView=(event, item)=>{
       event.preventDefault();
       setSelectedItem(item);
       historyToggle();
     }

     const [q, setQ] = useState("");

     function search(items) {
      return items.filter((item) => {
          return searchParam.some((newItem) => {
              return (
                  item[newItem]
                      .toString()
                      .toLowerCase()
                      .indexOf(q.toLowerCase()) > -1
              );
          });
      });
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

return (
    <>

    <Helmet>
      <title>Fee Management - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Fee Management - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Fee Management - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
      </Alert>
     {/*Make Payment*/}
     <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}} >
          <div >
             <ModalHeader toggle={toggle}>
                    </ModalHeader>
                    <ModalBody>
                      <FeeCollection processBatchYear={processBatchYear} selectedItem={selectedItem} payMsg={payMsg} enrollCourse={enrollCourse} setEnrollCourse={setEnrollCourse} 
                      payAmount={payAmount} fetchCurrentYearDetails={fetchCurrentYearDetails} selectedBatch={selectedBatch}
               processPayAmount={processPayAmount} handlePayOptions={handlePayOptions} currentPayStatus={currentPayStatus} feeBreakUp={feeBreakUp} toggle={toggle}/>    
                    </ModalBody>
                    <ModalFooter>
            {' '}
           
            </ModalFooter>
          </div>
                   
        </Modal>

        </div>

        {/*Payment History*/}
     <div >
          <Modal toggle={historyToggle} isOpen={!historyCollapsed} style={{top:'10%'}} >
          <div >
             <ModalHeader toggle={historyToggle}>
                    </ModalHeader>
                    <ModalBody>
                      <HistoryView selectedItem={selectedItem} />
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={historyToggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
     
        </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Fee Management</Breadcrumb.Item>
            <Breadcrumb.Item active>View</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Fee Details</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadFeeHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings">
        <Row className="justify-content-between align-items-center">
        <Col md={6} className="mb-3">
        <Form.Group className="div-inline" id="searchstud">
           <Form.Select defaultValue="Cash"  value={selectedBatch} 
              onChange={(event)=>{setSelectedBatch(event.target.value)}}>
             {batchYear.map(item => {
                return (
                <option key={item} value={item}>{item}</option>
                );
              })}
           </Form.Select>
        </Form.Group>
      </Col>
       <Col md={6}>
       <Button variant="primary" type="submit" onClick={(event)=>{fetchApplicationDetails(event, selectedBatch)}}>Search</Button>
      </Col>
      </Row>
      </div>

      <Table ref={componentRef} hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Registration No
                </th>
                <th>
                    Name
                </th>
                <th>Course Type</th>
                <th>
                    Course Status
                    </th>
                <th>
                    Payment Status
                </th>
               <th>
                    Action
                </th>
                
                </tr>
            </thead>
            <tbody>
            {search(searchResults).map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.registrationNo}
            </td>
            <td>
               {item.title}.{item.applicantName}
            </td>
            <td>{item.selectedCourse && item.selectedCourse.courseOption ? <p>{item.selectedCourse.courseOption}</p> : null}</td>
            <td>{item.courseStatus==="Enrolled"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Enrolled</p>:
            item.courseStatus==="Approved"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Approved</p>:
            item.courseStatus==="Active"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.courseStatus}</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Course Not Assigned</p>}</td>
            <td>              
            {item.coursePayment==="Partial"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Partial</p>:
            item.coursePayment==="Full"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Completed</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Pending</p>}</td>
            
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleHistoryView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> Payment History
              </Dropdown.Item>
             {item.coursePayment==="Full" || item.courseStatus==="na" || !item.selectedCourse ? null:<Dropdown.Item onClick={(event)=>{handlePaymentPage(event, item)}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Accept Payment
              </Dropdown.Item>}

            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>
    </>
  );
};
