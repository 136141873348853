import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { faEye, faEllipsisH, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Button} from '@themesberg/react-bootstrap';
import RegistrationForm from './RegistrationForm';
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import FileAttachments from '../../components/attachments/FileAttachments';
import AcademicInfo from './AcademicInfo';
import OptedCourseDetails from './OptedCourseDetails';
import { Auth } from 'aws-amplify';
const config = require('../../config.json');

export default function ListRegistrationStatus(props) {
    const {applicationDetails, setApplicationDetails, status} = props;
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, handleImageError } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    //const [selectedItem, setSelectedItem]=useState('');
  //  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
  const [collapsed, setCollapsed] = useState(true);
  const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
    occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
    addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
    const [idProof, setIDProof]=useState('');
  const [certProof, setCertProof]=useState('');


    useEffect(()=>{
        //console.log("props : " , props);
        alertClose();
        fetchApplicationDetails();
    },[])

    const toggle = () => {
        //   console.log("clicked");
        
             setCollapsed(!collapsed);
         }

    const fetchApplicationDetails=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const clientID="VJDEPTH";
            const applicationStatus=status;
            await axios.get(`${config.api.invokeURL}/dashboard/registration?clientID=${clientID}&applicationStatus=${applicationStatus}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
                setApplicationDetails(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - " + status
                });  
                //console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        }
    }

    const handleApplicationView=(event, item)=>{
        event.preventDefault();
     //   console.log("Item : ", item);
        try{
          const titem=item;
          if(titem.degreeDetails===undefined){
            titem.degreeDetail=[];
          }
          if(titem.idProofFile===undefined){
            titem.idProofFile="";
          }
          if(titem.certProofFile===undefined){
            titem.certProofFile="";
          }
          if(titem.avatar===undefined){
            titem.avatar="";
          }
        //  console.log("TITEM : " , titem);
          setProfileDetails(titem);
          const tapplicationNo= item.applicationNo
         // console.log("item : ", item.idproofFile);
         
          if(item.idproofFile===""||item.idproofFile===undefined||item.idproofFile===null){
            setIDProof('');
          }else{
            const tIDProofName=item.idproofFile;
           setIDProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
          }
          if(item.certproofFile===""||item.certproofFile===undefined||item.certproofFile===null){
           setCertProof('');
          }else{
         //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
          const tcertProofName=item.certproofFile;
          setCertProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
          }
    
         /*   setSelectedItem(item);
            setProfileDetails(item);
            const tapplicationNo= item.applicationNo
           // const tIDProofName=tapplicationNo+"_idproof.jpeg";
          // console.log("item : ", item.idproofFile);
           if(item.idproofFile===""){
             setIDProof('');
           }else{
             const tIDProofName=item.idproofFile;
            setIDProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
           }
           if(item.certproofFile===""){
            setCertProof('');
           }else{
          //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
          const tcertProofName=item.certproofFile;
            setCertProof(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
           }*/
            toggle();
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
        }
    }
    const handleApplicationUpdate=async(event, item, tStatus)=>{
        event.preventDefault();
      //  console.log("Item : ", item);
        try{
            
            const tDate= await generateDate();
            var tcoursePayment="";
            if(tStatus==="Approved"){
              tcoursePayment="Pending";
            }else{
              tcoursePayment="Declined";
            }
          const session = await Auth.currentSession();
         const access_token=session.idToken.jwtToken;

            const params={
                clientID:item.clientID,
                applicationNo:item.applicationNo,
                applicationDate:item.applicationDate,  
                areaCode:item.areaCode, 
                mobileNumber:item.mobileNumber,
                gender:item.gender,
                applicationStatus:tStatus,
                emailID:item.emailID,
                dateOfBirth:item.dateOfBirth,
                title:item.title,
                firstName:item.firstName,
                updatedBy:loggedUser.name,
                updatedOn:tDate,
                courseStatus:tStatus,
                coursePayment:tcoursePayment,
                middleName:item.middleName,
                lastName:item.lastName, 
                maritialStatus:item.maritialStatus,
                occupation:item.occupation, 
                religion:item.religion, 
                denomination:item.denomination, 
                diocese:item.diocese, 
                areaCode:item.areaCode, 
                mobileNumber:item.mobileNumber,
                landlineNumber:item.landlineNumber, 
                addressStreet:item.addressStreet, 
                addressState:item.addressState,
                addressPincode:item.addressPincode, 
                nationality:item.nationality, 
                motherTongue:item.motherTongue, 
                englishProficiency:item.englishProficiency, 
                selectedCourse:item.selectedCourse, 
                degreeDetails:item.degreeDetails, 
                avatar:item.avatar, 
                idproofFile:item.idproofFile, 
                certproofFile:item.certproofFile, 
                applicantName:item.applicantName
            }
            await axios.patch(`${config.api.invokeURL}/dashboard/registration`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                //  console.log("Response : ", response);
                fetchApplicationDetails();
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Successfully Updated"
                }); 
                window.scrollTo(0,0);
                  setLoaded(true);            
                })
                .catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })
          
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
        }
    }
  return (
      <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*View*/}
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={toggle}>
                    </ModalHeader>
                    <ModalBody>    
                        <RegistrationForm formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails}/> 
                        <OptedCourseDetails formType={"View"} selectedCourse={profileDetails.selectedCourse} />
                        <AcademicInfo formType={"View"} form={profileDetails} setForm={setProfileDetails} />  
                        <FileAttachments idProof={idProof} certProof={certProof} />    
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Application No</th>
                {status==="Approved"?<th>Registration No</th>:null}
                <th>Name</th>
                <th>Phone</th>
                <th>Date Applied</th>
                <th>Course Type</th>
               {status==="Pending Approval"||status==="Approved"? <th>Action</th>:null}
                </tr>
            </thead>
            <tbody>
            {applicationDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.applicationNo}</td>
            {status==="Approved"?<td>{item.registrationNo}</td>:null}
            <td><img id="profile" src={`${config.s3.profileUrl}${item.applicationNo}${"/"}${item.avatar}`} 
           onError={handleImageError}
           alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%"}} />  {item.title}.{item.applicantName}</td>
            <td>{item.areaCode}-{item.mobileNumber}</td>
            <td>{item.applicationDate}</td>
            <td>{item.selectedCourse===undefined?null:<p> {item.selectedCourse.courseOption}</p>}</td>
           {status==="Pending Approval"? <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item   onClick={(event)=>{handleApplicationView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Approve
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}>
                <FontAwesomeIcon icon={faCancel} className="me-2" /> Deny
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleApplicationView(event, item)}}/>
            <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}/>
            <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}/>
            
            <button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>:status==="Approved"? <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
             {/*} <Dropdown.Item className="text-success" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}>
                <FontAwesomeIcon icon={faCancel} className="me-2" /> Deny
          </Dropdown.Item>*/}
            </Dropdown.Menu>
          </Dropdown>
            </td>:null}
            </tr>)}
            </tbody>
            </Table>

    </>
  )
}
