import React, {useState, useEffect, useContext} from 'react';
import {ProductContext} from "../../ProductProvider";
import { coursesOffered,courseMaterialData} from '../../ProData';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Preloader from "../../components/Preloader";

const config = require('../../config.json');

export default function QuestionSheetForm(props) {
    const {questionTable, selectedCourse} = props;
    const { alertDetails, setAlertDetails, generateDate, loggedUser, alertClose } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [coursesAvailable, setCoursesAvailable]= useState([]);
    const [courseOptionAvailable, setCourseOptionAvailable]= useState([]);
    // const [selectedCourse, setSelectedCourse]=useState({});
    const [optedCourse, setOptedCourse]=useState({courseName:'Introduction to Scripture',courseCode:'1Bb01',assessmentCategory:'Online', assessmentStatus:'Active', assessmentType:'YesNo'});
    const viewOnly=false;

    useEffect(()=>{
      //console.log("Props : ", props);
      alertClose();
      if(selectedCourse===undefined){
        initializeValues();
      }else{
        setOptedCourse(selectedCourse);
      }
      setLoaded(true);
    },[])

    const initializeValues=()=>{
      var uniqueArray = removeDuplicates("courseName");
      var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
      setCoursesAvailable(sortedcourseArray);
      //console.log("Courses Name : ", sortedArray);
      uniqueArray = removeDuplicates("courseOption");
      var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
      setCourseOptionAvailable(sortedOptionArray);
    }

    const removeDuplicates=(tprop)=> {
      var newArray = [];
      var lookupObject  = {};
      for(var i in coursesOffered) {
         lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
      }
  
      for(i in lookupObject) {
          newArray.push(lookupObject[i]);
      }
       return newArray;
  }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
          var tcourseOpted="";
          var tselectedQ=[];
          if(selectedCourse===undefined){
         /* for(const i in courseMaterialData){
            //  console.log("selected Course : ", coursesOffered[i] );
              if(coursesOffered[i].courseName===optedCourse.courseName && coursesOffered[i].courseOption===optedCourse.courseOption){
              //  console.log("selected Course : ", coursesOffered[i] );
                //setSelectedCourse(coursesOffered[i]);
                tcourseOpted=coursesOffered[i];
              }
            }*/
             tcourseOpted=courseMaterialData.find(item=>item.courseTitle===optedCourse.courseName);
             tselectedQ=questionTable.filter(item=>item.courseName===optedCourse.courseName);
          } 
            const todayDate=generateDate();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const tquestionID = tcourseOpted.courseCode+"-"+(tselectedQ.length+1);
            const params={
              //clientID:"VJDEPTH",
              //registrationNo:selectedItem.registrationNo,
                courseCode:tcourseOpted.courseCode,
                questionID:tquestionID,
                courseName:tcourseOpted.courseTitle,
                assessmentCategory:optedCourse.assessmentCategory,
                assessmentAssignedTo:"NA",
                assessmentOpenDate:"NA",
                assessmentEndDate:"NA",
                assessmentQuestions:[],
                assessmentAnswers:[],
                assessmentStatus:optedCourse.assessmentStatus,
                assessmentType:optedCourse.assessmentType,
                createdBy:loggedUser.nickname,
                createdOn:todayDate,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate
            }
            //console.log("Params : ", params);
            await axios.post(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }})
              .then(async(response)=>{
                console.log("Response : ", response);
              //fetchApplicationDetails();
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Successfully Updated"
              }); 
              window.scrollTo(0,0);
              //courseToggle();
              //handleSearch();
                setLoaded(true);            
              })
              .catch((error)=>{
                console.log("Error :", error);
                  setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Error Occured : " + error.message
                }); 
                window.scrollTo(0,0);
                setLoaded(true);
              })
            }catch(error){
              console.log("Error :", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true); 
        }
    }

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>

    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <h5 className="mb-4">Question Sheet</h5>
         <Form>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Course</Form.Label>
                 <Input type="select" name="courseName"
                                  value={optedCourse.courseName} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseName:(event.target.value)})}}
                                >
                                  {courseMaterialData.map(item => {
                                return (<option key={item.courseTitle} value={item.courseTitle}>{item.courseTitle}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Assessment Type</Form.Label>
                 <Input type="select" name="courseOption"
                                 disabled={viewOnly}
                                  value={optedCourse.assessmentType} 
                                  onChange={(event)=>{setOptedCourse({...optedCourse, assessmentType:(event.target.value)})}}
                                >
                  <option value="YesNo">YesNo</option>
                  <option value="MCQ">MCQ</option>
                  <option value="Descriptive">Descriptive</option>
                  </Input>
               </Form.Group>
         </Row>
         <Row>
    <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Question Sheet Status</Form.Label>
                <Form.Select value={optedCourse.assessmentStatus}
                disabled={viewOnly}
                onChange={(event)=>{setOptedCourse({...optedCourse, assessmentStatus:event.target.value})}}
                >
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </Form.Select>
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Question Category</Form.Label>
                <Form.Select value={optedCourse.assessmentCategory}
                disabled={viewOnly}
                onChange={(event)=>{setOptedCourse({...optedCourse, assessmentCategory:event.target.value})}}
                >
                  <option value="Online">Online</option>
                  <option value="Offline">Offline</option>
                  <option value="Hybrid">Hybrid</option>
                </Form.Select>
              </Form.Group>
            </Col>
    </Row>

         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Update</Button>
           </div>
         </Form>
         </Card.Body>
      </Card>
    </>
  )
}
