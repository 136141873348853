import React, {useEffect, useState, useContext} from 'react';
import { Form, Col, Row, Card, Button, Table } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Preloader from "../../components/Preloader";
import {Alert } from "reactstrap";

const config = require('../../config.json');

export default function TermScoreView(props) {
    const {termAssessment, formType} = props;
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate } = useContext(ProductContext);
    const [examDetails, setExamDetails] = useState({assessmentID: "", assessmentMode: "", assessmentType:"", assessmentTakenDate: "", assessmentResponse: "", assessmentObtainedMarks: "", assessmentTotalMarks: "", assessmentRemarks: "",assessmentResult: "",evaluatedBy: null, evaluatedOn:""});
  //  const [checkValue, setCheckValue] = useState();
    const [pageReady, setPageReady] = useState(false);
   // const [assessmentDetails, setAssessmentDetails] = useState([]);
   const [formMode, setFormMode] = useState("");
   const [loaded, setLoaded] = useState(true);

    useEffect(()=>{
        setPageReady(true);
    },[])

   /* const processLocalFields=()=>{
        const ttemp=termAssessment.assessmentDetails;
        const tValue=ttemp.map(item=>item.assessmentID);
        const tlocal={};
        const tarray=[];
        for(const i in ttemp){
            tlocal.id=ttemp[i].assessmentID;
            tlocal.isChecked=false;
            tarray.push(tlocal);
        }
        console.log("tValue : ", tarray);
        setCheckValue(tarray);
    }*/

    const  handleCheckProcess=(event, item)=>{
        event.preventDefault();
        setExamDetails(item);
        setFormMode("Update");
    }

    const handleUpdate=async(event)=>{
        event.preventDefault();
        try{
            var assessmentValues="";
            if(formMode==="Add"){
                 assessmentValues= processAddAssessmentDetails();
            }else{
                 assessmentValues= processAssessmentDetails();
            }
            const todayDate=generateDate();
            const params={
                registrationNo:termAssessment.registrationNo,
                courseCode:termAssessment.courseCode,
                assessmentDetails:assessmentValues,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate
            }
          //  console.log("Params : ", params);
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const clientID="VJDEPTH";
          const registrationno=termAssessment.registrationNo;
          await axios.patch(`${config.api.invokeURL}/dashboard/scores/${registrationno}`,params,{
              headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
          ).then((response)=>{
             // console.log("Res : ", response);
              //setMyAssessmentSheet(response.data);
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length +" - Score Sheet Found"
              });  
              //console.log(response);
              setLoaded(true);
          }).catch((error)=>{
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
          })  
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        }
    }

    const processAddAssessmentDetails=()=>{
          const tempResponse={};
          const tempAssessment=termAssessment.assessmentDetails;
          tempResponse.assessmentID=examDetails.assessmentID;
          tempResponse.assessmentMode=examDetails.assessmentMode;
          tempResponse.assessmentType=examDetails.assessmentType;
          tempResponse.assessmentTakenDate=examDetails.assessmentTakenDate;
          tempResponse.assessmentObtainedMarks=examDetails.assessmentObtainedMarks;
          tempResponse.assessmentTotalMarks=examDetails.assessmentTotalMarks;
          tempResponse.assessmentRemarks=examDetails.assessmentRemarks;
          tempResponse.assessmentResult=examDetails.assessmentResult;
          tempResponse.evaluatedBy=examDetails.evaluatedBy;
          tempResponse.evaluatedOn=examDetails.evaluatedOn; 
          tempAssessment.push(tempResponse);
          console.log("Exam Details : ", tempAssessment);
          // questionResponse=tempResponse[index];
         //  setAssessmentDetails(tempResponse);
           return tempAssessment;
  
 
     }
 

    const processAssessmentDetails=()=>{
       // console.log("Exam Details : ", examDetails);
        const tempResponse=termAssessment.assessmentDetails;

       // var tempResponse=[...response];
       //  console.log("Temp Response : ", tempResponse);
 
         const tindex= tempResponse.find(titem => titem.assessmentID === examDetails.assessmentID)
         const index =tempResponse.indexOf(tindex);
      //   console.log("Temp Response : ", tempResponse[index]);
         tempResponse[index].assessmentMode=examDetails.assessmentMode;
         tempResponse[index].assessmentType=examDetails.assessmentType;
         tempResponse[index].assessmentTakenDate=examDetails.assessmentTakenDate;
         tempResponse[index].assessmentObtainedMarks=examDetails.assessmentObtainedMarks;
         tempResponse[index].assessmentTotalMarks=examDetails.assessmentTotalMarks;
         tempResponse[index].assessmentRemarks=examDetails.assessmentRemarks;
         tempResponse[index].assessmentResult=examDetails.assessmentResult;
         tempResponse[index].evaluatedBy=examDetails.evaluatedBy;
         tempResponse[index].evaluatedOn=examDetails.evaluatedOn; 
         // questionResponse=tempResponse[index];
        //  setAssessmentDetails(tempResponse);
          return tempResponse;
 

    }

    const handleAdd=(event)=>{
        event.preventDefault();
        const newID=termAssessment.assessmentDetails.length+1;
      //  console.log("New ID : ", newID);
        setExamDetails({...examDetails, assessmentID:newID});
        setFormMode("Add");

    }


  return ( 
      <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

    <div>TermScoreView</div>
    <Button active variant="outline-primary" size="sm" onClick={(event)=>{handleAdd(event)}}>Add</Button>


    {pageReady?
    <Table hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Exam Date
                </th>
                <th>
                   Question Type
                </th>
                <th>
                    Exam Mode
                    </th>
                <th>
                    Marks
                </th>    
                 <th>
                    Remarks
                </th>   
                             
                </tr>
            </thead>
            <tbody>
            {termAssessment.assessmentDetails && termAssessment.assessmentDetails.map((item, index)=>
            <tr key={index}>
          {formType==="Edit"?  <th scope="row">
            <Form.Check type="radio" id={index} name="test" value={item.assessmentID} onClick={(event)=>handleCheckProcess(event, item)}/>
            </th>:<span>{index+1}</span>}
            <td>
                {item.assessmentTakenDate}
            </td>
            <td>
                {item.assessmentType}
            </td>
            <td>
                {item.assessmentMode}
            </td>
            <td>
               {item.assessmentObtainedMarks}
            </td>
            <td>
               {item.assessmentRemarks}
            </td>
            </tr>)}
            </tbody>
            </Table>  :null}


            {formType==="Edit"?<div>
                <Row>
        <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">{formMode} Assessment Details</h5>
        <Form>
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="coursetitle">
                <Form.Label>Assessment ID</Form.Label>
                <Form.Control required type="text" placeholder="Diocese" value={examDetails.assessmentID}
                disabled/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Mode</Form.Label>
                <Form.Select defaultValue="Online" value={examDetails.assessmentMode}
                onChange={(event)=>setExamDetails({...examDetails, assessmentMode:event.target.value})}>
                  <option value="Online">Online</option>
                  <option value="Offline">Offline</option>
                </Form.Select>
              </Form.Group>
            </Col>
            
          </Row>
          <Row>
            
            
          </Row>
          <Row className="align-items-center">
          <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Question Type</Form.Label>
                <Form.Select defaultValue="Yes/No" value={examDetails.assessmentType}
                onChange={(event)=>setExamDetails({...examDetails, assessmentType:event.target.value})}>
                  <option value="Yes/No">Yes/No</option>
                  <option value="MCQ">Multiple Choice</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="optional">
                <Form.Label>Exam Date</Form.Label>
                <Form.Control required type="date" value={examDetails.assessmentTakenDate}
                onChange={(event)=>setExamDetails({...examDetails, assessmentTakenDate:event.target.value})}/>
              </Form.Group>
            </Col>
          </Row>
         
          <Row>
          <Col sm={6} className="mb-3">
              <Form.Group id="examdays">
                <Form.Label>Total Marks</Form.Label>
                <Form.Control required type="text" value={examDetails.assessmentTotalMarks}
                onChange={(event)=>setExamDetails({...examDetails, assessmentTotalMarks:event.target.value})}/>
              </Form.Group>
            </Col>
             <Col sm={6} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Label>Marks Obtained</Form.Label>
                <Form.Control required type="number" value={examDetails.assessmentObtainedMarks}
                onChange={(event)=>setExamDetails({...examDetails, assessmentObtainedMarks:event.target.value})}/>
              </Form.Group>
            </Col>
            
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="mark">
                <Form.Label>Result</Form.Label>
                <Form.Control required type="number" placeholder="Enter Max Marks" value={examDetails.assessmentResult}
                onChange={(event)=>setExamDetails({...examDetails, assessmentResult:event.target.value})} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="fileupload">
                <Form.Label>Remarks</Form.Label>
                <Form.Control required type="number" placeholder="" value={examDetails.assessmentRemarks}
                onChange={(event)=>setExamDetails({...examDetails, assessmentRemarks:event.target.value})} />
              </Form.Group>
            </Col>
          </Row>
          
          
         
          <div className="mt-3 text-center">
            
            <Button variant="primary" type="submit" onClick={(event)=>{handleUpdate(event)}}>{formMode==="Add"?"Add":"Update"}</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
        </Col>

            </Row>
            </div>:null} 
    </>
  )
}
