import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Form, Button, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Alert  } from "reactstrap";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { payOptionsData } from '../../ProData';
const config = require('../../config.json');

export default function FeeCollection(props) {
    const { generateOrderNo, alertDetails, setAlertDetails, alertClose} = useContext(ProductContext);
    const [paymentMode, setPaymentMode] = useState("Cash");
    const [transactionID, setTransactionID] = useState('');
    const [bankName, setBankName] = useState('');
    const {selectedItem, currentPayStatus, feeBreakUp, toggle, fetchCurrentYearDetails, selectedBatch} = props;
    const [todayDate, setTodayDate] = useState('');
    const [loaded, setLoaded]=useState(true);
    const [payOptions, setPayOptions] = useState([]);
    const [selectedOptionDetails, setSelectedOptionDetails] = useState({});
    const [enrollCourse, setEnrollCourse] = useState(selectedItem.selectedCourse);
    const [concessionEnabled, setConcessionEnabled]=useState(false);
    const [concessionValue, setConcessionValue]=useState(0);
    const [donationEnabled, setDonationEnabled]=useState(false);
    const [donationValue, setDonationValue]=useState(0);
    const [finalAmount, setFinalAmount]=useState(0);
    
    
    useEffect(()=>{
        //console.log("Props : ", props);
        const ttodayDate=generateDate();
        setTodayDate(ttodayDate);
        initializeValues();
        setEnrollCourse(selectedItem.selectedCourse);
        //setFinalAmount(selectedOptionDetails.courseAmount);
        alertClose();
    },[])

    const initializeValues=()=>{
        var uniqueArray = removeDuplicates("payOption");
        var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        //console.log("sorted array : ", sortedOptionArray);
        //console.log("Current Pay Status : ", currentPayStatus);
       var tpayOptions=[];
       if(currentPayStatus==="Pending"||currentPayStatus==="na"){
        for(const i in sortedOptionArray){
            if(sortedOptionArray[i].payOption==="Full" || sortedOptionArray[i].payOption==="Phase1"){
            tpayOptions.push(sortedOptionArray[i]);
            }
          }
        }else if(currentPayStatus==="Partial"){
        for(const i in sortedOptionArray){
            if(sortedOptionArray[i].payOption==="Phase2"){
            tpayOptions.push(sortedOptionArray[i]);
            }
          }
    }else{
        tpayOptions.push([]);
    }
    //console.log("TPayOption : ", tpayOptions);
    if(tpayOptions.length>0){
        handlePayOptionSelection(tpayOptions[0].payOption);
    }
        setPayOptions(tpayOptions);
      /*  setOptedCourse({...optedCourse,
          courseName:sortedcourseArray[0].courseName,
          courseOption:sortedOptionArray[0].courseOption
        });*/
    }

      const removeDuplicates=(tprop)=> {
        var newArray = [];
        var lookupObject  = {};
        for(var i in payOptionsData) {
           lookupObject[payOptionsData[i][tprop]] = payOptionsData[i];
        }
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }

    const handlePayOptionSelection=(toptedPayOption)=>{
        //event.preventDefault();
        for(const i in payOptionsData){
              if(payOptionsData[i].payOption===toptedPayOption && payOptionsData[i].courseOption===enrollCourse.courseOption){
            //    console.log("selected Option : ", payOptionsData[i] );
              setSelectedOptionDetails(payOptionsData[i]);
              setFinalAmount(payOptionsData[i].courseAmount);
              var tenrollCourse=enrollCourse;
              tenrollCourse.feesOpted=payOptionsData[i].courseAmount;
              tenrollCourse.payOption=payOptionsData[i].payOption;
              setEnrollCourse(tenrollCourse);
              }
            }
    }

    function generateDate() {
        const current_datetime = new Date();
        var tempUserId = "";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value = "";
        date_month.length === 1 ? (month_value = month_value.concat(0, date_month)) : (month_value = date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value = "";
        date_date.length === 1 ? (date_value = date_value.toString().concat(0, date_date)) : (date_value = date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value = "";
        date_hours.length === 1 ? (hours_value = hours_value.toString().concat(0, date_hours)) : (hours_value = date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value = "";
        date_minutes.length === 1 ? (minutes_value = minutes_value.toString().concat(0, date_minutes)) : (minutes_value = date_minutes);
        const accountNo = tempUserId.concat(date_year, "-", month_value, "-", date_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
    }

    const validateFields=()=>{
        var validateResult="PASS";
        if(paymentMode==="Cheque"){
            if(transactionID===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Cheque Number!"
                });  
                validateResult="FAIL";
            }else if(bankName===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Bank Name!"
                });  
                validateResult="FAIL";
            }

        } else if(paymentMode==="DD"){
            if(transactionID===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter DD Number!"
                });  
                validateResult="FAIL";
            }else if(bankName===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Bank Name!"
                });  
                validateResult="FAIL";
            }

        }else if(paymentMode==="Online"){
            if(transactionID===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Online Transaction Number!"
                });  
                validateResult="FAIL";
            }else if(bankName===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Bank Name!"
                });  
                validateResult="FAIL";
            }

        }else{
            validateResult="PASS";

        }
        return validateResult;
    }
    
    const handlePayment=async(event)=>{
        event.preventDefault();
        try{
            const validateResult=validateFields();
            if(validateResult==="PASS"){
            setLoaded(false);
            const tdate=generateDate();
            const orderno=generateOrderNo();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;      
            const params={
                orderID:orderno,
                paymentMode:paymentMode,
                paymentPartner:paymentMode,
                orderAmount:selectedOptionDetails.courseAmount,
                orderStatus:"TXN_SUCCESS",
                orderedBy:selectedItem.registrationNo,
                orderDate:tdate,
                feeAmount:selectedOptionDetails.courseAmount,
                transactionId:transactionID,
                gatewayName:paymentMode,
                currency:"INR",
                discountAmount:0,
                discountApplied:"NA",
                bankName:bankName,
                bankTransId:"NA",
                cart:enrollCourse,
                feeBreakUp:selectedOptionDetails.feesBreakUp,
                concessionEnabled:concessionEnabled,
                concessionValue:concessionValue,
                donationEnabled:donationEnabled,
                donationValue:donationValue,
                transactionAmount:finalAmount
            };
            //console.log("Params : ", params);
            await axios.post(`${config.api.invokeURL}/dashboard/order/${orderno}`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                 // console.log("response : ", response); 
                 fetchCurrentYearDetails(selectedBatch);
                 setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Payment successfully processed!"
                }); 
                window.scrollTo(0,0); 
                setLoaded(true);
                toggle();
                }).catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0); 
                  setLoaded(true);
                 // setLoaded(true);
                })
            }
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });  
        setLoaded(true);      
    }
    }

    const handleConcessionUpdate=(event)=>{
        if(concessionEnabled){
            const tfinalAmount=selectedOptionDetails.courseAmount-event.target.value;
            if(tfinalAmount<0){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Concession Amount is higher than the Fees Amount"
                }); 
                window.scrollTo(0,0); 
            }else{
                setConcessionValue(event.target.value);
                setFinalAmount(tfinalAmount);
            }
        }else{
            setConcessionValue(0);
            setFinalAmount(selectedOptionDetails.courseAmount);
        }
    }

    const handleConcessionCheck=(event)=>{
        if(concessionEnabled){
            setConcessionValue(0);
            setFinalAmount(selectedOptionDetails.courseAmount);
        }
        setConcessionEnabled(!concessionEnabled)
        
    }

    const handleDonationUpdate=(event)=>{
        if(donationEnabled){
            const tfinalAmount=selectedOptionDetails.courseAmount+parseFloat(event.target.value);
            if(event.target.value<0){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Donation Amount cannot be a negative value"
                }); 
                window.scrollTo(0,0); 
            }else{
                setDonationValue(event.target.value);
                setFinalAmount(tfinalAmount);
            }
        }else{
            setDonationValue(0);
            setFinalAmount(selectedOptionDetails.courseAmount);
        }
    }

    const handleDonationCheck=(event)=>{
        if(donationEnabled){
            setDonationValue(0);
            setFinalAmount(selectedOptionDetails.courseAmount);
        }
        setDonationEnabled(!donationEnabled)
        
    }

  return (
    <div>  
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    <div className="table-settings mb-4">
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Student Name : {selectedItem.title}.{selectedItem.applicantName} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Student ID : {selectedItem.registrationNo} </Form.Label>
    </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Course Type : {enrollCourse.courseOption} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Fees : {selectedOptionDetails.courseAmount} </Form.Label>
    </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Category:  </Form.Label>
    <Form.Group id="searchstud">
           
           <Form.Select
           // onChange={(event)=>{handlePayOptions(event, event.target.value)}}
           onChange={(event)=>{handlePayOptionSelection(event.target.value)}}>
             {payOptions.map(item => {
                return (<option key={item.payOption} value={item.payOption}>{item.payOption}</option>);
                })}

           </Form.Select>
         </Form.Group>
        </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Mode of Payment:  </Form.Label>
     <Form.Group id="searchstud">
           <Form.Select defaultValue="Cash" value={paymentMode} 
           onChange={(event)=>{setPaymentMode(event.target.value)}}>
             <option value="Cash">Cash</option>
             <option value="Cheque">Cheque</option>
             <option value="DD">DD</option>
             <option value="Online">Online</option>
           </Form.Select>
         </Form.Group>
        </Col>
    </Row>
    {paymentMode==="Cash"?null: <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Cheque/DD No:  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Cheque or DD no" value={transactionID} 
          onChange={(event)=>{setTransactionID(event.target.value)}}/>
        </InputGroup>
    </Col>
    <Col md={6} >
      <Form.Label>Bank Name:  </Form.Label>
    
      <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Bank Name" value={bankName} 
          onChange={(event)=>{setBankName(event.target.value)}}/>
        </InputGroup>
      </Col>
    </Row>}
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Amount to be Paid :  </Form.Label>
    <InputGroup>
         
    <Form.Control type="number" value={finalAmount} disabled />
          
        </InputGroup>
        </Col>
      <Col  md={6} >
      <Form.Label>Date of Payment :  </Form.Label>
      <InputGroup>
         
          <Form.Control type="date" placeholder="Paid on" value={todayDate} onChange={(event)=>{setTodayDate(event.target.value)}}/>
          
        </InputGroup>
      </Col>
      
    </Row> 
    <Row>
    <Col md={6} className="mb-3">
    <Form.Check type="checkbox">
        <FormCheck.Input id="defaultCheck5" className="me-2" checked={concessionEnabled} onChange={(event)=>{handleConcessionCheck(event)}}/>
        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Process Concession</FormCheck.Label>
    </Form.Check>
    </Col>
    {concessionEnabled?
    <Col md={6} className="mb-3">
    <Form.Label>Concession Amount :  </Form.Label>
    <InputGroup>
        <Form.Control type="number" value={concessionValue} onChange={(event)=>{handleConcessionUpdate(event)}} />
    </InputGroup>
    </Col>:null}
    </Row>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Check type="checkbox">
        <FormCheck.Input id="donationCheck5" className="me-2" checked={donationEnabled} onChange={(event)=>{handleDonationCheck(event)}}/>
        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Process Donation</FormCheck.Label>
    </Form.Check>
    </Col>
    {donationEnabled?
    <Col md={6} className="mb-3">
    <Form.Label>Donation Amount :  </Form.Label>
    <InputGroup>
        <Form.Control type="number" value={donationValue} onChange={(event)=>{handleDonationUpdate(event)}} />
    </InputGroup>
    </Col>:null}
    </Row>
    <Row>
    <div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" disabled={payOptions.length>0?false:true} onClick={(event)=>{handlePayment(event)}}>Submit</Button>
      </div>
    </Row>
  </div></div>
  )
}
