import React, { useEffect, useContext, useState } from 'react';
import Preloader from "../../components/Preloader";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import { ButtonGroup } from '@themesberg/react-bootstrap';
import {Input} from 'reactstrap';
import {ProductContext} from "../../ProductProvider";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import YesNoModal from '../exam/YesNoModal';
import MCQModal from '../exam/MCQModal';
import DescriptiveModal from '../exam/DescriptiveModal';
import ReactToPrint from "react-to-print";

//import { faListSquares } from '@fortawesome/free-solid-svg-icons';
const config = require('../../config.json');

export default function EvaluationForm(props) {
    const {selectedItem, evaluateToggle, fetchPendingEvaluationRecords} = props;
    const { alertClose, alertDetails, setAlertDetails, generateDate, loggedUser } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [evaluate, setEvaluate]= useState({marks:"", totalMarks:"", grade:"", result:"", remarks:"", evaluatedBy:"", evaluatedOn:""});
    const [formType, setFormType] = useState("Response View");
    //const [displayQuestions, setDisplayQuestions]=useState([]);
    const componentRef = React.useRef(null);

    useEffect(()=>{
        //console.log(props);
        //setDisplayQuestions(selectedItem.assessmentResponse);
        setFormType("Response View");
        alertClose();     
    },[])

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    
      const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="outline-primary"  size="sm">Print</Button>;
      }, []);    

    const validationFields =()=>{
        var returnValue="Failed";
        if(evaluate.marks===""){
            returnValue="Failed";
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Marks Scored Cannot be Empty"
            }); 
        }else if(evaluate.totalMarks===""){
            returnValue="Failed";
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Total Marks cannot be empty"
            }); 
        }else if(evaluate.grade===""){
            returnValue="Failed";
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Grade cannot be empty"
            }); 
        }else if(evaluate.result===""){
            returnValue="Failed";
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Result cannot be empty"
            }); 
        }else if(evaluate.remarks===""){
            returnValue="Failed";
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Remarks cannot be empty"
            }); 
        }else{
            returnValue="Passed";
        }
        return returnValue;
    }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
            setLoaded(false);            
            const validationResult=await validationFields();
            if(validationResult==="Passed"){
            const todayDate=generateDate();  
            //const tassessmentID=selectedItem.assessmentID+"#"+selectedItem.registrationNo;
              const params={
                registrationNo:selectedItem.registrationNo,
                assessmentID:selectedItem.assessmentID,
                assessmentStatus:'Completed',
                totalMarksObtained:evaluate.marks,
                totalMarks:evaluate.totalMarks,
                assessmentGrade:evaluate.grade,
                assessmentResult:evaluate.result,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate,
                assessmentRemarks:evaluate.remarks,
                evaluatedBy:evaluate.evaluatedBy,
                evaluatedOn:evaluate.evaluatedOn
            }
            //console.log("Params :", params);
            const registrationno=selectedItem.registrationNo;
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            await axios.patch(`${config.api.invokeURL}/dashboard/scores/${registrationno}`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Successfully Updated"
                }); 
                window.scrollTo(0,0);
                evaluateToggle();
                fetchPendingEvaluationRecords();
                })
                .catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })
            }else{
            setLoaded(true);  
            window.scrollTo(0,0);
            }          
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true); 
        }  
      }    

    return (
    <>
        <Preloader show={loaded ? false : true} />
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Evaluation"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
       
      </div>
        <div ref={componentRef}>
        <Card border="light" className=" bg-white shadow-sm mb-4">
        <Card.Body>     
            {selectedItem && selectedItem.examType==="YesNo"? 
            <YesNoModal questionID={selectedItem} formType={formType} displayQuestions={selectedItem.assessmentResponse}/>:
            (selectedItem && selectedItem.examType==="MCQ")
            ?<MCQModal questionID={selectedItem} formType={formType} displayQuestions={selectedItem.assessmentResponse}/>:
            <DescriptiveModal questionID={selectedItem} formType={formType} displayQuestions={selectedItem.assessmentResponse}/>}
        </Card.Body>
        </Card>
        <Card border="light" className=" bg-white shadow-sm mb-4">
        <Card.Body>     
        <h5 className="mb-4">Evaluate</h5>
        <Form>
        <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Registration No</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedItem.registrationNo} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Assessment ID</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedItem.assessmentID}>
                </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mb-3">
            <Form.Group id="emal">
            <Form.Label>Total Marks</Form.Label>
            <Input type="text" value={evaluate.totalMarks}
                onChange={(event)=>{setEvaluate({...evaluate, totalMarks:event.target.value})}}>
            </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="emal">
            <Form.Label>Marks Scored</Form.Label>
            <Input type="text" value={evaluate.marks}
                onChange={(event)=>{setEvaluate({...evaluate, marks:event.target.value})}}>
            </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mb-3">
            <Form.Group id="emal">
            <Form.Label>Grade</Form.Label>
            <Input type="text" value={evaluate.grade}
                onChange={(event)=>{setEvaluate({...evaluate, grade:event.target.value})}}>
            </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="emal">
            <Form.Label>Result</Form.Label>
            <Input type="text" value={evaluate.result}
                onChange={(event)=>{setEvaluate({...evaluate, result:event.target.value})}}>
            </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mb-3">
            <Form.Group id="emal">
            <Form.Label>Evaluated By</Form.Label>
            <Input type="text" value={evaluate.evaluatedBy}
                onChange={(event)=>{setEvaluate({...evaluate, evaluatedBy:event.target.value})}}>
            </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="emal">
            <Form.Label>Evaluated On</Form.Label>
            <Input type="date" value={evaluate.evaluatedOn}
                onChange={(event)=>{setEvaluate({...evaluate, evaluatedOn:event.target.value})}}>
            </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
            <Form.Group id="emal">
            <Form.Label>Remarks</Form.Label>
            <Input type="textarea" rows="3" value={evaluate.remarks}
                onChange={(event)=>{setEvaluate({...evaluate, remarks:event.target.value})}}>
            </Input>
            </Form.Group>
        </Row>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
        </div>

        </Form>
        </Card.Body>
        </Card>
        </div>
    </>  
    )
}
