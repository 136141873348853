import React, { useEffect, useContext, useState } from 'react';
//import { Col, Row, Card, Form } from 'react-bootstrap';
import {Alert} from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import {Auth} from 'aws-amplify';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
//import ProfileImage from '../../components/manageimage/ProfileImage';
//import { phoneCode } from '../../ProData';
//import { Routes } from '../../routes';
//import {useHistory} from 'react-router-dom';
import StaffRegistration from './RegistrationForm';
import {Helmet} from 'react-helmet-async';

//import RegistrationForm from '../Registrations/RegistrationForm';
const config = require('../../config.json');


export default function StaffProfile() {
  //const history=useHistory();
  const { loggedUser, alertDetails, setAlertDetails, alertClose, validateLogin} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', areaCode:'+91',mobileNumber:'', emailID:''});
  const [imageFile, setImageFile] = useState('');
  const [profileImage,setProfileImage]=useState('https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg');

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchMyProfile();
    }else{
      validateLogin();
    //history.push(Routes.Signin.path);
    }
    //return () => clearTimeout(timer);

  },[loggedUser])

  const fetchMyProfile=async()=>{
    setLoaded(false);
    try{
        //const torderno=orderno.toString();
     //   await axios.get(`${config.api.invokeURL}/student/order/${orderno}`, {
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
         const searchOption="MyProfile";
            await axios.get(`${config.api.invokeURL}/staff/profile?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {

            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
          // console.log("response : ", response.data[0]);
            if(response.data.length>0)
            {
           //     console.log("order status")
           const tavatar=response.data[0].avatar;
           if(tavatar===undefined||tavatar===""){
            setProfileImage("https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg");
          }else{
            const tapplicationNo=response.data[0].applicationNo;
           // const tImageName=profileDetails.avatar;
           // console.log(`${config.s3.profileUrl}${tapplicationNo}${"/"}${tavatar}`);
            setProfileImage(`${config.s3.profileUrl}${tapplicationNo}${"/"}${tavatar}`);  
          }
                setProfileDetails(response.data[0]);
                setProfileImage(response.data[0].avatar);
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Profile loaded Successfully!"
              });  
              setLoaded(true);

            }
            setLoaded(true);
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })

    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);

  }
}

  return (
    <div>
          <Helmet>
      <title>My Profile - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="My Profile - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="My Profile - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Staff</Breadcrumb.Item>
            <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Profile</h4>
          <StaffRegistration formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails} tprofileImage={profileImage} setImageFile={setImageFile}/>

        </div>

    </div>
  )
}
