import React, { useContext, useEffect} from "react";
import ImageLoader from '../../components/manageimage/ImageLoader'
import { ProductContext } from '../../ProductProvider';

export default function ImageViewer() {

  const { alertClose, loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
      validateLogin();
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])

  return (
    <div>ImageViewer
        <ImageLoader />
    </div>
  )
}
