import React, {useState, useEffect, useContext} from 'react';
import {ProductContext} from "../../ProductProvider";
import { coursesOffered } from '../../ProData';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Preloader from "../../components/Preloader";

const config = require('../../config.json');

export default function CourseDetailsForm(props) {
    const {selectedItem, selectedCourse, courseToggle, handleSearch} = props;
    const { alertDetails, setAlertDetails, generateDate, loggedUser, alertClose } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [coursesAvailable, setCoursesAvailable]= useState([]);
    const [courseOptionAvailable, setCourseOptionAvailable]= useState([]);
    // const [selectedCourse, setSelectedCourse]=useState({});
    const [optedCourse, setOptedCourse]=useState({courseName:'',courseOption:'', courseStatus:'Approved', courseResult:'PASS', courseCompletedOn:'', duration:'', fees:'', feesOpted:'', payOptions:'', payStatus:'', grade:''});
    const viewOnly=false;

    useEffect(()=>{
      //console.log("Props : ", props);
      alertClose();
      if(selectedCourse===undefined||selectedCourse.courseName===undefined||selectedCourse.courseName===""){
        initializeValues();
      }else{
        initializeValues();
        setOptedCourse(selectedCourse);
      }
      setLoaded(true);
    },[])

    const initializeValues=()=>{
      var uniqueArray = removeDuplicates("courseName");
      var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
      setCoursesAvailable(sortedcourseArray);
      //console.log("Courses Name : ", sortedArray);
      uniqueArray = removeDuplicates("courseOption");
      var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
      setCourseOptionAvailable(sortedOptionArray);
      setOptedCourse({...optedCourse,
        courseName:sortedcourseArray[0].courseName,
        courseOption:sortedOptionArray[0].courseOption
      });
    }

    const removeDuplicates=(tprop)=> {
      var newArray = [];
      var lookupObject  = {};
      for(var i in coursesOffered) {
         lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
      }
      for(i in lookupObject) {
          newArray.push(lookupObject[i]);
      }
       return newArray;
  }


    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
          var tcourseOpted={};
          setLoaded(false);
         // console.log("Opted Course : ", optedCourse);
          //console.log("Selected Course : ", selectedCourse);
          if(selectedCourse===undefined||selectedCourse.courseName===undefined||selectedCourse.courseName===""){
          for(const i in coursesOffered){
            //  console.log("selected Course : ", coursesOffered[i] );
              if(coursesOffered[i].courseName===optedCourse.courseName && coursesOffered[i].courseOption===optedCourse.courseOption){
            //    console.log("inside loop : ", coursesOffered[i] );
                //setSelectedCourse(coursesOffered[i]);
                tcourseOpted=coursesOffered[i];
              }
            }
          }else{
            tcourseOpted=selectedCourse;
          }
         // console.log("tcourseOpted Course : ", tcourseOpted);
            if(tcourseOpted.payStatus==="na"||tcourseOpted.payStatus===""||tcourseOpted.payStatus===undefined){
              tcourseOpted.payStatus="Pending";
            }else{
              tcourseOpted.payStatus=selectedItem.coursePayment;
            }
            tcourseOpted.courseStatus=optedCourse.courseStatus;
            tcourseOpted.courseResult=optedCourse.courseResult;
            tcourseOpted.grade=optedCourse.grade;
            tcourseOpted.courseCompletedOn=optedCourse.courseCompletedOn;
            const todayDate=generateDate();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //console.log("TOpted:", tcourseOpted);
            const params={
              clientID:"VJDEPTH",
              registrationNo:selectedItem.registrationNo,
              selectedCourse:tcourseOpted,
              courseStatus:tcourseOpted.courseStatus,
              coursePayment:tcourseOpted.payStatus,
              updatedBy:loggedUser.nickname,
              updatedOn:todayDate
            }
            //console.log("Params : ", params);
            await axios.patch(`${config.api.invokeURL}/dashboard/registration/course-details`, params,{
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }})
              .then(async(response)=>{
              //  console.log("Response : ", response);
              //fetchApplicationDetails();
              await handleSearch();
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Successfully Updated"
              }); 
              window.scrollTo(0,0);
              courseToggle();
              //handleSearch();
                setLoaded(true);            
              })
              .catch((error)=>{
                  setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Error Occured : " + error.message
                }); 
                window.scrollTo(0,0);
                setLoaded(true);
              })
           // console.log("tcourse opted : ",tcourseOpted);
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true); 
        }
    }

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <h5 className="mb-4">Update - Course Details</h5>
         <Form>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Course {optedCourse.courseName} </Form.Label>
                 <Input type="select" name="courseName"
                                  value={optedCourse.courseName} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseName:(event.target.value)})}}
                                >
                                  {coursesAvailable.length>0 && coursesAvailable.map(item => {
                                return (<option key={item.courseName} value={item.courseName}>{item.courseName}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Preference Mode</Form.Label>
                 <Input type="select" name="courseOption"
                                 disabled={viewOnly}
                                  value={optedCourse.courseOption} 
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseOption:(event.target.value)})}}
                                >
                                  {courseOptionAvailable.map(item => {
                                return (<option key={item.courseOption} value={item.courseOption}>{item.courseOption}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <Row>
    <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Course Status</Form.Label>
                <Form.Select value={optedCourse.courseStatus}
                disabled={viewOnly}
                onChange={(event)=>{setOptedCourse({...optedCourse, courseStatus:event.target.value})}}
                >
                  <option value="Approved">Approved</option>
                  <option value="Completed">Completed</option>
                  <option value="DropOut">DropOut</option>
                </Form.Select>
              </Form.Group>
            </Col>
            </Row>
            {optedCourse.courseStatus==="Completed"?<Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Course Result</Form.Label>
                <Form.Select value={optedCourse.courseResult}
                disabled={viewOnly}
                onChange={(event)=>{setOptedCourse({...optedCourse, courseResult:event.target.value})}}
                >
                  <option value="PASS">PASS</option>
                  <option value="FAIL">FAIL</option>
                  <option value="NA">DropOut</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Grade</Form.Label>
                <Form.Control required type="text" placeholder="Enter your First name" value={optedCourse.grade}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setOptedCourse({...optedCourse, grade:(event.target.value).toUpperCase()})}}  
                                 />
              </Form.Group>
            </Col>

    </Row>:null}
    {optedCourse.courseStatus==="Completed"?<Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Completed Date</Form.Label>
                <Input type="date" value={optedCourse.courseCompletedOn}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseCompletedOn:event.target.value})}}
               />

              </Form.Group>
            </Col>

    </Row>:null}

         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Update</Button>
           </div>
         </Form>
         </Card.Body>
    </Card>
    </>
  )
}
