import React, { useEffect, useState, useContext , useRef} from 'react';
import { Col, Row, Card, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Breadcrumb, BreadcrumbItem, Alert, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Form } from 'react-bootstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import {ProductContext} from "../../ProductProvider";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";

const config = require('../../config.json');

export default function NotificationNew() {
  const ref = useRef();
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, generateDate } = useContext(ProductContext);
  const [request, setRequest]=useState({reqid:"",reqCreateDate:"",reqCreateTime:"",reqSubject:"",reqComments:"",reqStatus:"Open",reqRaisedBy:loggedUser.name, fileAttached:false, attachmentName:null, msgCategory:"Query", msgTargettedTo:"All",msgTo:"Group"})
  const [attachment, setAttachment]=useState('');
  const [fileExtn, setFileExtn]=useState('');
  const [loaded, setLoaded] = useState(true);
  const [batchYear, setBatchYear] = useState([]);

useEffect(()=>{
  if(loggedUser.isAuthenticated){
    alertClose();
    assignInitialValues();
  }else{
  validateLogin();
  alertClose();
  }
  return()=>{
    alertClose();
  }
 // console.log(batchYear);
},[loggedUser])

const assignInitialValues=()=>{
    const current_datetime = new Date();
    const tusername=loggedUser.name.trim().replace(/\s/g, "");
    const tempUserId= tusername.substring(0,4)+"QY";
    var tempCreateDate="";
    var tempCreateTime="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    const accCreateDate = tempCreateDate.concat(date_year,"-",month_value,"-", date_value);
    const accCreateTime = tempCreateTime.concat(hours_value,":", minutes_value);

    //setUserId(accountNo);
    //return accountNo;
    setRequest({...request,
      reqid:accountNo,
      reqCreateDate:accCreateDate,
      reqCreateTime:accCreateTime,
      reqRaisedBy:loggedUser.name,
      reqSubject:"",
      reqComments:"",
      reqStatus:"Open",
      fileAttached:false, 
      attachmentName:null,
      msgTargettedTo:"All",
      msgTo:"Group"
    })
    setAttachment('');
    setFileExtn('');
    var tbatchYear=["All"];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
    //tbatchYear.push("All");
    setBatchYear(tbatchYear);
  
    //console.log("acc : ", accountNo);
  
  
}

const handleSelectFile = async(event)=>{
  event.preventDefault();
  setLoaded(false);
  alertClose();
  //console.log("inside");
  if (event.target.files && event.target.files.length > 0) {
    if(event.target.files[0].size>1000000){
      const fileSize=event.target.files[0].size/1000000;
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"File Size limit is 1MB. Your current file size is - " + fileSize.toFixed(2) + "MB."
    }); 
     // console.log("Event : ", event.target.files[0].size);

    }else{
      //console.log("Event : ", event.target.files[0]);
      setAttachment(event.target.files[0]);
      const fileName=event.target.files[0].name;
      const tfileExtn=fileName.substring(fileName.indexOf('.'),fileName.length);
      setFileExtn(tfileExtn);
     // console.log("Extn : ", fileExtn);
    }
  }
  setLoaded(true);

}

const handleRequestSubmit=async(event)=>{
  event.preventDefault();
  try {
    if(request.reqSubject==="" || request.reqComments===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Subject or Description cannot be Empty!!!"
      }); 
    }
    else{
       // const treqid= await generateReqId();
       setLoaded(false);

       if(request.reqid===""){
        assignInitialValues();
       }
       if(attachment){
       await postOnS3();
       }
        const newFileName=request.reqid+fileExtn;
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const { reqid,reqCreateDate, reqCreateTime, msgTargettedTo, reqSubject, reqComments, reqStatus, reqRaisedBy, fileAttached}=request;
        const msgid=reqid;
        const params = {
            "msgID": reqid,
            "msgCreationDate":  reqCreateDate,
            "msgCreationTime":reqCreateTime,
            "msgSubject":reqSubject,
            "msgDescription": reqComments,
            "msgStatus": reqStatus,
            "msgRaisedBy":reqRaisedBy,
            "msgTargettedTo":msgTargettedTo,
            "msgCategory":"Notification",
            "msgAcknowledgeBy": "NA",    
            "msgValidTill": "NA",
            "msgResolutionNotes":"NA",
            "fileAttached":fileAttached,
            "attachmentName":newFileName
        };
        //console.log("Params : ", params);
        //msgTemplates.push(params);
    await axios.post(`${config.api.invokeURL}/inbox/${msgid}`, params,{
    headers: {
      Authorization: access_token,
      'x-api-key':config.api.key
    }})
    .then(async(response)=>{
      //console.log("response : ", response); 
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message:request.reqid + " - Submitted Successfully"
      }); 
       // handleCancelClick(event);
      //resetRequestValues();
      assignInitialValues();
      setLoaded(true);
    })
    .catch((error)=>{
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Error Occured : " + error.message
      }); 
      setLoaded(true);
    })
  }
}catch (err) {
      setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + err.message
}); 
setLoaded(true);
}
}

const postOnS3 = async()=>{
  try{
      //  console.log("NewImage : ", newImage);
    const newFileName=request.reqid+fileExtn;
    //console.log("newfilename :", newFileName);
    
    var file = attachment;
    var blob = file.slice(0, file.size, file.type); 
    const newFile = new File([blob], newFileName, {type: file.type});
   // console.log("New file : ", newFile);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;   
    const bucketName=config.s3.attachmentBucketName;
    await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
   // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
         headers:{
             Authorization: access_token,
             'x-api-key': config.api.key
         }
     }).then(async(response)=>{
        // console.log("Response : ", response);
        const res=await fetch(response.data.uploadURL, { method: 'PUT',
         headers:{
             "Content-Type":"image/jpeg"
           },body: newFile});
           console.log("Res : ", res);
           if(res.status===200){
              setRequest({...request,
              fileAttached:true});
              ref.current.value=null;
            //  fetchGalleryImages();
              // setProfileImage(newFileName);
               //var timestamp = new Date().getTime();     
              // var tprofile = document.getElementById("profile");
               //var imgsrc="";
               //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
               //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
              // tprofile.src=imgsrc;
              //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
               //console.log("tprofle : ", tprofile.src);
           }
      }).catch((error)=>{
          //console.log("error1:", error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
       // setDispMsg(error.message);
       // hideLoader();
     })
  
  }catch(error){
  //  console.log("error1:", error);
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + error.message
  }); 
  }

}

  return (
    <div>
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.DashboardOverview.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Message
    </BreadcrumbItem>
    <BreadcrumbItem >
      New Notification
    </BreadcrumbItem>
    </Breadcrumb>
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">New Notification Form</h5>

        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        <p>Notification ID : {request.reqid}</p>
        <Row>
          <Col>
          Date : {new Date().toLocaleDateString()}
          </Col>
          <Col>
          Time : {new Date().toLocaleTimeString()}
          </Col>
        </Row>
          <Row className='container p-4'>
            <Col>
                <form > 
                <div className="navbar-bg">
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                <Col md={6} className="mb-3">
                  <Form.Label>Notification To:  </Form.Label>

                  <InputGroup>    
                        <Form.Select value={request.msgTo} 
                            onChange={(event)=>{setRequest({...request, msgTo:event.target.value})}}>
                              <option key="Group" value="Group">Group</option>
                              <option key="Individual" value="Individual">Individual</option>
                        </Form.Select>
                      </InputGroup>
                  </Col>

                  {request.msgTo==="Group"?<Col md={6} className="mb-3">
                  <Form.Label>Select Batch:  </Form.Label>
                  <InputGroup>    
                        <Form.Select value={request.msgTargettedTo} 
                            onChange={(event)=>{setRequest({...request, msgTargettedTo:event.target.value})}}>
                          {batchYear.map(item => {
                              return (
                              <option key={item} value={item}>{item}</option>
                              );
                            })}
                        </Form.Select>
                      </InputGroup>
                  </Col>:request.msgTo==="Individual"?
                  <Col md={6} className="mb-3">
                  <Form.Label>Enter Registration No:  </Form.Label>
                  <InputGroup>    
                  <Input
                        type="text"
                        name="subject"
                        placeholder="Enter Registration No"
                        onChange={(event)=>setRequest({...request,
                          msgTargettedTo:event.target.value})}
                      />
                      </InputGroup>
                  </Col>:null}
                </Row>
                <Row>
                <Form.Group id="subject">
            <Form.Label>Subject</Form.Label>
            <Input
                        type="text"
                        name="subject"
                        placeholder="Enter Subject"
                        onChange={(event)=>setRequest({...request,
                          reqSubject:event.target.value})}
                      />
            </Form.Group>
                  </Row>
                  </div>
                    </FormGroup>
                    <FormGroup style={{overflow:"visible"}}>
                    <div>
                    <Row>
                    <Form.Group id="description">
            <Form.Label>Description</Form.Label>
            <Input type="textarea" name="msg" rows={4}
                      placeholder="Enter detailed description"
                      onChange={(event)=>setRequest({...request,
                        reqComments:event.target.value})}
                      />
            </Form.Group>

                  </Row>
                  </div>
                    </FormGroup>
       </div>
       <FormGroup check row>
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
                {/*} <input type="file" id="uploadfile" ref={ref} onChange={(event)=>{handleSelectFile(event)}} />*/}
                 <Button type="submit" onClick={(event)=>{handleRequestSubmit(event)}}> Submit</Button>
            </div>
            </div>
                </FormGroup> 
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
