import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input} from 'reactstrap';
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
//import FileAttachments from "../../components/attachments/FileAttachments";
import ProfileImage from "../../components/manageimage/ProfileImage";
import { phoneCode } from "../../ProData";
//import axios from "axios";
const config = require('../../config.json');


export  default function RegistrationForm(props) {
  const {profileDetails, setProfileDetails, formType, setImageFile} = props;
  /*const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
  occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
  addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
 // const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});*/
  const [profileImage,setProfileImage]=useState('');
  const [viewOnly, setViewOnly] = useState(true);
  const componentRef = React.useRef(null);

  useEffect(()=>{
    if(profileDetails.avatar===undefined||profileDetails.avatar===""){
      setProfileImage("https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg");
    }else{
      const tapplicationNo=profileDetails.applicationNo;
      const tImageName=profileDetails.avatar;
      setProfileImage(`${config.s3.profileUrl}${tapplicationNo}${"/"}${tImageName}`);  
    }
    if(formType==="Edit"){
      setViewOnly(false);
    }else{
      setViewOnly(true);
    }
  },[])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  return (
    <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>Registration Form</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
      </div>
     
      <div ref={componentRef}>
        <ProfileImage formType={formType} profileImage={profileImage} setImageFile={setImageFile}/>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
       <Row></Row>
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Select value={profileDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setProfileDetails({...profileDetails, title:event.target.value})}}
               >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Fr">Fr</option>
                  <option value="Other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your First name" value={profileDetails.firstName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, firstName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control required type="text" value={profileDetails.middleName}
                                   disabled={viewOnly}
                                   onChange={(event)=>{setProfileDetails({...profileDetails, middleName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" value={profileDetails.lastName}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, lastName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            
            
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Input type="date" value={profileDetails.dateOfBirth}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, dateOfBirth:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select value={profileDetails.gender} 
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, gender:event.target.value})}}
                                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="maritalstatus">
                <Form.Label>Marital Status</Form.Label>
                <Form.Select value={profileDetails.maritialStatus}
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, maritialStatus:event.target.value})}}
                                >
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="occupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control required type="text" value={profileDetails.occupation}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, occupation:(event.target.value).toUpperCase()})}}
                                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Religion</Form.Label>
                <Form.Select value={profileDetails.religion} 
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, religion:event.target.value})}}
                                >
                  <option value="Christian">Christian</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Muslim">Muslim</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Denomination</Form.Label>
                <Form.Control required type="text" value={profileDetails.denomination}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, denomination:(event.target.value).toUpperCase()})}}
                                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col sm={6} className="mb-3">
              <Form.Group id="diocese">
                <Form.Label>Diocese</Form.Label>
                <Form.Control required type="text" value={profileDetails.diocese}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, diocese:(event.target.value).toUpperCase()})}}
                                />
              </Form.Group>
            </Col>
            <Col>
            <Form.Label>Mobile Number</Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                
                <Input type="select" name="courseName"
                                  value={profileDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Input>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Enter your mobile number" 
                                disabled={viewOnly}
                                value={profileDetails.mobileNumber} onChange={(event)=>{setProfileDetails({...profileDetails, mobileNumber:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>
            
            
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" value={profileDetails.emailID}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Landline Number</Form.Label>
                <Form.Control required type="number" value={profileDetails.landlineNumber}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, landlineNumber:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          
          
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" value={profileDetails.addressStreet}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, addressStreet:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.addressState}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, addressState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group id="zip">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.addressPincode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, addressPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Nationality" value={profileDetails.nationality}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, nationality:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            
          </Row>
          
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Mother Tongue</Form.Label>
                <Form.Control required type="text" placeholder="Your Mother Tongue" value={profileDetails.motherTongue}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, motherTongue:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>English Proficiency</Form.Label>
                <Form.Select value={profileDetails.englishProficiency} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, englishProficiency:event.target.value})}}
                  >
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Average">Average</option>
                  <option value="Poor">Poor</option>
                  <option value="Dont know English">Dont know English</option>
                </Form.Select>
              </Form.Group>
            </Col>
            
          </Row>
</Form>
</Card.Body>
</Card>
</div>
</div>
  )
}
