import React, {useEffect, useState, useContext} from 'react';
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { Input, Table} from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {  faAdd , faTrash} from "@fortawesome/free-solid-svg-icons";
//import axios from "axios";
//import { Auth } from "aws-amplify";
//import { Routes } from "../../routes";
//import Preloader from "../../components/Preloader";
import {ProductContext} from "../../ProductProvider";
//const config = require('../../config.json');

export default function AcademicInfo(props) {
  const { alertDetails, setAlertDetails } = useContext(ProductContext);
  const [localStudy, setLocalStudy]=useState({name:"",place:"",startYear:"",endYear:"",degree:"",percentage:""});
  const {form, setForm,  formType} = props;
  //const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg')
//  const [loaded, setLoaded] = useState(true);
const [viewOnly, setViewOnly] = useState(true);

useEffect(() => {
  if(formType==="Edit"){
    setViewOnly(false);
  }else{
    setViewOnly(true);
  }
}, [])


  const handleStudyAdd =async(event)=>{
    event.preventDefault();
    if(localStudy.name===""||localStudy.place===""||localStudy.startYear===""||localStudy.endYear===""||localStudy.degree===""||localStudy.percentage===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Acadamic Fields cannot be empty. Enter all Fields!"
    }); 
    window.scrollTo(0,0);
    }else{
      //setStudyDetails([...studyDetails, localStudy]);
      await setForm({...form,
        degreeDetails:[...form.degreeDetails, localStudy]});
        setLocalStudy({name:"",place:"",startYear:"",endYear:"",degree:"",percentage:""});
     //   calculateProgressiveValues(memberInfo);
    }
  }

  const handleRemoveStudy=async(event, member)=>{
    event.preventDefault();
    //console.log("item : ", item);
      let tempCart = [...form.degreeDetails];
      tempCart = tempCart.filter(item => item.name !==member.name);
     // console.log("temp cart :", tempCart);
     await setForm({
      ...form,
      degreeDetails:[...tempCart]
    });
   // calculateProgressiveValues(memberInfo);
    // setStudyDetails([...tempCart]);
  }

  const alertClose=()=>{
    setAlertDetails({
        ...alertDetails,
        style:"",
        open:false,
        message:""
    });
}

  return (
    <div>
         <Card border="light" className="bg-white shadow-sm mb-4">
      {!viewOnly?<Card.Body>
        <h5 className="mb-4">Academic information</h5>
        <h6>Add Details</h6>
          <Row className='container p-2'>
            <Col> 
              <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
              <div>
            <Row>
            <Col xs={9} lg={3}>
                  <Input
                        type="text"
                        name="institutionname"
                        placeholder="Institution Name"
                        onChange={(event)=>{setLocalStudy({...localStudy,
                          name:(event.target.value).toUpperCase()})}}
                          value={localStudy.name}
                 />
                  </Col>
                                <Col xs={9} lg={3}>
                                <Input type="text" name="place"
                                 placeholder='Place'
                                 onChange={(event)=>{setLocalStudy({...localStudy,
                                  place:(event.target.value).toUpperCase()})}}
                                  value={localStudy.place}
                                >
                              </Input>
                    </Col>
                <Col xs={9} lg={3}>
                  <Input type="number" name="year" placeholder='Start Year (eg: 1996)'
                    onChange={(event)=>{setLocalStudy({...localStudy,
                    startYear:event.target.value})}}
                    value={localStudy.startYear}
                  />
                 </Col>
                 
                 
                 
               </Row>
               <Row>
               <Col xs={9} lg={3}>
                  <Input type="text" name="year" placeholder='Degree (Initials)'
                        onChange={(event)=>{setLocalStudy({...localStudy,
                        degree:(event.target.value).toUpperCase()})}}
                        value={localStudy.degree}
                      />
                 </Col>
                 <Col xs={9} lg={3}>
                  <Input type="text" name="percentage" placeholder='Grade/Percentage'
                        onChange={(event)=>{setLocalStudy({...localStudy,
                        percentage:(event.target.value).toUpperCase()})}}
                        value={localStudy.percentage}

                      />
                 </Col>
                 <Col xs={9} lg={3}>
                  <Input type="text" name="year" placeholder='End Year (eg: 1999)'
                      onChange={(event)=>{setLocalStudy({...localStudy,
                      endYear:event.target.value})}}
                      value={localStudy.endYear}
                      />
                 </Col>
                 <Col xs={9} lg={1}>
                 <Button title="Add User" onClick={(event)=>{handleStudyAdd(event)}} >ADD</Button>
                 </Col>
               </Row>
                  </div>
                 </FormGroup>
       </div>
       </Col>           
          </Row>
      </Card.Body>:null}

      <p>Degree Details:</p>

  {form.degreeDetails ?<Table hover responsive>
  <thead>
    <tr>
      <th>
        #
      </th>
      <th>
       Name of the Institute
      </th>
      <th>
       Place of the Institute
      </th>
      <th>
       Start Year
      </th>
      <th>
       End Year
      </th>
      <th>
       Degree
      </th>
    </tr>
  </thead>
  <tbody>
  {form.degreeDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">
      {index+1}

      </th>
      <td>
      {item.name}

      </td>
      <td>
        {item.place}
      </td>
      <td>
        {item.startYear}
      </td>
      <td>
        {item.endYear}
      </td>
      <td>
        {item.degree}
      </td>
     {/*!viewOnly? <td>
      <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveStudy(event, item)}}/>
  </td>:null*/}
    </tr>)}
  </tbody>
</Table>:<p>No Degree details found</p>}
    </Card>
    </div>
  )
}
