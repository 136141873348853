
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input} from 'reactstrap';
//import FileAttachments from "../../components/attachments/FileAttachments";
import ProfileImage from "../../components/manageimage/ProfileImage";
import { phoneCode } from "../../ProData";
//import axios from "axios";
//const config = require('../../config.json');


export  default function StaffRegistration(props) {
  const {profileDetails, setProfileDetails, formType, setImageFile, profileImage} = props;
 /* const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', 
   areaCode:'',mobileNumber:'', emailID:'' });*/
 // const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});*/
  //const [profileImage,setProfileImage]=useState('');
  const [viewOnly, setViewOnly] = useState(true);
  //const [imageFile, setImageFile]= useState('');
  //const formType="Edit";


  useEffect(()=>{
   // console.log("Props : ", props);
   
    if(formType==="Edit"){
      setViewOnly(false);
    }else{
      setViewOnly(true);
    }
  },[])

  return (
    <div>
        <ProfileImage formType={formType} profileImage={profileImage} setImageFile={setImageFile}/>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
       <Row></Row>
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Select value={profileDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setProfileDetails({...profileDetails, title:event.target.value})}}
               >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Fr">Fr</option>
                  <option value="Other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your First name" value={profileDetails.firstName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, firstName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control required type="text" value={profileDetails.middleName}
                                   disabled={viewOnly}
                                   onChange={(event)=>{setProfileDetails({...profileDetails, middleName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" value={profileDetails.lastName}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, lastName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            
            
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Input type="date" value={profileDetails.dateOfBirth}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, dateOfBirth:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select value={profileDetails.gender} 
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, gender:event.target.value})}}
                                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" value={profileDetails.emailID}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col>
            <Form.Label>Mobile Number </Form.Label>
          
            <Row>
              <Col md={4}>
                  <Input  type="select" name="courseName"
                                  value={profileDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Input>
              </Col>
              <Col md={8}>
                <Form.Control required type="number" placeholder="Enter your mobile number" 
                                disabled={viewOnly}
                                value={profileDetails.mobileNumber} onChange={(event)=>{setProfileDetails({...profileDetails, mobileNumber:event.target.value})}}
                />
              </Col>
            </Row>
            
                
                
                 
           
            
               
            
           
           
            </Col>
            
          </Row>          
          
</Form>
</Card.Body>
</Card>
</div>
  )
}
