import React, {useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faDownload, faUpload, faEye, faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, Table, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { Auth } from "aws-amplify";
import axios from 'axios';
import {Routes} from '../../routes';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import ShowSubjectExam from "../exam/ShowSubjectExam";
import { courseMaterialData } from "../../ProData";
import useFileDownloader from "../../components/downloader/useFileDownloader";
import UploadCoursePDF from "./UploadCoursePDF";
import { ProductContext } from '../../ProductProvider';
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { downloadCourseHeaders } from "../../ProData";
import ViewCourse from "./ViewCourse";
import ViewCourseTemp from "./ViewCourseTemp";

//import YesNoModal from "../exam/YesNoModal";
//import ExamInfo from '../manageExams/ExamInfo';
//import MCQModal from "../exam/MCQModal";
//import DescriptiveModal from "../exam/DescriptiveModal";
//import {Input} from 'reactstrap';
const config = require('../../config.json');


export default () => {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const [collapsed, setCollapsed] = useState(true);
  const [examCollapsed, setExamCollapsed] = useState(true);
  const [courseCollapsed, setCourseCollapsed] = useState(true);
  const [imageFile, setImageFile]= useState('');
  const [selectedItem, setSelectedItem]= useState();
  const [questionID, setQuestionID]=useState();
  //const [formType, setFormType] = useState("View");
  const csvLinkEl = React.createRef();
  const componentRef = React.useRef(null);
  const [pdfFile, setPDFFile] = useState(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
      validateLogin();
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])


  const handleCourseDownload = async(event, item) => {
    event.preventDefault();
    try{
      setLoaded(false);
      const fetchFile=item.courseCode+".pdf";
      const bucketName=`images.vjdepth.in/docs/courseMaterials/active`;
      /*const params={
          fileName:fetchFile,
          bucketName:`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}/${propsFile.domainId}`
      };*/
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      await axios.get(`${config.api.invokeURL}/dashboard/coursematerials/download?fileName=${fetchFile}&bucketName=${bucketName}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
     ).then(async(response)=>{
         // console.log("Response : ", response);
          const file ={
              name:item.courseCode,
              file:response.data.downloadURL,
              filename:fetchFile
          };
          //console.log("url file : ", file);
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Successfully Downloaded"
        }); 
    
          downloadFile(file);
      }).catch((error)=>{
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Error Occured - " + error.message
      }); 
      })        
    }catch(error){
      setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
        //console.log("Error : ", error);
    }
}

const handleCourseView = async(event, item) => {
  event.preventDefault();
  try{
    setLoaded(false);
    const fetchFile=item.courseCode+".pdf";
    const bucketName=`images.vjdepth.in/docs/courseMaterials/active`;
    /*const params={
        fileName:fetchFile,
        bucketName:`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}/${propsFile.domainId}`
    };*/
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    await axios.get(`${config.api.invokeURL}/dashboard/coursematerials/download?fileName=${fetchFile}&bucketName=${bucketName}`,{
      headers: {
        Authorization: access_token,
        'x-api-key': config.api.key
      }}, 
   ).then(async(response)=>{
       // console.log("Response : ", response);
        const file ={
            name:item.courseCode,
            file:response.data.downloadURL,
            filename:fetchFile
        };
        setPDFFile(file.file);
        viewCourseToggle();
        
        console.log("url file : ", file.file);
        setLoaded(true);
        /*setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: "Successfully Downloaded"
      }); */
  
        //downloadFile(file);
    }).catch((error)=>{
      setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Error Occured - " + error.message
    }); 
    })        
  }catch(error){
    setLoaded(true);
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Error Occured - " + error.message
  }); 
  window.scrollTo(0,0);
      //console.log("Error : ", error);
  }
}

const toggle = () => {
  //   console.log("clicked");
  
       setCollapsed(!collapsed);
   }

const handleCoursePDFChange=async(event, item) =>{
  event.preventDefault();
  try{
    setSelectedItem(item);
    toggle();
  }catch(error){
    console.log("Error : ", error);
  }
}

const viewExamToggle = () => {
  //   console.log("clicked");
  
       setExamCollapsed(!examCollapsed);
   }

const viewCourseToggle = () => {
//   console.log("clicked");
      setCourseCollapsed(!courseCollapsed);
  }

const handleViewExam=async(event, item, mode)=>{
  event.preventDefault();
  //console.log("Item : ", item);
  setSelectedItem(item.courseCode);
  setQuestionID(item);
  viewExamToggle();

  /*try{
    setLoaded(false);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    //const clientID="VJDEPTH";
    //const applicationStatus=status;
    const fetchid=item.courseCode;
    await axios.get(`${config.api.invokeURL}/dashboard/exam/list-all-questions?fetchid=${fetchid}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      if(response.data.length>0){
        setQuestionID(response.data[0]);
        setFormType(mode);
        viewExamToggle();
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: response.data.length +" - Questions Found"
        });  
      }else{
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: " No Questions Found"
      });  

      }
        //console.log("Res : ", response);
        //setQuestionTable(response.data);
        //console.log(response);
        setLoaded(true);
    }).catch((error)=>{
        //console.log(error);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    })  
}catch(error){
    setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
}*/
  /// need a script to fetch the qustions from the DB
}

const postOnS3 = async()=>{
  try{
      //  console.log("NewImage : ", newImage);
     // var tKeyName="";
      var newFileName="";
        //tKeyName="avatar/"+applicationno;   
        newFileName=selectedItem.courseCode+".pdf";
    
   // console.log("newfilename :", newFileName);
   // const newImage="";
    var file = imageFile;
    var blob = file.slice(0, file.size, 'application/pdf'); 
    const newFile = new File([blob], newFileName, {type: 'application/pdf'});
   // console.log("New file : ", newFile);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    const bucketName=`images.vjdepth.in/docs/courseMaterials/active`;
    await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
         headers:{
             Authorization: access_token,
             'x-api-key': config.api.key
         }
     }).then(async(response)=>{
      //   console.log("Response : ", response);
        const res=await fetch(response.data.uploadURL, { method: 'PUT',
         headers:{
             "Content-Type":"application/pdf"
           },body: newFile});
        //   console.log("Res : ", res);
           if(res.status===200){
             toggle();
          //  setNewProfileImage(newFileName);
            //  fetchGalleryImages();
              // setProfileImage(newFileName);
               //var timestamp = new Date().getTime();     
              // var tprofile = document.getElementById("profile");
               //var imgsrc="";
               //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
               //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
              // tprofile.src=imgsrc;
              //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
               //console.log("tprofle : ", tprofile.src);
           }
      }).catch((error)=>{
          console.log("error1:", error);
         // setDispMsg(error.message);
       // hideLoader();
     })
  
  }catch(error){
    console.log("error1:", error);
  }

}

const reactToPrintContent = React.useCallback(() => {
  return componentRef.current;
}, [componentRef.current]);

const reactToPrintTrigger = React.useCallback(() => {
  return <Button variant="outline-primary"  size="sm">Print</Button>;
}, []);

const handleDownload =async(event)=>{
  event.preventDefault();
  csvLinkEl.current.link.click();
}

  return (
    <>
        <Helmet>
      <title>Course EBooks - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Course EBooks - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Course EBooks - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*Upload Doc*/}
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={toggle}>
                      {selectedItem?selectedItem.courseTitle:null} - Upload Document
                    </ModalHeader>
                    <ModalBody>    
                      <UploadCoursePDF setImageFile={setImageFile}/>
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={postOnS3}>
                Upload
            </Button>

            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        {/*show exam */}
        <Modal toggle={viewExamToggle} isOpen={!examCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={viewExamToggle}>
                    {questionID?<p>{ questionID.courseTitle}</p>:null}
                    </ModalHeader>
                    <ModalBody>   
                      <ShowSubjectExam courseCode={selectedItem}/>
                    </ModalBody>
                    <ModalFooter>
                   

            {' '}
            <Button onClick={viewExamToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
      {/*View Course*/}
        <Modal toggle={viewCourseToggle} isOpen={!courseCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={viewCourseToggle}>
                    {questionID?<p>{ questionID.courseTitle}</p>:null}
                    </ModalHeader>
                    <ModalBody>   
                      <ViewCourseTemp  pdfFile={pdfFile}/>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={viewCourseToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>  <Link to={Routes.LandingPage.path}>
            <FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
            <Breadcrumb.Item>Course</Breadcrumb.Item>
            <Breadcrumb.Item active>Materials</Breadcrumb.Item>
          </Breadcrumb>
          </div>

          <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadCourseHeaders}
              filename="download.csv"
              data={courseMaterialData}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
       
        </div>
        <h4>Course Materials</h4>

       {/*} <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <Button active variant="outline-primary" size="sm">Add</Button>
          <Button variant="outline-primary" size="sm">Print</Button>
         <Button variant="outline-primary" size="sm">Download</Button>
          </ButtonGroup>
  </div>*/}

      <Table ref={componentRef} hover responsive className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Course Code</th>
                <th>Course Title</th>
                <th>Semester</th>
                <th>Optional</th>
               <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {courseMaterialData.map((item, index)=>
            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.courseCode}
            </td>
            <td>
                {item.courseTitle}
            </td>
            <td>
                {item.semester}
            </td>
            <td>
               {item.optional}
            </td>
            
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Item   onClick={(event)=>{handleCourseView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Material
              </Dropdown.Item>
              <Dropdown.Item   onClick={(event)=>{handleCourseDownload(event, item)}}>
                <FontAwesomeIcon icon={faDownload} className="me-2" /> Download
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleCoursePDFChange(event, item)}}>
                <FontAwesomeIcon icon={faUpload} className="me-2" /> Upload New PDF
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={(event)=>{handleViewExam(event, item,"View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Exam
            </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>   
            {downloaderComponentUI}
 </>
  );
};
