import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, Breadcrumb, InputGroup } from '@themesberg/react-bootstrap';
import { Card, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { Auth } from "aws-amplify";

const config = require('../../config.json');


export default function StudentsPic() {
    const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, handleImageError } = useContext(ProductContext);
    //const csvLinkEl = React.createRef();
    const [searchTopic, setSearchTopic] = useState('Batch');
    const [searchValue, setSearchValue] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [batchYear, setBatchYear]= useState([]);

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          processBatchYear();
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
    
      },[loggedUser])
    
      const processBatchYear=()=>{
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        //const lastTenYear=date_year-10;
        var tbatchYear=[];
        for(var i=0; i<10;i++){
          tbatchYear.push(date_year-i);
        }
    
      //  console.log("batch year : ", tbatchYear);
        setBatchYear(tbatchYear);
        setSearchValue(date_year);
      }

    const handleSearch=async()=>{
        //event.preventDefault();
        try{
          if(searchValue===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Search Value is empty Enter the search input and then hit Search button!"
          });  
    
          }else{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
          await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
            if(response.data.length>0)
            {
                setSearchResults(response.data);
            }
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - student's records found!"
          });  
            setLoaded(true);
    
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })
    }
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
        }
      }


  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Students Details</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Student Details</h4>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
  </div>*/}
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
          <Col  className="mb-3" md={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="Batch"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {batchYear.map(item => {
                    return (
                    <option key={item} value={item}>{item}</option>
                    );
                  })}
            </Form.Select>:
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
    <Row>{searchResults.map((item, index)=>
        <Col key={index}><Card style={{width:"15rem", alignItems:"center", justifyContent:"center", alignContent:"center"}}>
            <img id="profile" src={`${config.s3.profileUrl}${item.applicationNo}${"/"}${item.applicationNo}${".jpeg"}`} 
           onError={handleImageError}
           alt="Profile" style={{width:"6rem", height:"6rem", borderRadius:"50%"}} />
           <p style={{fontSize:"14px"}}>{item.title}.{item.applicantName}</p>           
        </Card></Col>)}
    </Row>
    </>
  )
}
