import React,{useState, useEffect} from 'react';
import {Helmet} from "react-helmet-async";
import Preloader from "../../components/Preloader";
import axios from 'axios';
import { Auth } from "aws-amplify";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const magazineBackground = require("../../assets/jounrnal_background.jpg")
//const writingGif=require("../../assets/writing.gif");
//const activeColour="#097969";
//const passiveColour="#B6B3B2";
const config = require('../../config.json');

export default function ViewCourseTemp(props) {
    const {pdfFile} = props;
    const [loaded, setLoaded] = useState(true);
    const [progressValue, setProgressValue] = useState(0);
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  
    useEffect(() => {
    //   handleFetchPDF();
    }, [])
  
    const handleFetchPDF = async(e, item) => {
      e.preventDefault();
      //console.log("fetch : ", item);
      try{
        const fetchFile=item.journalID+".pdf";
        //console.log("fetch file :", fetchFile);
        const bucketName='docs.vidyajyotijournal.com/pdfs';
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
       ).then(async(response)=>{
            console.log("Response : ", response);
           // setPdfFile(response.data.downloadURL);
            //setShowPDF(true); 
        }).catch((error)=>{
            console.log("Error1 : ", error);
            //setDispMsg("Error Occured - ", error.message);
        })        
      }catch(error){
          console.log("Error2 : ", error);
      }
    }

    const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      //EnterFullScreen: () => <></>,
      //EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
    });

    const handlePageChange = (e) => {
      localStorage.setItem('current-page', `${e.currentPage}`);
    };
    
    const handleSwitchTheme = (theme) => {
      localStorage.setItem('current-theme', theme);
    };
    
    const handleDocumentLoad=(e)=>{
      setLoaded(true);
    }
  
    return (
    <>
      <Helmet>
        <title>VJDepth - Distance Education Programme in Theology</title>
          <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
          <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
          <link rel="canonical" href="/magazine/2023" />
      </Helmet>
      <div style={{ backgroundImage: 'url(' + magazineBackground + ')' , backgroundRepeat:"repeat", height:"1200px"}}>
        <Preloader show={loaded ? false : true} />

      <div>ViewCourseTemp</div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      <Viewer fileUrl={pdfFile} onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
            onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}

            renderLoader={(percentages) => (
              setProgressValue(Math.round(percentages)) )}/>
    </Worker>
      {pdfFile}
      </div>
    </>
    )
  }
