import React from 'react';
//import { courseMaterialData } from '../../ProData';
import { Button } from '@themesberg/react-bootstrap';
//import { faEye, faEdit, faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
//import { Auth } from 'aws-amplify';
//import axios from 'axios';
//import { ProductContext } from '../../ProductProvider';
//import Preloader from "../../components/Preloader";
//import ProgressBarStatus from '../../components/ProgresssBarStatus';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

//const config = require('../../config.json');

export default function PhaseJourney(props) {
       const {myAssessmentSheet}=props;

    return (
       <>

       <div className='card card-layout'>
       <div style={{color:"white", padding:"20px"}}>
        
          {/* start*/}
    
          <div className='con-lesson-journey'>   
       <div >
            <i className='fa fa-play' style={{color:"white"}}>  START   </i>     
       </div>
       {myAssessmentSheet.length>0 && myAssessmentSheet.map((item, index)=><div key={index} >
          <>
          {index<4?<>  <OverlayTrigger
          key={index}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
                   <>
                   <p>	{index +1}. {item.courseCode}</p>
              <p> {item.courseName}</p>
              <p> Marks = {item.totalMarksObtained}</p>

              </>
            </Tooltip>
          }
        >
       <Button className="button-journey"> <i className='btnLessonMapIcon fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
<p>	&#40;{index +1}&#41; {item.courseCode}</p>       <i className='btnLessonDotIcon fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
</Button>
          
        </OverlayTrigger> </>:null}</> 
       </div>)}
       
       </div> 


       <div className='con-lesson-journey-bg'>   
       <div >
            <i className='road-path fa fa-play' >  START   </i>     
       </div>
       <div className='line-draw'  />
       </div> 
       {/* end*/}

       <div className='line-draw-vertical-right'>
       </div>   

       {/* start*/}
        
       <div className='con-lesson-journey'>   
       <div >
            <i className='fa fa-play' style={{color:"white"}}>   </i> 
            </div>
            {myAssessmentSheet.map((item, index)=><div >
                   {index>3&&index<9?<><OverlayTrigger
          key={index}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
                   <>
                   <p>	{index +1}. {item.courseCode}</p>
              <p> {item.courseName}</p>
              <p> Marks = {item.totalMarksObtained}</p>

              </>
            </Tooltip>
          }
        >
       <Button className="button-journey"> <i className='btnLessonMapIcon fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
        <p>	&#40;{index +1}&#41; {item.courseCode}</p>       <i className='btnLessonDotIcon fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
        </Button>
          
        </OverlayTrigger>
                   {/*<div style={{flex: 1, height: '8px', backgroundColor: 'white'}}/>
                   <i className='fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red", padding:'5px', fontSize:"50px"}}>  </i>
       <div style={{color:"green"}}>
       <p>	&#40;{index +1}&#41; {item.courseCode}</p>
       <i className='fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red", padding:'5px', fontSize:"20px"}}>  </i>
       </div>*/}</>:null}
       </div>).reverse()}

       <div  className='line-draw' /> 
       </div> 
       <div className='con-lesson-journey-bg'>   
       <div >
            <i className='road-path fa fa-play' style={{color:"black", padding:'10px'}}>    </i> 
            </div>
       <div  className='line-draw' />
       </div> 
       {/* end*/}
       <div className='line-draw-vertical-left'>
       </div>
       {/* start*/}
        
              <div className='con-lesson-journey'>   
       <div >
              <i className='road-path fa fa-play' >     </i> 
              </div>
              {myAssessmentSheet.map((item, index)=><div >
                   {index>8 && index <14?<><OverlayTrigger
          key={index}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
                   <>
                   <p>	{index +1}. {item.courseCode}</p>
              <p> {item.courseName}</p>
              <p> Marks = {item.totalMarksObtained}</p>

              </>
            </Tooltip>
          }
        >
       <Button className="button-journey"> <i className='btnLessonMapIcon fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
<p>	&#40;{index +1}&#41; {item.courseCode}</p>       <i className='btnLessonDotIcon fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
</Button>
          
        </OverlayTrigger>
                   {/*<div style={{flex: 1, height: '8px', backgroundColor: 'white'}} />
                   <i className='fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red", padding:'5px', fontSize:"50px"}}>  </i>
       <div style={{color:"green"}}>
       <p>	&#40;{index +1}&#41; {item.courseCode}</p>
       <i className='fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red", padding:'5px', fontSize:"20px"}}>  </i>
       </div>*/}</>:null}
       </div>)}

       <div  className='line-draw' /> 
       </div> 
       <div className='con-lesson-journey-bg'>   
       <div >
            <i className='fa fa-play' style={{color:"black", padding:'10px'}}>    </i>     
       </div>
       <div  className='line-draw' />
               </div> 
       {/* end*/}
       <div className='line-draw-vertical-right'>
       </div>
       {/* start*/}
      
       <div className='con-lesson-journey'>   
       <div>
       <i className='fa fa-play' style={{color:"white", padding:'10px'}}>   </i> 
       </div>
       {myAssessmentSheet.map((item, index)=><div >
              {index>13?<><OverlayTrigger
          key={index}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
                   <>
                   <p>	{index +1}. {item.courseCode}</p>
              <p> {item.courseName}</p>
              <p> Marks = {item.totalMarksObtained}</p>

              </>
            </Tooltip>
          }
        >
      <Button className="button-journey"> <i className='btnLessonMapIcon fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
<p>	&#40;{index +1}&#41; {item.courseCode}</p>       <i className='btnLessonDotIcon fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red"}}>  </i>
</Button>
          
        </OverlayTrigger>
              {/*<div style={{flex: 1, height: '8px', backgroundColor: 'white'}} />
              <i className='fa fa-map-marker' style={{color:item.assessmentResult==="PASS"?"green":"red", padding:'5px', fontSize:"50px"}}>  </i>
       <div style={{color:"green"}}>
       <p>	&#40;{index +1}&#41; {item.courseCode}</p>       
       <i className='fa fa-dot-circle-o' style={{color:item.assessmentResult==="PASS"?"green":"red", padding:'5px', fontSize:"20px"}}>  </i>
</div>*/}</>:null}
       </div>).reverse()}

        <div  className='line-draw' />
       </div> 
       <div className='con-lesson-journey-bg'>   
       <div>
       <i className='fa fa-home' style={{color:"white", padding:'10px', fontSize:"20px"}}> FINISH  </i>
       </div>
       <div  className='line-draw' /> 
       </div> 
       {/* end*/}
       </div>
      </div>
      </>
    )
  
}
