import React from 'react';
import { Row, Card, Form, Button } from 'react-bootstrap';

export default function ApplicationStatus(props) {
    const {app, handleNewForm}=props;
  return (
      <>
      <Card border="light" className=" container bg-white shadow-sm mb-4">
      <Card.Body>     
        <h5 className="mb-4">Registration - Final Status</h5>
        <Form>
        <Row>
        <h3 className="mb-4">Registration No - {app.appNo}</h3>
        </Row>
        <Row>
          <p>{app.appMsg}</p>
        </Row>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleNewForm(event)}}> New Form</Button>

          </div>

        </Form>
        </Card.Body>
     </Card>
      </>
  )
}
