import React, { useEffect, useState } from 'react';

export default function ProfileImage(props) {
    const { profileImage, setImageFile, formType}=props;
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [viewOnly, setViewOnly] = useState(true);

useEffect(() => {
  if(formType==="Edit"){
    setViewOnly(false);
  }else{
    setViewOnly(true);
  }
}, [profileImage])

   const handleFileSelection = (event)=>{
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
      var tprofile = document.getElementById("profile");
      tprofile.src=URL.createObjectURL(event.target.files[0]);
  }
 }

   const handleImageError=(e)=>{
    e.target.src = "https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg"
   }

    return (
      <div style={{textAlign:"center"}}> 
      
      {profileImage ? <img id="profile" src={profileImage} 
           onError={handleImageError}
           alt="Profile" style={{width:"6rem", height:"6rem", borderRadius:"50%"}} />
      :null}
      
       <input
         type="file"
         accept="image/*"
         ref={inputRef}
         onChange={handleFileSelection}
         style={{ display: "none" }}
       />
       
      {!viewOnly? <span style={{backgroundColor:'primary', width:'120px', height:'120px', borderRadius:"20%", position:'relative', left:'-20px', top:'30px', zIndex:"999"}}>
           <i className='overlay-profile fa fa-camera' onClick={triggerFileSelectPopup} style={{ border:'2px solid white', borderRadius:'50%',color:'black', left:'-20px', top:'35px', fontSize:'20px', zIndex:'500', cursor:'pointer'}}></i>
           </span>:null}
       </div>      
    )

}