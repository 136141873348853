import React, { useEffect, useState , useContext} from 'react';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Table , Alert } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from '@themesberg/react-bootstrap';

const config = require('../../config.json');

export default function HistoryView(props) {
    const { alertDetails, setAlertDetails, alertClose} = useContext(ProductContext);
    const {selectedItem}=props;
    const [OrderDetails, setOrderDetails]=useState([]);
    const [loaded, setLoaded] = useState(false);
  
    
    useEffect(()=>{
        fetchOrderHistory();
    },[])

    const fetchOrderHistory=async()=>{
        setLoaded(false);
        try{
            //const torderno=orderno.toString();
         //   await axios.get(`${config.api.invokeURL}/student/order/${orderno}`, {
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
             const searchOption="MyOrders";
             const fetchKey=selectedItem.registrationNo;
                await axios.get(`${config.api.invokeURL}/student/order?fetchKey=${fetchKey}&fetchOption=${searchOption}`, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
              // console.log("response : ", response);
                if(response.data.length>0)
                {
               //     console.log("order status")
                    setOrderDetails(response.data);
                }
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length + " - Payment Records Found"
              });  
              setLoaded(true);
            }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);
          })
    
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    
      }
    }
  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Payments</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Payments</h4>
       
        </div>
    <Table hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    order No
                </th>
                <th>
                    Date
                </th>
                <th>
                    Status
                    </th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
            {OrderDetails.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.orderID}
            </td>
            <td>
                {item.orderDate}
            </td>
            <td>
            {item.orderStatus==="TXN_SUCCESS"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Success</p>
                :<p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Failed</p>}
            </td>
            <td>
                {item.orderAmount}
            </td>
            <td>
            
           {/*} <button onClick={(event)=>{handleEnrollRequest(event, item)}}>Enroll</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>
            </tr>)}
            </tbody>
            </Table>
    </>  )
}
