import React, {useEffect, useState} from 'react';
import { Row, Card, Form, Button } from 'react-bootstrap';
import {Input} from 'reactstrap';
import { coursesOffered } from '../../ProData';

export default function CourseSelection(props) {
    const {optedCourse, setOptedCourse, setSelectedCourse, setIsCoursseShortlisted} = props;
    const [coursesAvailable, setCoursesAvailable]= useState([]);
    const [courseOptionAvailable, setCourseOptionAvailable]= useState([]);

    useEffect(()=>{
        initializeValues();
        //console.log("Courses Option : ", sortedArray);
      },[])

      const handleCourseSelection =(event)=>{
        event.preventDefault();
       // console.log("Opted Course : ", optedCourse);
      
        for(const i in coursesOffered){
        //  console.log("selected Course : ", coursesOffered[i] );
          if(coursesOffered[i].courseName===optedCourse.courseName && coursesOffered[i].courseOption===optedCourse.courseOption){
          //  console.log("selected Course : ", coursesOffered[i] );
            setSelectedCourse(coursesOffered[i]);
            setIsCoursseShortlisted(true);
          }
        }
      }

      const initializeValues=()=>{
        var uniqueArray = removeDuplicates("courseName");
        var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        setCoursesAvailable(sortedcourseArray);
        //console.log("Courses Name : ", sortedArray);
        uniqueArray = removeDuplicates("courseOption");
        var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        setCourseOptionAvailable(sortedOptionArray);
        setOptedCourse({...optedCourse,
          courseName:sortedcourseArray[0].courseName,
          courseOption:sortedOptionArray[0].courseOption
        });
      }

      const removeDuplicates=(tprop)=> {
        var newArray = [];
        var lookupObject  = {};
        for(var i in coursesOffered) {
           lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }

  return (
      <>
    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <h5 className="mb-4">Registration - Select the Course</h5>
         <Form>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Course</Form.Label>
                 <Input type="select" name="courseName"
                                  value={optedCourse.courseName} 
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseName:(event.target.value)})}}
                                >
                                  {coursesAvailable.map(item => {
                                return (<option key={item.courseName} value={item.courseName}>{item.courseName}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Preference Mode</Form.Label>
                 <Input type="select" name="courseOption"
                                  value={optedCourse.courseOption} 
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseOption:(event.target.value)})}}
                                >
                                  {courseOptionAvailable.map(item => {
                                return (<option key={item.courseOption} value={item.courseOption}>{item.courseOption}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleCourseSelection(event)}}>Select Course</Button>
           </div>
         </Form>
         </Card.Body>
      </Card>
    </>
  )
}
