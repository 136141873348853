import React from 'react'
import { Table } from '@themesberg/react-bootstrap';
import { courseMaterialData } from "../../ProData";
import { Input, FormGroup  } from "reactstrap";

export default function ViewMaterials(props) {
    const {tempMaterial, setTempMaterial}=props;

    const handleSwitchUpdate =async (selectedCourse)=>{
        //setUpdated(false);
        var tcourseOpted=courseMaterialData;
        tcourseOpted.find(item=>item.assignStatus===selectedCourse.courseCode).assignStatus=!selectedCourse.assignStatus;
        //tcourseOpted.assignStatus=!tcourseOpted.assignStatus;
        //console.log("Updated course : ", tcourseOpted);
        await setTempMaterial(tcourseOpted);
        //setUpdated(true);
    }
  return (
    <div>
    <Table hover responsive className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Course Code</th>
                <th>Course Title</th>
               <th>Assigned</th>
                </tr>
            </thead>
            <tbody>
            {tempMaterial.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.courseCode}</td>
            <td>{item.courseTitle}</td>            
           <td><FormGroup switch>
           <Input
          type="switch"
          checked={item.assignStatus}
          onChange={() => {
            //setState(!state);
            //console.log("Item : ", item);
            handleSwitchUpdate(item);
          }}
          /*onClick={() => {
            //setState(!state);
            //console.log("Item : ", item);
            handleSwitchUpdate(item);
          }}*/
        />
            </FormGroup></td>
            </tr>)}
            </tbody>
    </Table> 

    </div>
  )
}
