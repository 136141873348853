import React, {useState, useContext, useEffect} from "react";
import { Input, FormGroup, Alert  } from "reactstrap";
import {Modal, ModalBody, ModalHeader, ModalFooter  } from "reactstrap";
import { Col, Row, Form, Button, Breadcrumb, InputGroup, Table } from '@themesberg/react-bootstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEye, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { ProductContext } from '../../ProductProvider';
import { courseMaterialData } from "../../ProData";
import Preloader from "../../components/Preloader";
import {Routes} from '../../routes';
import ViewMaterials from "./ViewMaterials";

const config = require('../../config.json');

export default function AssignMaterials() {
    const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin, generateDate } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [tempMaterial, setTempMaterial] = useState([]);
    const [updated, setUpdated] = useState(true);
    const [searchTopic, setSearchTopic] = useState('RegistrationID');
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [collapsed, setCollapsed] = useState(true);

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          setTempMaterial(courseMaterialData);
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
       // console.log(batchYear);
    },[loggedUser])
    
    const handleSearch=async()=>{
        //event.preventDefault();
        try{
          if(searchValue===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Search Value is empty Enter the search input and then hit Search button!"
          });  
          }else{
            setLoaded(false);
            setTempMaterial([]);
            setSearchResults([]);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
          await axios.get(`${config.api.invokeURL}/dashboard/coursematerials/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           if(searchTopic==="RegistrationID"){
            //console.log("res :", response);
            if(response.data[0].length>0)
            {
                setSearchResults(response.data[0]);    
            }
            if(response.data[1].length>0){
                await setTempMaterial(response.data[1][0].books);
            }else{
                setTempMaterial(courseMaterialData);
            }
           }else if(searchTopic==="AssignedStatus"){
            if(response.data[0].length>0)
            {
                setSearchResults(response.data[0]);    
                //setTempMaterial(response.data[0][0].books);    
            }
           }
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data[0].length + " - student's records found!"
          });  
            setLoaded(true);
    
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })
    }
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
        }
    }
    
    const handleSwitchUpdate =async (selectedCourse)=>{
        setUpdated(false);
        var tcourseOpted=courseMaterialData;
        tcourseOpted.find(item=>item.courseCode===selectedCourse.courseCode).assignStatus=!selectedCourse.assignStatus;
        //tcourseOpted.assignStatus=!tcourseOpted.assignStatus;
        //console.log("Updated course : ", tcourseOpted);
        await setTempMaterial(tcourseOpted);
        setUpdated(true);
    }

    const handleAssignMaterialTask=async(event)=>{
        event.preventDefault();
        try{
            setLoaded(false);
            const todayDate=generateDate();
            const ttempMaterial=tempMaterial;
            const assignedBooks=ttempMaterial.filter(item=>item.assignStatus===true);
            var assignedPercentage=0;
            assignedBooks.length>0?assignedPercentage=(assignedBooks.length/tempMaterial.length)/100:assignedPercentage=0;
            const assignStatus=assignedBooks.length>0?'Active':'In-Active';
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            const params={
                registrationNo:searchResults[0].registrationNo,
                assignStatus:assignStatus,
                totalBooks:tempMaterial.length,
                booksAssigned:assignedBooks.length,
                assignedPercentage:assignedPercentage,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate,
                books:tempMaterial
            }
            await axios.post(`${config.api.invokeURL}/dashboard/coursematerials`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
               //   console.log("response : ", response); 
                  setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: searchResults[0].registrationNo + " - Successfully Assigned."
                }); 
                window.scrollTo(0,0);
                setLoaded(true);
                }).catch((error)=>{
               //   console.log("error : ", error);
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  setLoaded(true);
                })
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);    
        }
    }

    const handleViewMaterials=(item)=>{
        //console.log("Item : ", item.books);
        setTempMaterial(item.books);
        toggle();
    }

    const toggle = () => {        
        setCollapsed(!collapsed);
    }

    const handleSearchTopicChange=async(event)=>{
        setSearchTopic(event.target.value)
        setSearchResults([]);
        setTempMaterial([]);
        if(event.target.value==="AssignedStatus"){
            setSearchValue("Active");
        }else{
            setSearchValue('');
        }
    }
  
  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
    </Alert>
    {/*View Doc*/}
    <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
        <ModalHeader toggle={toggle}>
            Assigned Books of {}
        </ModalHeader>
        <ModalBody>    
            <ViewMaterials tempMaterial={tempMaterial} setTempMaterial={setTempMaterial}/>
        </ModalBody>
        <ModalFooter>
        {' '}
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>
    </Modal>

    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>  <Link to={Routes.LandingPage.path}>
            <FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
            <Breadcrumb.Item>Course</Breadcrumb.Item>
            <Breadcrumb.Item active>Materials</Breadcrumb.Item>
          </Breadcrumb>
          </div>

          <div className="btn-toolbar mb-2 mb-md-0">
          {/*<ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadCourseHeaders}
              filename="download.csv"
              data={courseMaterialData}
              ref={csvLinkEl}
            />
            </ButtonGroup>*/}
        </div>
       
    </div>

    <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
      <Col md={4} className="mb-3">
            <Form.Group id="searchstud">               
              <Form.Select defaultValue="RegistrationID" value={searchTopic} onChange={(event)=>{handleSearchTopicChange(event)}}>
              <option value="RegistrationID">Registration No</option>
              <option value="AssignedStatus">Assigned Students</option>
              </Form.Select>
            </Form.Group>
          </Col>
        <Col  className="mb-3" md={8}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            {searchTopic==="AssignedStatus"?           
            <Form.Select value={searchValue} defaultValue="Active"
                onChange={(event)=>{setSearchValue(event.target.value)}}>
                  <option key={"Active"} value={"Active"}>{"Active"}</option>
                  <option key={"In-Active"} value={"In-Active"}>{"In-Active"}</option>
          </Form.Select>:
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
            <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
          </InputGroup>
        </Col>
      </Row>
    </div>

    {searchTopic==="RegistrationID"?<>
    {searchResults.length>0 ? <>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Student Name : {searchResults[0].title}.{searchResults[0].applicantName} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Student ID : {searchResults[0].registrationNo} </Form.Label>
    </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Course Type : {searchResults[0].selectedCourse.courseOption} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Payment Status : {searchResults[0].coursePayment} </Form.Label>
    </Col>
    </Row>
    <Table hover responsive className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Course Code</th>
                <th>Course Title</th>
               <th>Assigned</th>
                </tr>
            </thead>
            <tbody>
            {updated && tempMaterial.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.courseCode}</td>
            <td>{item.courseTitle}</td>            
           <td><FormGroup switch>
           <Input
          type="switch"
          checked={item.assignStatus}
          onChange={() => {
            //setState(!state);
            //console.log("Item : ", item);
            handleSwitchUpdate(item);
          }}
          /*onClick={() => {
            //setState(!state);
            //console.log("Item : ", item);
            handleSwitchUpdate(item);
          }}*/
        />
            </FormGroup></td>
            </tr>)}
            </tbody>
    </Table> 
    <Button onClick={(event)=>{handleAssignMaterialTask(event)}}>Submit</Button>    
    </>:<div>No Records Found!</div>}  </>: searchTopic==="AssignedStatus"?<>
    <Table hover responsive className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Registration No</th>
                <th>Total Books</th>
               <th>Books Assigned</th>
               <th>Assigned %</th>
               <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {updated && searchResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.registrationNo}</td>
            <td>{item.totalBooks}</td>  
            <td>{item.booksAssigned}</td>  
            <td>{item.assignedPercentage}</td>   
            <td><FontAwesomeIcon icon={faEye} className="me-2" onClick={event=>{handleViewMaterials(item)}}/></td>     
            </tr>)}
            </tbody>
    </Table></>:null}   
    </>
  )
}
