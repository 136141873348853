import React, { useEffect } from "react";
//import moment from "moment-timezone";
//import Datetime from "react-datetime";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';



export default function EditGallery(props) {
    const {newProjectName, newProjectDate, setNewProjectName, setNewProjectDate,alertClose }=props;

    useEffect(()=>{
      alertClose();
    },[])

  return (
    <div>
      
         <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Gallery information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Gallery Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Gallery name" value={newProjectName}  onChange={(event)=>{setNewProjectName(event.target.value)}}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="projectDate">
                <Form.Label>Date</Form.Label>
                <Form.Control required type="date" value={newProjectDate} onChange={(event)=>{setNewProjectDate(event.target.value)}} />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>

    </div>
  )
}
