import React, {useState, useEffect, useContext} from 'react';
import {ProductContext} from "../../ProductProvider";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
//import { Auth } from 'aws-amplify';
//import axios from 'axios';
import { courseMaterialData } from '../../ProData';

//const config = require('../../config.json');

export default function ScoreEdit(props) {
    const {profileDetails, editToggle, processingData, formType, selectedScore} = props;
    const { alertClose, alertDetails, setAlertDetails, generateDate, loggedUser, handleProcessAssessment, handleUpdateAssessment } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [optedCourse, setOptedCourse]=useState({courseName:'Introduction to Scripture',courseCode:'1Bb01', courseStatus:'Active', courseResult:'PASS', courseCompletedOn:'', duration:'', fees:'', feesOpted:'', payOptions:'', payStatus:'', grade:''});
    const [markSheet, setMarkSheet]= useState({totalMarks:100,markScored:0,examMode:'Online', examType:'YesNo', result:'', grade:'', evaluatedBy:'', evaluatedOn:'', remarks:'',publishDate:'', examDate:''});
    const [isDisabled, setIsDisabled]=useState(true);
    //const [courseOptionAvailable, setCourseOptionAvailable]= useState([]);

    useEffect(()=>{
        console.log("Props : ", props);
       //   initializeValues();
       const tdate=generateDate();
       //console.log("Today's Date : ", tdate);
       setMarkSheet({...markSheet,
         examDate:tdate,
         evaluatedOn:tdate,
         publishDate:tdate
         });
       if(formType==="View"){
        setIsDisabled(true);
       }else{
        setIsDisabled(false);
       }
       if(formType==="View"||formType==="Edit"){
        const tcourse=selectedScore.courseName+"-("+selectedScore.courseCode+")";
        //console.log("tcourse : ", tcourse);
        setMarkSheet({...markSheet,
        totalMarks:selectedScore.totalMarks,
        markScored:selectedScore.totalMarksObtained,
        registrationNo:selectedScore.registrationNo,
        assessmentID:selectedScore.assessmentID,
        assessmentStatus:selectedScore.assessmentStatus,
        grade:selectedScore.assessmentGrade,
        result:selectedScore.assessmentResult,
        examMode:selectedScore.assessmentCategory,
        remarks:selectedScore.assessmentRemarks,
        examDate:selectedScore.examDate,
        examType:selectedScore.examType,
        evaluatedBy:selectedScore.evaluatedBy,
        evaluatedOn:selectedScore.evaluatedOn,
        publishDate:selectedScore.publishDate
        });
        setOptedCourse({...optedCourse,
          courseCode:selectedScore.courseCode,
          courseName:selectedScore.courseName
        });
       }
        alertClose();
        setLoaded(true);
      },[])

      const dataValidation=()=>{
        var returnValue="FAIL";
        if (markSheet.markScored===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Mark Scored cannot be empty"
        });  
          return returnValue;
        }/*else if(markSheet.examDate===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Exam Date cannot be Empty"
        });  
          return returnValue;
        }else if(markSheet.publishDate===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Publish Date cannot be empty"
        });  
          return returnValue;
        }else if(markSheet.evaluatedOn===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Evaluation Date cannot be empty"
        });  
          return returnValue;
        }else if(markSheet.evaluatedBy===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Evaluated By is Empty. If not applicable, then update as NA"
        });  
          return returnValue;
        }*/else if(markSheet.totalMarks===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Total Marks cannot be Empty"
        });  
          return returnValue;
        }else if(markSheet.result===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Result is Empty. If not applicable, then update as NA"
        });  
          return returnValue;
        }else if(markSheet.grade===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Grade is Empty. If not applicable, then update as NA"
        });  
          return returnValue;
        }else if(markSheet.remarks===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Remarks is Empty. If not applicable, then update as NA"
        });  
          return returnValue;
        }else{
          return "PASS";
        }
      }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
          const validationResult=await dataValidation();
          if(validationResult==="PASS"){
          setLoaded(false);
           var tcourseOpted="";
            tcourseOpted=courseMaterialData.find(item=>item.courseTitle===optedCourse.courseName);
              const todayDate=generateDate();
              //const session = await Auth.currentSession();
              //const access_token=session.idToken.jwtToken;
              const tassessmentID=tcourseOpted.courseCode+"-M#"+profileDetails.registrationNo;
              const params={
                  registrationNo:profileDetails.registrationNo,
                  assessmentID:tassessmentID,
                  courseCode:tcourseOpted.courseCode,
                  courseName:tcourseOpted.courseTitle,
                  assessmentStatus:'Completed',
                  totalMarksObtained:markSheet.markScored,
                  totalMarks:markSheet.totalMarks,
                  assessmentGrade:markSheet.grade,
                  assessmentResult:markSheet.result,
                  assessmentCategory:markSheet.examMode,
                  assessmentResponse:[],
                  createdBy:loggedUser.nickname,
                  createdOn:todayDate,
                  updatedBy:loggedUser.nickname,
                  updatedOn:todayDate,
                  assessmentRemarks:markSheet.remarks,
                  examDate:markSheet.examDate,
                  examType:markSheet.examType,
                  evaluatedBy:markSheet.evaluatedBy,
                  evaluatedOn:markSheet.evaluatedOn,
                  publishDate:markSheet.publishDate
              }
             // console.log("Params : ", params);
              await handleProcessAssessment(params);
              await processingData();
              setLoaded(true);
              editToggle();
              
            /*  const registrationno=profileDetails.registrationNo;
              await axios.post(`${config.api.invokeURL}/dashboard/scores/${registrationno}`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  console.log("Response : ", response);
                //fetchApplicationDetails();
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Successfully Updated"
                }); 
                window.scrollTo(0,0);
                //courseToggle();
                //handleSearch();
                  setLoaded(true);            
                })
                .catch((error)=>{
                  console.log("Error :", error);
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })*/
              }
              }catch(error){
                //console.log("Error :", error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true); 
          }
    }

    const handleUpdate=async(event)=>{
      event.preventDefault();
      try{
        const validationResult=await dataValidation();
        if(validationResult==="PASS"){
        setLoaded(false);
         var tcourseOpted="";
          tcourseOpted=courseMaterialData.find(item=>item.courseTitle===optedCourse.courseName);
            const todayDate=generateDate();
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
            //const tassessmentID=tcourseOpted.courseCode+"-M#"+profileDetails.registrationNo;
            const params={
                registrationNo:selectedScore.registrationNo,
                assessmentID:selectedScore.assessmentID,
                courseCode:tcourseOpted.courseCode,
                courseName:tcourseOpted.courseTitle,
                assessmentStatus:'Completed',
                totalMarksObtained:markSheet.markScored,
                totalMarks:markSheet.totalMarks,
                assessmentGrade:markSheet.grade,
                assessmentResult:markSheet.result,
                assessmentCategory:markSheet.examMode,
                assessmentResponse:[],
                createdBy:loggedUser.nickname,
                createdOn:todayDate,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate,
                assessmentRemarks:markSheet.remarks,
                examDate:markSheet.examDate,
                examType:markSheet.examType,
                evaluatedBy:markSheet.evaluatedBy,
                evaluatedOn:markSheet.evaluatedOn,
                publishDate:markSheet.publishDate
            }
           // console.log("Params : ", params);
            await handleUpdateAssessment(params);
            await processingData();
            setLoaded(true);
            editToggle();
            
          /*  const registrationno=profileDetails.registrationNo;
            await axios.post(`${config.api.invokeURL}/dashboard/scores/${registrationno}`, params,{
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }})
              .then(async(response)=>{
                console.log("Response : ", response);
              //fetchApplicationDetails();
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Successfully Updated"
              }); 
              window.scrollTo(0,0);
              //courseToggle();
              //handleSearch();
                setLoaded(true);            
              })
              .catch((error)=>{
                console.log("Error :", error);
                  setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Error Occured : " + error.message
                }); 
                window.scrollTo(0,0);
                setLoaded(true);
              })*/
            }
            }catch(error){
              //console.log("Error :", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true); 
        }
  }

    const handleSubjectSelection=async(event)=>{
      const tcourseOpted=courseMaterialData.find(item=>item.courseTitle===event.target.value);
      //console.log("selected course : ", tcourseOpted);
      await setOptedCourse({...optedCourse, 
        courseName:tcourseOpted.courseTitle,
        courseCode:tcourseOpted.courseCode
      });
    }

    const handleMarkValidation=(event)=>{
      var tresult="";
      var tremarks="";
      //console.log("event value : ", event.target.value);
      if(parseFloat(event.target.value)>parseFloat(markSheet.totalMarks)){
        //console.log("event value : ", markSheet.totalMarks);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Mark Scored cannot be greater than Total Marks"
      });  
      }else{
      const tgrade=(parseFloat(event.target.value)/parseFloat(markSheet.totalMarks))*100;
      const ttgrade=(tgrade).toFixed(2)+"%";
      if(tgrade>=40){
        tresult="PASS";
      }else{
        tresult="FAIL";
      }
      if(tgrade>=50 && tgrade<=63){
        tremarks="Need more improvement";
      }else if(tgrade>=64 && tgrade<=66){
        tremarks="Good";
      }else if(tgrade>=67 && tgrade<=69){
        tremarks="Very Good";
      }else if(tgrade>=70){
        tremarks="Excellent";
      }
      setMarkSheet({...markSheet,
         markScored:parseFloat(event.target.value),
         grade:ttgrade,
         remarks:tremarks,
         result:tresult});
      }
    }

    const handleTotalMarksChange=(event)=>{
      var tresult="";
      //console.log("event value : ", event.target.value);

      if(parseFloat(event.target.value)<parseFloat(markSheet.markScored)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Total Marks cannot be lesser than Mark Scored"
      });  
      }else{
      const tgrade=(parseFloat(markSheet.markScored)/parseFloat(event.target.value))*100;
      const ttgrade=(tgrade).toFixed(2)+"%";
      if(tgrade>=40){
        tresult="PASS";
      }else{
        tresult="FAIL";
      }
      setMarkSheet({...markSheet,
        totalMarks:parseFloat(event.target.value),
        grade:ttgrade,
        result:tresult});
      }
    }

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose}>
    {alertDetails.message}
    </Alert>

    <Card border="light" className=" bg-white shadow-sm mb-4">
    <Card.Body>     
    <h5 className="mb-4">{formType} Marks</h5>
    <Form>
    <Row>
    <Form.Group id="otp">
         <Form.Label>Subject Name</Form.Label>
         <Input type="select" name="courseName"
            value={optedCourse.courseName} disabled={isDisabled}
            onChange={(event)=>{handleSubjectSelection(event)}}>
            {courseMaterialData.map(item => {
        return (<option key={item.courseTitle} value={item.courseTitle}><>{item.courseTitle}-({item.courseCode})</></option>);
        })}
        </Input>
       </Form.Group>
    </Row>
    <Row>
        <Form.Group id="emal">
            <Form.Label>Subject ID</Form.Label>
            <Input type="text" name="coursecode"
                value={optedCourse.courseCode} disabled>
            </Input>
            </Form.Group>
    </Row>
    <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="emal">
            <Form.Label>Marks Scored</Form.Label>
            <Input type="number" name="markScored" disabled={isDisabled}
                value={markSheet.markScored} onChange={(event)=>{handleMarkValidation(event)}}>
            </Input>
            </Form.Group>
        </Col>
        <Col>
        <Form.Group id="otp">
            <Form.Label>Total Marks</Form.Label>
            <Input type="number" name="totalMarks" disabled={isDisabled}
                value={markSheet.totalMarks} onChange={(event)=>{handleTotalMarksChange(event)}}>
            </Input>
        </Form.Group>
        </Col>
    </Row>
    <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="emal">
            <Form.Label>Grade</Form.Label>
            <Input type="text" name="markScored" disabled={isDisabled}
                value={markSheet.grade} onChange={(event)=>{setMarkSheet({...markSheet, grade:event.target.value})}}>
            </Input>
            </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
        <Form.Group id="emal">
            <Form.Label>Result</Form.Label>
            <Input type="text" name="result" disabled={isDisabled}
                value={markSheet.result} onChange={(event)=>{setMarkSheet({...markSheet, result:event.target.value})}}>
            </Input>
            </Form.Group>
        </Col>  
    </Row>
    <Row>
    <Col md={6} className="mb-3">
      <Form.Group id="emal">
        <Form.Label>Exam Type</Form.Label>
        <Input type="select" name="examType" disabled={isDisabled}        
        value={markSheet.examType} onChange={(event)=>{setMarkSheet({...markSheet, examType:event.target.value})}}>
            <option value="YesNo">YesNo</option>
            <option value="MCQ">MCQ</option>
            <option value="Descriptive">Descriptive</option>
            <option value="Offline">Offline</option>
        </Input>
      </Form.Group>
    </Col>
    <Col md={6} className="mb-3">
        <Form.Group id="emal">
            <Form.Label>Exam Mode</Form.Label>
            <Input type="select" name="markScored" disabled={isDisabled}
                value={markSheet.examMode} onChange={(event)=>{setMarkSheet({...markSheet, examMode:event.target.value})}}>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
                <option value="Hybrid">Hybrid</option>
            </Input>
            </Form.Group>
        </Col>
    </Row>
    <Row>
        <Col md={6} className="mb-3">
      <Form.Group id="emal">
        <Form.Label>{markSheet.examMode==="Online"?<>Published Date</>:<>Date Sent</>}</Form.Label>
        <Input type="date" name="examDate" disabled={isDisabled}
            value={markSheet.publishDate} onChange={(event)=>{setMarkSheet({...markSheet, publishDate:event.target.value})}}>
        </Input>
      </Form.Group>
    </Col>
    <Col md={6} className="mb-3">
      <Form.Group id="emal">
        <Form.Label>{markSheet.examMode==="Online"?<>Exam Date</>:<>Date Received</>}</Form.Label>
        <Input type="date" name="examDate" disabled={isDisabled}
            value={markSheet.examDate} onChange={(event)=>{setMarkSheet({...markSheet, examDate:event.target.value})}}>
        </Input>
      </Form.Group>
    </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
      <Form.Group id="emal">
        <Form.Label>Evaluated By</Form.Label>
        <Input type="text" name="evaluvatedBy" disabled={isDisabled}
            value={markSheet.evaluatedBy} onChange={(event)=>{setMarkSheet({...markSheet, evaluatedBy:event.target.value})}}>
        </Input>
        </Form.Group>
    </Col>
    <Col md={6} className="mb-3">
      <Form.Group id="emal">
        <Form.Label>Evaluated On</Form.Label>
        <Input type="date" name="evaluvatedBy" disabled={isDisabled}
            value={markSheet.evaluatedOn} onChange={(event)=>{setMarkSheet({...markSheet, evaluatedOn:event.target.value})}}>
        </Input>
        </Form.Group>
    </Col>

    </Row>
    <Row>
        <Form.Group id="emal">
            <Form.Label>Remarks</Form.Label>
            <Input type="text" name="remarks" disabled={isDisabled}
                value={markSheet.remarks} onChange={(event)=>{setMarkSheet({...markSheet, remarks:event.target.value})}}>
            </Input>
            </Form.Group>
    </Row>

    <div className="mt-3 text-center">
     {formType==="New"?<Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Add</Button>
     :formType==="Edit"?<Button variant="primary" type="submit" onClick={(event)=>{handleUpdate(event)}}>Update</Button>
     :null}
    </div>
 </Form>
 </Card.Body>
</Card>
</>  )
}
