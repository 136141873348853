import React, {useEffect, useState, useContext} from 'react';
import { faEye, faEllipsisH, faCheck, faPlus, faHome, faHandPointRight, faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Breadcrumb, BreadcrumbItem } from "reactstrap";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { Link } from "react-router-dom";
import MCQModal from '../exam/MCQModal';
import YesNoModal from '../exam/YesNoModal';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import {Routes} from '../../routes';
//import ScoreView from '../assessment/ScoreView';
//import { sampleQuestionTable } from '../../ProData';
//import userEvent from '@testing-library/user-event';
import QuestionSheetForm from '../exam/QuestionSheetForm';
import AssignExamForm from '../exam/AssignExamForm';
import DescriptiveModal from '../exam/DescriptiveModal';
import GeneralInstructions from '../exam/GeneralInstructions';
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { downloadQuestionHeaders } from "../../ProData";

const config = require('../../config.json');


export default function ExamInfo() {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [initials, setInitials] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [questionCollapsed, setQuestionCollapsed] = useState(true);
  const [assignExamCollapsed, setAssignExamCollapsed]= useState(true);
  const [generalCollapsed, setGeneralCollapsed]= useState(true);
  const [questionID, setQuestionID]=useState();
  const [formType, setFormType] = useState("View");
  const [questionTable, setQuestionTable] = useState([]);
  const [displayQuestions, setDisplayQuestions]=useState([]);
  const componentRef = React.useRef(null);
  const csvLinkEl = React.createRef();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchData();
      setInitials(true);  
    }else{
      validateLogin();
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])

  const fetchData=async()=>{
    try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //const clientID="VJDEPTH";
      //const applicationStatus=status;
      const fetchid="All";
      await axios.get(`${config.api.invokeURL}/dashboard/exam/list-all-questions?fetchid=${fetchid}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
          //console.log("Res : ", response);
          setQuestionTable(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length +" - Questions Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          //console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
      })  
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
  }

  const handleQuestionDisplay =async(event, item, mode)=>{
    event.preventDefault();
    setLoaded(false);
    //console.log("Item :", item);
    setQuestionID(item);
    if(mode==="Edit"){
      setDisplayQuestions(item.assessmentAnswers);
    }else{
      setDisplayQuestions(item.assessmentQuestions);
    }
    setFormType(mode);
    if(mode==="Exam"){
      generalToggle();
    }else{
      toggle();
    }
    setLoaded(true);
    
  }

  const toggle = () => {
      // console.log("clicked");
         setCollapsed(!collapsed);
  }

  const questionToggle = () => {
  // console.log("clicked");
      setQuestionCollapsed(!questionCollapsed);
  }

  const assignExamToggle = () => {
  // console.log("clicked");
      setAssignExamCollapsed(!assignExamCollapsed);
  }

  const handleGeneralSubmit=()=>{
    generalToggle();
    toggle();
  }

  const generalToggle = () => {
    // console.log("clicked");
        setGeneralCollapsed(!generalCollapsed);
    }

  const handleQuestionSheet=(event)=>{
    event.preventDefault();
    questionToggle();

  }

  const handleAssignExam=(event,item)=>{
    event.preventDefault();
  //  console.log("item :",item);
    setQuestionID(item);
    assignExamToggle();

  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  return (
    <>
        <Helmet>
      <title>Manage Exam - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Manage Exam - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Manage Exam - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
        <BreadcrumbItem>
          <Link to={Routes.LandingPage.path}>
          <FontAwesomeIcon icon={faHome} />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem >
          Exam
        </BreadcrumbItem>
      </Breadcrumb>
      </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadQuestionHeaders}
              filename="download.csv"
              data={questionTable}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>

      {/*View && Edit*/}
      <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
              <ModalHeader toggle={toggle}>
              {questionID?<p>{questionID.courseName} - {questionID.assessmentCategory} Exam</p>:null}
              </ModalHeader>
              <ModalBody> 
              {questionID && questionID.assessmentType==="YesNo"? 
                <YesNoModal questionID={questionID} formType={formType} displayQuestions={displayQuestions} toggle={toggle}/>:
                (questionID && questionID.assessmentType==="MCQ")
                ?<MCQModal questionID={questionID} formType={formType} displayQuestions={displayQuestions} toggle={toggle}/>:
                <DescriptiveModal questionID={questionID} formType={formType} displayQuestions={displayQuestions} toggle={toggle}/>}
              </ModalBody>
              <ModalFooter>
          {' '}
          <Button onClick={toggle}>
              Close
          </Button>
          </ModalFooter>
      </Modal>
      
      {/*New Question Sheet*/}
      <Modal toggle={questionToggle} isOpen={!questionCollapsed} style={{top:'10%'}}>
              <ModalHeader toggle={questionToggle}>
                New Question Paper
              </ModalHeader>
              <ModalBody>  
                <QuestionSheetForm questionTable={questionTable}/>
              </ModalBody>
              <ModalFooter>
          {' '}
          <Button onClick={questionToggle}>
              Close
          </Button>
          </ModalFooter>
      </Modal>

      {/*Assign Exam*/}
      <Modal toggle={assignExamToggle} isOpen={!assignExamCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={assignExamToggle}>
                      Assign Exam
                    </ModalHeader>
                    <ModalBody>  
                      <AssignExamForm questionID={questionID} assignExamToggle={assignExamToggle}/>
                    </ModalBody>
                    <ModalFooter>
                {' '}
                <Button onClick={assignExamToggle}>
                    Close
                </Button>
                </ModalFooter>
      </Modal>

      {/*General Guidelines*/}
      <Modal toggle={generalToggle} isOpen={!generalCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={generalToggle}>
                      Exam - General Guidelines
                    </ModalHeader>
                    <ModalBody>  
                      <GeneralInstructions />
                    </ModalBody>
                    <ModalFooter>
                <Button onClick={handleGeneralSubmit}>
                    Start
                </Button>

                {' '}
                <Button onClick={generalToggle}>
                    Close
                </Button>
                </ModalFooter>
      </Modal>


    <div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} variant="secondary" className="text-dark me-2" onClick={(event)=>{handleQuestionSheet(event)}}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
          {/*<div className="d-flex">
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Active"
          />
  </div>*/}
          </div>
        <Table ref={componentRef} responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Course Name</th>
                <th>Question ID</th>
                <th>Assessment Type</th>
               <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {initials && questionTable.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.title}.{item.courseName}</td>
            <td>{item.questionID}</td>
            <td>{item.assessmentType}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleQuestionDisplay(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Questions
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleQuestionDisplay(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Edit Questions
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleAssignExam(event, item)}}>
                <FontAwesomeIcon icon={faHandPointRight} className="me-2" /> Assign Exam
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleQuestionDisplay(event, item, "Exam")}}>
                <FontAwesomeIcon icon={faPencilSquare} className="me-2" /> Exam Mode
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>
    </div>
    </>
  )
}
