import React, {useEffect, useState, useContext} from 'react'
import {ProductContext} from "../../ProductProvider";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import { Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { Table } from '@themesberg/react-bootstrap';

const config = require('../../config.json');

export default function CompletedEvaluation() {
    const { alertClose, alertDetails, setAlertDetails } = useContext(ProductContext);
    const [batchYear, setBatchYear] = useState([]);
    const [selectedBatch, setSelectedBatch]=useState('');
    const [loaded, setLoaded] = useState(false);
    const [applicationDetails, setApplicationDetails]=useState([]);

    useEffect(()=>{
        alertClose();
        processBatchYear();
        setLoaded(true);
    },[])

    const processBatchYear=()=>{
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var tbatchYear=[];
        for(var i=0; i<10;i++){
          tbatchYear.push(date_year-i);
        }
        setBatchYear(tbatchYear);
        setSelectedBatch(date_year);
    }

    const validationCriteria=()=>{
        var returnValue="Failed";
        if(selectedBatch===""){
            returnValue= "Failed";
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Select the Batch"
            }); 
      
        }else{
            returnValue="Passed"; 
        }
        return returnValue;
    }

    const handleSubmit=async()=>{
        try{
            const validationResult=validationCriteria();
            if(validationResult==="Passed"){
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const applicationStatus="Completed";
            await axios.get(`${config.api.invokeURL}/dashboard/scores/evaluation?fetchid=${applicationStatus}&fetchOption=${selectedBatch}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
              //console.log("Response : ", response);
                setApplicationDetails(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - Completed Evaluation"
                });  
                //console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        }
    }


  return (
<>
    <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
    <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Select Batch</Form.Label>
                <Form.Select value={selectedBatch} 
                    onChange={(event)=>{setSelectedBatch(event.target.value)}}>
                    {batchYear.map(item => {
                    return (
                    <option key={item} value={item}>{item}</option>
                    );
                    })}
           </Form.Select>              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">

         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Search</Button>
           </div>
           </Col>

           </Row>

         </Card.Body>
         </Card>

         <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Registration No</th>
                <th>Assessment ID</th>
                <th>Course Name</th>
                <th>Exam Type</th>
                <th>Exam Date</th>
                </tr>
            </thead>
            <tbody>
            {applicationDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.registrationNo}</td>
            <td>{item.assessmentID}</td>
            <td>{item.courseName}</td>
            <td>{item.examType}</td>
            <td>{item.examDate}</td>
            </tr>)}
            </tbody>
        </Table>
    </>  
)
}
