import React,{useState, useContext, useEffect} from 'react';
import Calendar from 'react-awesome-calendar';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import CalendarForm from './CalendarForm';
import { Button, Dropdown, ButtonGroup, Breadcrumb} from '@themesberg/react-bootstrap';
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { eventsColour } from '../../ProData';
import axios from "axios";
import { Auth } from "aws-amplify";
//import EventView from './EventView';
import {Helmet} from 'react-helmet-async';
import {Routes} from '../../routes';
import { Link } from "react-router-dom";
import { v1 as uuidv1 } from 'uuid';
 
const config = require('../../config.json');



export default function CalendarView() {
    const [calendarDetails, setCalendarDetails]=useState({id:'', startFrom:'', endTo:'', eventType:'Online Class',header:'',comments:'', presenter:'', location:'', audience:'NA' });
    const [collapsed, setCollapsed] = useState(true);
    //const [viewCollapsed, setViewCollapsed] = useState(true);
    const [loaded, setLoaded]=useState(true);
    const { generateDate, alertDetails, setAlertDetails, alertClose, loggedUser, validateLogin} = useContext(ProductContext);
    const [eventsData, setEventsData]=useState([]);
    const [responseData, setResponseData]=useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          const tDate= generateCalendarDate();
          fetchEvents(tDate);
          //setEventsData(events);
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
       // console.log(batchYear);
      },[loggedUser])

      const fetchEvents=async(tDate)=>{
          try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken; 
            await axios.get(`${config.api.invokeURL}/dashboard/calendar?calendarMonth=${tDate}`, {  
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            //console.log("Response : ", response);
                setResponseData(response.data);
                processEvents(response.data);
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Calendar Events fetched Successfully!"
              });  
            setLoaded(true);
        })
          }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);        
          }
      }

      const processEvents=(tEvents)=>{
        var tempEvents=[];
        for(const item in tEvents){
            var tItem={};
            tItem.id=tEvents[item].calendarID;
            tItem.color=tEvents[item].color;
            tItem.from=tEvents[item].startFrom;
            tItem.to=tEvents[item].endTo;
            tItem.title=tEvents[item].title + "-"+ tEvents[item].header;
            tempEvents.push(tItem);
        }
        setEventsData(tempEvents);
      }

      const generateCalendarDate=()=>{
        const current_datetime = new Date();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        const accountNo = tempUserId.concat(date_year,"-",month_value);
        return accountNo;
    }

    const handleTimeLineClick=(e)=>{
        const testStart=new Date(e.year, e.month,e.day,e.hour);
        const testEnd=new Date(e.year, e.month,e.day,e.hour+1);
        setCalendarDetails({...calendarDetails, 
          startFrom:testStart,
            endTo:testEnd});
            setIsDisabled(false);
        toggle();
    }

    const handleNewClick=(e)=>{
        e.preventDefault();
        //console.log("timeline click : ", e);
        const utcStartTime=new Date();
        const utcEndTime=new Date();
        setCalendarDetails({...calendarDetails, 
          startFrom:utcStartTime,
            endTo:utcEndTime});
            setIsDisabled(false);
        toggle();
    }

    const validateFields=()=>{
      var validateResult=false;
      if(calendarDetails.startFrom===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Start Date and Time cannot be empty!"
      });  
      }else if(calendarDetails.endTo===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "End Date and Time cannot be empty!"
      });  
      }else if(calendarDetails.endTo<=calendarDetails.startFrom){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "End Date and Time cannot be lesser than Start Date and Time!"
      });  
      }else if(calendarDetails.header===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Calender Title cannot be Empty!"
      });  
      }else if(calendarDetails.comments===""){
        setCalendarDetails({...calendarDetails, comments:"NA"});
        validateResult=true;
      }else if(calendarDetails.presenter===""){
        setCalendarDetails({...calendarDetails, presenter:"NA"});
        validateResult=true;
      }else if(calendarDetails.location===""){
        setCalendarDetails({...calendarDetails, location:"NA"});
        validateResult=true;
      }else{
        validateResult=true;
      }
      return validateResult;
    }

    const handleEventSave=async()=>{
        setLoaded(false);
        try{
          const validateResult= validateFields();
          if(validateResult){
          const utcStartTimea=(calendarDetails.startFrom).toString();
          const utcStartTimeb = utcStartTimea.replace(/-/g, " ").replace("GMT+0530", "GMT+0000"); 
          const utcStartTime=new Date(utcStartTimeb).toISOString();
          const utcEndTimea=(calendarDetails.endTo).toString();
          const utcEndTimeb = utcEndTimea.replace(/-/g, " ").replace("GMT+0530", "GMT+0000"); 
          const utcEndTime=new Date(utcEndTimeb).toISOString();
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken; 
          //const tid=await generateOrderNo();
          const tid=uuidv1();
          const tDate= await generateDate();
          const tColour=eventsColour.filter(item=>item.id===calendarDetails.eventType);  
            const params={
                calendarID:tid,
                color: tColour[0].color,
                startFrom:utcStartTime,
                endTo:utcEndTime,
                title:calendarDetails.eventType,
                eventHeader:calendarDetails.header,
                comments:calendarDetails.comments,
                presenter:calendarDetails.presenter,
                location:calendarDetails.location,
                audience:calendarDetails.audience,
                updatedBy:loggedUser.name,
                updatedOn:tDate
            }
            //console.log("Params : ", params);
            await axios.post(`${config.api.invokeURL}/dashboard/calendar`,params, {  
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("Response : ", response);
                await fetchEvents(utcStartTime);
                  setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Calendar Entry Created Successfully!"
                });  
                toggle();
                setLoaded(true)
            }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);
            window.scrollTo(0,0);
          })
        }else{
          setLoaded(true);
        }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);  
        }
    }

    const toggle = () => {
        setCollapsed(!collapsed);
    }

    const handleChange=(event)=>{
        //console.log("Change Event : ", event);
        if(event.mode==="monthlyMode"){
            const tYear=event.year.toString();
            const tMonth=(event.month+1).toString();
            var month_value="";
            tMonth.length===1? (month_value=month_value.concat(0,tMonth)):(month_value=tMonth);
    
            const tDate=tYear+"-"+month_value;
            fetchEvents(tDate);
            //console.log("Date : ", tDate);
        }
    }

    const handleClick=(event)=>{
        const temp=responseData.filter(item=>item.calendarID=event);
        const startTest=new Date(temp[0].startFrom);
        const endTest=new Date(temp[0].endTo);
        var tEvent=temp[0];
        tEvent.startFrom=startTest;
        tEvent.endTo=endTest;
        //console.log("selected Item : ", tEvent);
        //setEventItem(tEvent);
        setIsDisabled(true);
        setCalendarDetails(tEvent);
        
        //viewToggle();
        toggle();
    }

    const handleEventRemove=async(event)=>{
      event.preventDefault();
      try{
        const calendarid=calendarDetails.calendarID;
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken; 
        await axios.delete(`${config.api.invokeURL}/dashboard/calendar?calendarid=${calendarid}`, {  
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }
        }).then(async() => {
         //console.log("Response : ", new Date(utcStartTime).toISOString());
          const test=generateCalendarDate();
          await fetchEvents(test);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Calendar Entry Removed Successfully!"
          });  
          toggle();
          setLoaded(true)
      }).catch((error)=>{
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      window.scrollTo(0,0);
    })
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Error Occured : " + error.message
      }); 
      window.scrollTo(0,0);
      setLoaded(true);  
      }
    }

  return (
  <>
    <Helmet>
      <title>Course Calendar - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Course Calendar - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Course Calendar - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>      <Link to={Routes.LandingPage.path}>
            <FontAwesomeIcon icon={faHome} /></Link>    </Breadcrumb.Item>
            <Breadcrumb.Item>Course</Breadcrumb.Item>
            <Breadcrumb.Item active>Course Calendar</Breadcrumb.Item>
          </Breadcrumb>       
        </div>
       {/*} <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Evaluation"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadAssessmentHeaders}
              filename="download.csv"
              data={applicationDetails}
              ref={csvLinkEl}
            />
          </ButtonGroup>
  </div>*/}
       
      </div>

    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>

    {/*Calendar Form*/}
    <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                <ModalHeader toggle={toggle}>
                    Calendar - {isDisabled?"View":"Add"} Events 
                </ModalHeader>
                <ModalBody>   
                    <CalendarForm isDisabled={isDisabled} calendarDetails={calendarDetails} setCalendarDetails={setCalendarDetails}/> 
                </ModalBody>
                <ModalFooter>
                {!isDisabled?<Button onClick={handleEventSave}>
            Save
        </Button>:<Button onClick={handleEventRemove}>
            Remove
        </Button>}

        {' '}
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>
    </Modal>

    {/*Event View*/}
    {/*<Modal toggle={viewToggle} isOpen={!viewCollapsed} style={{top:'10%'}}>
                  <ModalHeader toggle={viewToggle}>
                      Calendar - View Event
                  </ModalHeader>
                  <ModalBody>   
                      <EventView eventItem={eventItem}/> 
                  </ModalBody>
                  <ModalFooter>
          </ModalFooter>
</Modal>*/}

    <h3 className="align-items-center">Course Calendar</h3>

    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} variant="secondary" className="text-dark me-2" onClick={(event)=>{handleNewClick(event)}}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
          {/*<div className="d-flex">
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Active"
          />
          </div>*/}
    </div>
        <Calendar
                events={eventsData}
                onClickTimeLine={event=>handleTimeLineClick(event)}
                onChange={event=>handleChange(event)}
                onClickEvent={event=>handleClick(event)}
        />
      </>
  )
}
