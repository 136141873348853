import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {Breadcrumb, BreadcrumbItem  } from "reactstrap";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faEdit, faTrash, faEllipsisH, faHome } from '@fortawesome/free-solid-svg-icons';
import {Modal, ModalBody, ModalHeader, ModalFooter, Table, Alert  } from "reactstrap";
import {Routes} from '../../routes';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import InfoDeskForm from './InfoDeskForm';
const config = require('../../config.json');

export default function ActiveInfo() {
    const { validateLogin, alertClose , alertDetails, setAlertDetails, loggedUser} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [newCollapsed, setNewCollapsed] = useState(true);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState('vjdepth-logo.png');
    const [newProjectName, setNewProjectName]=useState('');
    const [infoDesk, setInfoDesk]=useState({startDate:'', endDate:'',category:'Flash News'});
    const [flashNews, setFlashNews]=useState('');
    const [scrollMsg, setScrollMsg]=useState({title:'', email:'', link:'', message:'',image:'vjdepth-logo.png'});
    const [faithMsg, setFaithMsg]=useState({title:'', head:'', message:''});
    const [activePost, setActivePost]=useState([]);
    const [formType, setFormType]=useState("View");
    const [selectedItem, setSelectedItem]=useState('');

    useEffect(() => {
        alertClose();  
       if(loggedUser.isAuthenticated){
            fetchActiveInfo();
        }else{
            validateLogin();
        }       
    }, [loggedUser]);

    /*const postOnS3 = async()=>{
        try{
            const fileName="Gallery_"+new Date().getTime();
          const newFileName=fileName+".jpeg";
          const bucketName=config.s3.bucketName+"/gallery";
          //console.log("bucket name : ", bucketName);
          var file = imageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
          //console.log("New file : ", newFile);
          await axios.get(`${config.api.invokeUrl}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
               headers:{
                   //Authorization: access_token,
                  // 'x-api-key': config.api.key
               }
           }).then(async(response)=>{
            //   console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"image/jpeg"
                 },body: newFile});
                // console.log("Res : ", res);
                 if(res.status===200){
                    fetchGalleryImages();
                     setProfileImage(newFileName);
                     //var timestamp = new Date().getTime();     
                     var tprofile = document.getElementById("profile");
                     //var imgsrc="";
                     //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                     //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                     //console.log("tprofle : ", tprofile.src);
    
                 }
            }).catch((error)=>{
                console.log("error1:", error);
             // setDispMsg(error.message);
             // hideLoader();
           })
        }catch(error){
          console.log("error1:", error);
        }
    
      }*/

    /*const toggle = () => {
           //console.log("clicked");
             setCollapsed(!collapsed);
    }
*/
    const newToggle = () => {
    //console.log("clicked");
        alertClose();
        setNewCollapsed(!newCollapsed);
    }

    /*const editToggle = () => {
        //console.log("clicked");
            setEditCollapsed(!editCollapsed);
    }*/
    const generateDate = async () => {
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var todayDate="";
        const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        return tDate;
        //console.log("acc : ", accountNo);
    }    

    const fetchActiveInfo =async()=>{
        try{
            setLoaded(false);
           //console.log("Testing");
           const session = await Auth.currentSession();
           const access_token=session.idToken.jwtToken;
            const startDate=await generateDate();
            //console.log("start Date : ", startDate);
            const clientID="VJDepth";
        await axios.get(`${config.api.invokeURL}/dashboard/newsinfo?clientID=${clientID}&startDate=${startDate}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log(response);
            setActivePost(response.data);
           // setGalleryImages(response.data);
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length + " - Active Post available"
            });  
            setLoaded(true);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
            //setDispMsg(error.message);
        })
    }catch(error){
		//console.log(error);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0);   
        //setDispMsg(error.message);
    }
    }

    const handleProjectView=(event, item)=>{
        event.preventDefault();
    // console.log("key : ", item);
        setInfoDesk({...infoDesk,
        startDate:item.startDate,
        endDate:item.endDate,
        category:item.category
        });
        setFlashNews(item.flashNews);
        setFaithMsg(item.faithMsg);
        setScrollMsg(item.scrollMsg);
        const tImage=config.s3.scrollUrl+item.scrollMsg.image;
        setProfileImage(tImage);
        setFormType("View");
        //setKeyName(item.folderName);
        //setProjectName(item.projectName);
        newToggle();
    }

    const handleProjectEdit=(event, item)=>{
        event.preventDefault();
        //console.log("key : ", item);
        setInfoDesk({...infoDesk,
        startDate:item.startDate,
        endDate:item.endDate,
        category:item.category
        });
        setFlashNews(item.flashNews);
        setFaithMsg(item.faithMsg);
        setScrollMsg(item.scrollMsg);
        const tImage=config.s3.scrollUrl+item.scrollMsg.image;
        setProfileImage(tImage);
        setFormType("Edit");
        setSelectedItem(item);
        //setKeyName(item.folderName);
        //setProjectName(item.projectName);
        newToggle();
    }

    const handleProjectDelete =async(event, item)=>{
        event.preventDefault();
        try{
                const msgid=item.msgID;
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;     
                await axios.delete(`${config.api.invokeURL}/dashboard/newsinfo/${msgid}`,{
                headers:{
                            Authorization: access_token,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                    setAlertDetails({
                        ...alertDetails,
                        style:"success",
                        open:true,
                        message: item.msgID+ "  - Successfully Deleted"
                    }); 
                    fetchActiveInfo();
                    setLoaded(true);
                    window.scrollTo(0,0); 
            
                    }).catch((error)=>{
                        //console.log("error1:", error);
                        setAlertDetails({
                            ...alertDetails,
                            style:"warning",
                            open:true,
                            message:"Error Occured - " + error.message
                        }); 
                        setLoaded(true);
                        window.scrollTo(0,0);   
                    // setDispMsg(error.message);
                    // hideLoader();
                    })
        }
        catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);   
    }
    }

    const postOnS3 = async(fileName)=>{
        try{   
                //const fileName=newFileName
            //  const fileName="infoDesk_"+new Date().getTime();
            // const newFileName=newDoctorDept+"$"+newDoctorName+".jpeg";
            const tnewFileName=fileName+".jpeg";
            const keyName='images/infoDesk';
        
            const bucketName=config.s3.bucketName+keyName;
           // console.log("bucket name : ", bucketName);
           // console.log("file name : ", imageFile);
        
            var tfile = imageFile[0];
            var blob = tfile.slice(0, tfile.size, 'image/jpeg'); 
            const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
            //console.log("New file : ", newFile);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //console.log("access token : ", access_token);
            await axios.get(`${config.api.invokeURL}/dashboard/images/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
                headers:{
                    Authorization: access_token,
                    'x-api-key': config.api.key
                }       }).then(async(response)=>{
         //   console.log("Response : ", response);
            const res=await fetch(response.data.uploadURL, { method: 'PUT',
            headers:{
                "Content-Type":"image/jpeg"
                },body: newFile});
                //console.log("Res : ", res);
                if(res.status===200){
                //  fetchGalleryImages();
                    setProfileImage(tnewFileName);
                    //var timestamp = new Date().getTime();     
                    // var tprofile = document.getElementById("profile");
                    //var imgsrc="";
                    //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                    //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                    //console.log("tprofle : ", tprofile.src);
                //  setLoaded(true);
                //  newToggle();
                    // fetchGalleryImages();


                }
            }).catch((error)=>{
            //    console.log("error1:", error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                window.scrollTo(0,0);   
            // setDispMsg(error.message);
            // hideLoader();
        })
        
        }catch(error){
       // console.log("error1:", error);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0);   
        }

    }

/*const editOnS3 = async()=>{
    try{
        if(newProjectDate===""||newProjectName===""){
           // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter all project details along with an image"
            }); 
            window.scrollTo(0,0); 
        }else{         
        setLoaded(false);
        //const fileName=newProjectName+"_"+new Date().getTime();
      //const newFileName=fileName+".jpeg";
      const newKeyName=newProjectName+"$"+newProjectDate;
      const tKeyName="images/gallery/"+newKeyName;
      const bucketName=config.s3.bucketName+tKeyName;
      //console.log("bucket name : ", bucketName);
      //var file = imageFile;
      //var blob = file.slice(0, file.size, 'image/jpeg'); 
      //const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const inputParams={
        fileName:selectedProject,
        newKeyName:newKeyName
    }
   // const session = await Auth.currentSession();
   // const access_token=session.idToken.jwtToken;       
    await axios.post(`${config.api.invokeURL}/dashboard/images/gallery/editgallery`, inputParams,{
    //  await axios.get(`${config.api.invokeUrl}/images/projects/newproject?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
    //           Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
        //   console.log("Response : ", response);
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: newProjectName+ "  - Successfully Modified"
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
        editToggle();
        //fetchGalleryImages();

        /*  const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 newToggle();
                 fetchGalleryImages();
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }

  }
*/
    const handleProjectAdd=async(event)=>{
        event.preventDefault();
        setFormType('Add')
        newToggle();
    }

    const EditMessage=async(event)=>{
        event.preventDefault();
        try{
            if(infoDesk.startDate===""||infoDesk.endDate===""){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Start and End Date must be filled with values"
            }); 
            window.scrollTo(0,0); 
            }else if(infoDesk.startDate>infoDesk.endDate){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Start Date must be less than the End Date"
            }); 
            window.scrollTo(0,0); 
            }else {
                const msgid=selectedItem.msgID;
                var tscrollMsg=scrollMsg;
                if(imageFile===""){
                   /* setScrollMsg({...scrollMsg,
                        image:"vjdepth-logo.png"});*/
                    tscrollMsg.image="vjdepth-logo.png";
                }else{
                    /*await setScrollMsg({...scrollMsg,
                    image:msgid+".jpeg"});*/
                    tscrollMsg.image=msgid+".jpeg";
                    await postOnS3(msgid);
                }    
 
                const inputParams={
                    clientID:"VJDepth",
                    msgID:msgid,
                    startDate:infoDesk.startDate,
                    endDate:infoDesk.endDate,
                    category:infoDesk.category,
                    flashNews:flashNews,
                    scrollMsg:tscrollMsg,
                    faithMsg:faithMsg
                };
             //   console.log("params : ", inputParams);
             const session = await Auth.currentSession();
             const access_token=session.idToken.jwtToken;  
                await axios.patch(`${config.api.invokeURL}/dashboard/newsinfo/${msgid}`, inputParams,{
                headers:{
                            Authorization: access_token,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                    setAlertDetails({
                        ...alertDetails,
                        style:"success",
                        open:true,
                        message: newProjectName+ "  - Successfully Modified"
                    }); 
                    newToggle();
                    fetchActiveInfo();
                    setLoaded(true);
                    window.scrollTo(0,0); 
            
                    }).catch((error)=>{
                        //console.log("error1:", error);
                        setAlertDetails({
                            ...alertDetails,
                            style:"warning",
                            open:true,
                            message:"Error Occured - " + error.message
                        }); 
                        setLoaded(true);
                        window.scrollTo(0,0);   
                    // setDispMsg(error.message);
                    // hideLoader();
                    })
        }
        }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);   
    }
    }

    const AddNewMessage=async()=>{
        try{
            if(infoDesk.startDate===""||infoDesk.endDate===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Start and End Date must be filled with values"
                }); 
                window.scrollTo(0,0); 
            }else if(infoDesk.startDate>infoDesk.endDate){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Start Date must be less than the End Date"
                }); 
                window.scrollTo(0,0); 
            }else {
                setLoaded(false);
                const msgid=new Date().getTime().toString();
                //console.log("imageFile : ", imageFile);
                var tscrollMsg=scrollMsg;
                if(imageFile===""){
                    tscrollMsg.image="vjdepth-logo.png";
                }else{
                   // console.log("Else part");
                   tscrollMsg.image=msgid+".jpeg";
                    await postOnS3(msgid);
                }
                const inputParams={
                    clientID:"VJDepth",
                    msgID:msgid,
                    startDate:infoDesk.startDate,
                    endDate:infoDesk.endDate,
                    category:infoDesk.category,
                    flashNews:flashNews,
                    scrollMsg:tscrollMsg,
                    faithMsg:faithMsg
                };
                // console.log("params : ", inputParams);
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                await axios.post(`${config.api.invokeURL}/dashboard/newsinfo/${msgid}`, inputParams,{
                    headers:{
                            Authorization: access_token,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                        setAlertDetails({
                            ...alertDetails,
                            style:"success",
                            open:true,
                            message: msgid+ "  - Successfully Added"
                        }); 
                        newToggle();
                        fetchActiveInfo();
                        setLoaded(true);
                        window.scrollTo(0,0); 
                        }).catch((error)=>{
                        setAlertDetails({
                            ...alertDetails,
                            style:"warning",
                            open:true,
                            message:"Error Occured - " + error.message
                        }); 
                        setLoaded(true);
                        window.scrollTo(0,0);   
                    })
        }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });             
            setLoaded(true);
            window.scrollTo(0,0);   
        }
    }

  return (
    <div>
        <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
      <FontAwesomeIcon icon={faHome} />
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      News Section
    </BreadcrumbItem>
  </Breadcrumb>
        <h3>Active Post</h3>
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>

            <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={newToggle}>
            News Section - {formType}
            </ModalHeader>
            <ModalBody>
                <InfoDeskForm infoDesk={infoDesk} setInfoDesk={setInfoDesk} flashNews={flashNews} 
                setFlashNews={setFlashNews} scrollMsg={scrollMsg} setScrollMsg={setScrollMsg}
                faithMsg={faithMsg} setFaithMsg={setFaithMsg} formType={formType} 
                setImageFile={setImageFile} profileImage={profileImage} setProfileImage={setProfileImage} alertDetails={alertDetails}
                alertClose={alertClose}/>
                    </ModalBody>
            <ModalFooter> 
      {formType==="Add"?<Button
        color="primary"
        onClick={AddNewMessage}
      >
        Add Message       
        </Button>:formType==="Edit"?<Button
        color="primary"
        onClick={EditMessage}
      >
        Edit Message       
        </Button>:null}
  {' '}
      <Button onClick={newToggle}>
        Close
      </Button>
    </ModalFooter>
            </Modal>    

        <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{handleProjectAdd(event)}} variant="secondary" className="text-dark me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>

       <Table hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Message ID
                </th>
                <th>
                    Start Date
                </th>
                <th>
                    End Date
                </th>
                <th>
                    Category
                </th>
                <th>
                    Action
                </th>
                
                </tr>
            </thead>
            <tbody>
            {activePost.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.msgID}
            </td>
            <td>
                {item.startDate}
            </td>
            <td>
                {item.endDate}
            </td>
            <td>
                {item.category}
            </td>
            
            <td>
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleProjectView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View
              </Dropdown.Item>
                <Dropdown.Item onClick={(event)=>{handleProjectEdit(event, item)}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleProjectDelete(event, item)}}>
                <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
            {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleProjectView(event, item)}}/>
            <FontAwesomeIcon icon={faEdit} className="me-2" onClick={(event)=>{handleProjectEdit(event, item)}}/>
            <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleProjectDelete(event, item)}}/>

            {/*<button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>
            </tr>)}
            </tbody>
            </Table>

    </div>
  )
}
