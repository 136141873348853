import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
//import { Auth } from "aws-amplify";

// pages
import InProgress from './InProgress';
import StudentsDetails from "./StudentsInfo/StudentsDetails";
import ManageFee from "./feemanage/ManageFee";
import DisplayPendingFees from "./feemanage/DisplayPendingFees";
import ManageRegistration from "./Registrations/ManageRegistration";
import Signup from "./auth/Signup";


//auth
import Signin from "./auth/Signin";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
//import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ExamInfo from '../pages/manageExams/ExamInfo';
import { ProductContext } from '../ProductProvider';

import ImageViewer from './ImageEditor/ImageViewer';
import SliderViewer from './ImageEditor/SliderViewer'
import GalleryViewer from './ImageEditor/GalleryViewer';
import ActiveInfo from './infoDesk/ActiveInfo';

//students
/*import Courses from './student/Courses';
import MyProfile from './student/MyProfile';
import MyPayments from './student/MyPayments';
import MyCourses from './student/MyCourses';*/

//order
import OrderStatus from './order/OrderStatus';
import NewRegistration from './Registrations/NewRegistration';

//staff
import StaffNewRegistration from './staff/StaffNewRegistration';
import StaffProfile from './staff/MyProfile';
import WelcomeDash from '../components/WelcomeDash';
//import ListFeeDetails from './feemanage/ListFeeDetails';
import InsightsOverview from './dashboard/InsightsOverview';
import AddCourseMaterial from './CoursesInfo/AddCourseMaterial';
import AssignMaterials from './CoursesInfo/AssignMaterials';
import ManageEvaluation from './evaluation/ManageEvaluation';
//import AssignExamForm from './exam/AssignExamForm';
import ShowAssignedExam from './exam/ShowAssignedExam';
import LessonJourney from './exam/LessonJourney';
import BatchCompletionStatus from './exam/BatchCompletionStatus';
import AccountMgmt from './StudentsInfo/AccountMgmt';
import CalendarView from './schedular/CalendarView';
import NotificationNew from './inbox/NotificationNew';
import QueriesOpen from './inbox/QueriesOpen';
import DisplayVideos from './videoCourses/DisplayVideos';
import AddVideos from './videoCourses/AddVideos';
import StudentsPic from './dashboard/StudentsPic';
//import {useHistory} from 'react-router-dom';

//const config = require('../config.json');

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    //console.log("loader");
    const timer = setTimeout(() => setLoaded(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { loggedUser } = useContext(ProductContext);

  useEffect(() => {
    //console.log("sidebar");

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          {loggedUser.isAuthenticated?
          <Navbar />:null}

          <Component {...props} />
         {/*} <Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

export default function HomePage() {
  //const history= useHistory();
  //const { loggedUser, validateLogin, fetchOpenItems, alertClose, setLoggedUser, setMyProfileImage} = useContext(ProductContext);

/*  useEffect(()=>{
    console.log("test");
        alertClose();
        async function loginValidation() {
          console.log("test");
      
           try{
              await Auth.currentAuthenticatedUser()
             .then(async(user)=>{
               console.log("user : ", user);
               var tadmin=false;
               if(user.attributes['profile']==="Admin"){
                tadmin=true;
               // fetchOpenItems();
               }
               await setLoggedUser({...loggedUser,
                 name:user.attributes['name'],
                 email:user.attributes['email'],
                 profile:user.attributes['profile'],
                 DOB:user.attributes['birthdate'],
                 website:user.attributes['website'],
                 nickname:user.attributes['nickname'],
                 gender:user.attributes['gender'],
                 family_name:user.attributes['family_name'],
                 phone_number:user.attributes['phone_number'],
                 //website:user.attributes['website'],
                 isAuthenticated:true,
                 isAdmin:tadmin
               });
               const tnickname=user.attributes['nickname'];
               const tImageName=user.attributes['nickname']+".jpeg";
               setMyProfileImage(`${config.s3.profileUrl}${tnickname}${"/"}${tImageName}`);
              // navigate("/");   
              history.push(Routes.DashboardOverview.path);
               
             })
             .catch((error) =>{
             //  console.log("error:", error);
               setLoggedUser({...loggedUser,
                 name:'',
                 email:'',
                // currentStatus:'',
                 DOB:'',
                 profile:'',
                 //mobile:'',
                 website:'',
                 nickname:'',
                 gender:'',
                 family_name:'',
                 phone_number:'',
                 isAuthenticated:false,
                 isAdmin:false
               }); 
               history.push(Routes.Signin.path);
       
             })     
           }catch(error){
            // console.log(error);
           }
         }
     /* const validationResult= await validateLogin();
      console.log("Validation Result : ", validationResult);
      if(validationResult==="Failed"){
        history.push(Routes.Signin.path);
      }else if(validationResult==="NOT Authorized"){
        history.push(Routes.Signin.path);
      }else{
        history.push(Routes.DashboardOverview.path);
      }*/

 // },[])

 
 /*  const validateLogin = async() =>{
    console.log("test");

     try{
        await Auth.currentAuthenticatedUser()
       .then(async(user)=>{
         console.log("user : ", user);
         var tadmin=false;
         if(user.attributes['profile']==="Admin"){
          tadmin=true;
         // fetchOpenItems();
         }
         await setLoggedUser({...loggedUser,
           name:user.attributes['name'],
           email:user.attributes['email'],
           profile:user.attributes['profile'],
           DOB:user.attributes['birthdate'],
           website:user.attributes['website'],
           nickname:user.attributes['nickname'],
           gender:user.attributes['gender'],
           family_name:user.attributes['family_name'],
           phone_number:user.attributes['phone_number'],
           //website:user.attributes['website'],
           isAuthenticated:true,
           isAdmin:tadmin
         });
         const tnickname=user.attributes['nickname'];
         const tImageName=user.attributes['nickname']+".jpeg";
         setMyProfileImage(`${config.s3.profileUrl}${tnickname}${"/"}${tImageName}`);
        // navigate("/");   
        history.push(Routes.DashboardOverview.path);
         
       })
       .catch((error) =>{
       //  console.log("error:", error);
         setLoggedUser({...loggedUser,
           name:'',
           email:'',
          // currentStatus:'',
           DOB:'',
           profile:'',
           //mobile:'',
           website:'',
           nickname:'',
           gender:'',
           family_name:'',
           phone_number:'',
           isAuthenticated:false,
           isAdmin:false
         }); 
         history.push(Routes.Signin.path);
 
       })     
     }catch(error){
      // console.log(error);
     }
   }*/

return(
  <Switch>
    <RouteWithSidebar exact path={Routes.Insights.path} component={InsightsOverview} />
    <RouteWithSidebar exact path={Routes.LandingPage.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.StudentsPic.path} component={StudentsPic} />

    {/* auth */}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithSidebar exact path={Routes.ResetPassword.path} component={ResetPassword} />
   {/* <RouteWithLoader exact path={Routes.Lock.path} component={Lock} /> */}
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.InProgress.path} component={InProgress} />
    <RouteWithSidebar exact path={Routes.StudentsDetails.path} component={StudentsDetails} />
    <RouteWithSidebar exact path={Routes.StudentMgmt.path} component={AccountMgmt} />
    <RouteWithSidebar exact path={Routes.ManageFee.path} component={ManageFee} />
    <RouteWithSidebar exact path={Routes.ListFees.path} component={DisplayPendingFees} />    
    <RouteWithSidebar exact path={Routes.ManageRegistration.path} component={ManageRegistration} />
    <RouteWithSidebar exact path={Routes.NewRegistration.path} component={NewRegistration} />

    {/*Calendar*/}
    <RouteWithSidebar exact path={Routes.CourseMaterialInfo.path} component={AddCourseMaterial} />
    <RouteWithSidebar exact path={Routes.AssignMaterials.path} component={AssignMaterials} />
    <RouteWithSidebar exact path={Routes.CourseSchedular.path} component={CalendarView} />
    <RouteWithSidebar exact path={Routes.WelcomeDash.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.CourseVideosInfo.path} component={DisplayVideos} />
    <RouteWithSidebar exact path={Routes.CourseVideosAdd.path} component={AddVideos} />


    {/*Inbox*/}
    <RouteWithSidebar exact path={Routes.InboxNewNotification.path} component={NotificationNew} />
    <RouteWithSidebar exact path={Routes.InboxOpenQuery.path} component={QueriesOpen} />


    {/*Exam*/}
    <RouteWithSidebar exact path={Routes.ExamInfo.path} component={ExamInfo} />
    <RouteWithSidebar exact path={Routes.AssignExam.path} component={ShowAssignedExam} />
    <RouteWithSidebar exact path={Routes.LessonJourney.path} component={BatchCompletionStatus} />

    {/* Evaluation */}
    <RouteWithSidebar exact path={Routes.evaluation.path} component={ManageEvaluation} />

    {/* Gallery and Slider */}
    <RouteWithSidebar exact path={Routes.ImageViewer.path} component={ImageViewer} />
    <RouteWithSidebar exact path={Routes.SliderViewer.path} component={SliderViewer} />
    <RouteWithSidebar exact path={Routes.GalleryViewer.path} component={GalleryViewer} />

    {/* Info Desk */}
    <RouteWithSidebar exact path={Routes.InfoDesk.path} component={ActiveInfo} />

    {/* Students 
    <RouteWithSidebar exact path={Routes.CoursesOffered.path} component={Courses} />
    <RouteWithSidebar exact path={Routes.MyProfile.path} component={MyProfile} />
    <RouteWithSidebar exact path={Routes.MyPayments.path} component={MyPayments} />
    <RouteWithSidebar exact path={Routes.MyCourses.path} component={MyCourses} />
    */}

    {/* Order */}
    <RouteWithSidebar exact path={Routes.OrderStatus.path} component={OrderStatus} />

    {/* Staff */}
    <RouteWithSidebar exact path={Routes.StaffRegistration.path} component={StaffNewRegistration} />
    <RouteWithSidebar exact path={Routes.StaffProfile.path} component={StaffProfile} />


    <Redirect to={Routes.NotFound.path} />
  </Switch>
)
  }