import React, { useEffect, useState , useContext} from 'react';
import { Form, Col, Row, Card, Button, Table} from '@themesberg/react-bootstrap';
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimerSample from '../../components/examTimer/TimerSample';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Preloader from "../../components/Preloader";
import {Alert } from "reactstrap";

const config = require('../../config.json');


export default function MCQModal(props) {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, handleProcessAssessment } = useContext(ProductContext);
    const {questionID, formType, displayQuestions, toggle}= props;
    const [response, setResponse]=useState([{}]);
    const [initials, setInitials] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [questionDetails, setQuestionDetails] = useState({questionID:"", questionTitle:"", choices:[{"option":"","choice":"", "value":false}]});
    const [QADetails, setQADetails] = useState({questionID:"", questionTitle:"", choices:[{"option":"","choice":"", "value":false}]});
    //const [optionSelected, setOptionSelected] = useState(false);
    const [newOption, setNewOption] = useState({"option":"","choice":"", value:false});
    const [loaded, setLoaded] = useState(false);
    const [questionView, setQuestionView]= useState([]);
    const [disabledValue, setDisabledValue]= useState(true);


    useEffect(()=>{
      alertClose();
      setResponse(questionID.assessmentQuestions);
      setQuestionView(displayQuestions);
      //console.log("Questions : ", displayQuestions);
      //console.log("props received : ", questionID);
      if(formType==="Response View"||formType==="View"){
        setDisabledValue(true);
      }else{
        setDisabledValue(false);
      }
      setInitials(true);
      setLoaded(true);
      return()=>{
        alertClose();
      }
  },[])

  const handleAdd=(event)=>{
    event.preventDefault();
    const newID=questionID.assessmentQuestions.length+1;
  //  console.log("New ID : ", newID);
    setQuestionDetails({...questionDetails, questionID:newID, questionTitle:"", choices:[]});
    const tnewOption=String.fromCharCode(97);
    setNewOption({...newOption,option:tnewOption});
    setFormMode("Add");
}

const handleSubmit=async(event)=>{
  event.preventDefault();
  try{
    setLoaded(false);
    const todayDate=generateDate();
    const tassessmentID=questionID.questionID+"#"+loggedUser.nickname;
      const params={
        registrationNo:loggedUser.nickname,
        assessmentID:tassessmentID,
        courseCode:questionID.questionID,
        courseName:questionID.courseName,
        assessmentStatus:'Pending Evaluation',
        totalMarksObtained:"0",
        totalMarks:"0",
        assessmentGrade:"NA",
        assessmentResult:"NA",
        assessmentCategory:"Online",
        assessmentResponse:response,
        createdBy:loggedUser.nickname,
        createdOn:todayDate,
        updatedBy:loggedUser.nickname,
        updatedOn:todayDate,
        assessmentRemarks:"",
        examDate:todayDate,
        examType:"MCQ",
        evaluatedBy:"",
        evaluatedOn:""  
    }
    //console.log("Params :", params);
    await handleProcessAssessment(params);
    toggle();
    setLoaded(true);
  }catch(error){

  }
}

    const handleResponse=(event,item, option)=>{
      // console.log("Event : ", event);
      //event.preventDefault();
        var tempResponse=[...response];
        const tindex= tempResponse.find(titem => titem.questionID === item.questionID)
        const index =tempResponse.indexOf(tindex);
        
        // questionResponse=tempResponse[index];
         tempResponse[index].choices[option].value=!tempResponse[index].choices[option].value;
         setResponse(tempResponse);
        //console.log("response :", tempResponse);
      //  setOptionSelected(!optionSelected);
       // setResponse()
    }

    const  handleCheckProcess=(event, item)=>{
      //console.log("Item : ", item);
      setQuestionDetails(item);
      setQADetails(item);
      const tnewOption=String.fromCharCode(item.choices.length+97);
      setNewOption({...newOption,option:tnewOption});
      setFormMode("Update");
  }

  const handleValidation=()=>{
    var returnValue="Failed";
    if(questionDetails.questionID===""){
      returnValue="Failed";
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Question ID is empty. Select the Existing Question or Click Add to add a new Question"
    }); 
    window.scrollTo(0,0);
    }else if(questionDetails.questionTitle===""){
      returnValue="Failed";
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Question Value cannot be Empty"
    }); 
    window.scrollTo(0,0);
    }else if(questionDetails.choice===""){
      returnValue="Failed";
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Answer value cannot be Empty"
    }); 
    window.scrollTo(0,0);
    }else{
      returnValue="Passed";
    }
    return returnValue;
  }

  const handleUpdate=async(event)=>{
    event.preventDefault();
    try{
      setLoaded(false);
      const validationResult= await handleValidation();
      if(validationResult==="Passed"){
        var updatedQuestions="";
        var updatedQA="";
        if(formMode==="Add"){
            updatedQuestions= processAddQuestionDetails();
            updatedQA=processAddQADetails();
        }else{
            updatedQuestions= processQuestionDetails();
            updatedQA=processQADetails();
        }
        const todayDate=generateDate();
        const params={
            courseCode:questionID.courseCode,
            questionID:questionID.questionID,
            assessmentQuestions:updatedQuestions,
            assessmentAnswers:updatedQA,
            updatedBy:loggedUser.nickname,
            updatedOn:todayDate,
            category:"UpdateQuestion"
        }
       // console.log("Params : ", params);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        await axios.patch(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
             // console.log("Response : ", response);
            //fetchApplicationDetails();
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: "Successfully Updated"
            }); 
            window.scrollTo(0,0);
            //courseToggle();
            //handleSearch();
              setLoaded(true);            
            })
            .catch((error)=>{
             // console.log("Error :", error);
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true);
            })
          }
          setLoaded(true);
    }catch(error){
     // console.log("Error :", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Error Occured : " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
}

const processAddQuestionDetails=()=>{
  const tempResponse={};
  const tempAssessment=questionID.assessmentQuestions;
  tempResponse.questionID=questionDetails.questionID;
  tempResponse.questionTitle=questionDetails.questionTitle;
  tempResponse.choices=questionDetails.choices;
  tempAssessment.push(tempResponse);
   return tempAssessment; 
}

const processAddQADetails=()=>{
const tempResponse={};
const tempAssessment=questionID.assessmentAnswers;
tempResponse.questionID=questionDetails.questionID;
tempResponse.questionTitle=questionDetails.questionTitle;
tempResponse.choices=QADetails.choices;
tempAssessment.push(tempResponse);
 return tempAssessment;
}

const handleChoiceEdit =(event, item)=>{
  //console.log("ITem : ", item);
  //setQuestionDetails(item);
  //const tnewOption=String.fromCharCode(item.choices.length+97);
  setNewOption({...newOption,
    option:item.option,
    choice:item.choice,
    value:item.value});
  setFormMode("ChoiceEdit");
}


const processQuestionDetails=()=>{
var tempResponse=[...questionID.assessmentQuestions];
 const tindex= tempResponse.find(titem => titem.questionID === questionDetails.questionID)
 const index =tempResponse.indexOf(tindex);
 tempResponse[index].questionTitle=questionDetails.questionTitle;
 tempResponse[index].choices=questionDetails.choices;
  return tempResponse;
}

const processQADetails=()=>{
 var tempResponse=[...questionID.assessmentAnswers];
  const tindex= tempResponse.find(titem => titem.questionID === questionDetails.questionID)
  const index =tempResponse.indexOf(tindex);
  tempResponse[index].questionTitle=questionDetails.questionTitle;
  tempResponse[index].choices=QADetails.choices;
  return tempResponse;
}


const processNewOption=async(event)=>{
  event.preventDefault();
  var tempResponse=[...QADetails.choices];
  tempResponse.push(newOption);
  await setQADetails({...QADetails, choices:tempResponse});
  var tempResponse1=[...questionDetails.choices];
  const tempOption={"option":"","choice":"", value:false};
  tempOption.option=newOption.option;
  tempOption.choice=newOption.choice;
  tempResponse1.push(tempOption);
  setQuestionDetails({...questionDetails, choices:tempResponse1});
  const tnewOption=String.fromCharCode(tempResponse1.length+97);
  setNewOption({...newOption,option:tnewOption,choice:"",value:false});
}

const processEditOption=async(event)=>{
  event.preventDefault();
  var tempResponse=[...QADetails.choices];
  const UpdatedResponse = tempResponse.map(obj => {
    if (obj.option === newOption.option) {
      return {...obj, choice: newOption.choice,value:newOption.value};
    }
    return obj;
  });
   await setQADetails({...QADetails, choices:UpdatedResponse});
  var tempResponse1=[...questionDetails.choices];
  const UpdatedResponse1 = tempResponse1.map(obj => {
    if (obj.option === newOption.option) {
      return {...obj, choice: newOption.choice,value:false};
    }
    return obj;
  });
  setQuestionDetails({...questionDetails, choices:UpdatedResponse1});
  const tnewOption=String.fromCharCode(tempResponse.length+97);
  setNewOption({...newOption,option:tnewOption,choice:"",value:false});
}


return (
  <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>

    <div className='mb-5 '><h4>Multiple Choice Questions</h4>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
   {formType==="Edit"? <Button active variant="outline-primary" size="sm" onClick={(event)=>{handleAdd(event)}}>Add</Button>
   :null}
    <div className="d-flex">
    {/*<ExamTimer />*/}
   {formType==="Exam"? <TimerSample />:null}
    </div>
    </div>
    {questionID && questionView.map((item, index)=>
    <div className='container' key={index}>
      <Row className='card p-3'>
        <Col className='pt-2' scope="row" >
            {formType==="Edit"?  <span>
            <Form.Check inline type="radio" name="test" value={item.questionID} onClick={(event)=>handleCheckProcess(event, item)}/>
            Q.{index+1} {item.questionTitle}
            </span>:<span>Q.{index+1} {item.questionTitle} </span>}
        </Col>
        {formType==="Response View"?
        <Col className='pt-2'>
        {item && initials && item.choices.map((titem,tindex)=>
          <Form.Check key={tindex} inline label={`${titem.option}.${titem.choice}`} 
            type="checkbox" name={"Q"+item.questionID} 
            //checked={response?response[index].choices[tindex].value:response[index].choices[tindex].value} 
            checked={titem.value} 
            disabled={disabledValue}
            onChange={(event)=>handleResponse(event,item, tindex)} />)}
        </Col>:formType==="Edit"?
        <Col className='pt-2'>
        {item && initials && item.choices.map((titem,tindex)=>
          <Form.Check key={tindex} inline label={`${titem.option}.${titem.choice}`} 
            type="checkbox" name={"Q"+item.questionID} 
            //checked={response?response[index].choices[tindex].value:response[index].choices[tindex].value} 
            checked={titem.value} 
            disabled={disabledValue}
            onChange={(event)=>handleResponse(event,item, tindex)} />)}
        </Col>:
        <Col className='pt-2'>
        {response && item && initials && item.choices.map((titem,tindex)=>
          <Form.Check key={tindex} inline label={`${titem.option}.${titem.choice}`} 
            type="checkbox" name={"Q"+item.questionID} disabled={disabledValue}
            checked={response?response[index].choices[tindex].value:response[index].choices[tindex].value} 
            onChange={(event)=>handleResponse(event,item, tindex)} />)}
        </Col>}
      </Row>
    </div>
    )}

    {formType==="Edit"?<div>
      <Row>
        <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
        <h5 className="mb-4">{formMode} Question Details</h5>
        <Form>
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="coursetitle">
                <Form.Label>Question No</Form.Label>
                <Form.Control required type="text" value={questionDetails.questionID}
                disabled/>
              </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Question Title</Form.Label>
                <Form.Control type="text" value={questionDetails.questionTitle}
                onChange={(event)=>setQuestionDetails({...questionDetails, questionTitle:event.target.value})} />
              </Form.Group>
            </Col>
        </Row>
        <Table responsive hover className="user-table align-items-center">
          <thead>
            <tr>
              <th>Option</th>
              <th>Choice</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
          {initials && QADetails && QADetails.choices.map((item, index)=>
            <tr key={index}>
              <td><Form.Check inline type="radio" name="choices" value={item.option} onClick={(event)=>handleChoiceEdit(event, item)}/> {item.option}</td>
              <td>{item.choice}</td>
              <td>{item.value?<FontAwesomeIcon icon={faCheck} className="me-2"/>:null}</td>
            </tr>)}
          </tbody>
        </Table>
        <Row className="align-items-center">
          <Col md={2} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Option</Form.Label>
                <Form.Control type="text" value={newOption.option} disabled />
              </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Choice</Form.Label>
                <Form.Control type="text" value={newOption.choice}
                onChange={(event)=>setNewOption({...newOption,choice:event.target.value})} />
              </Form.Group>
          </Col>
          <Col md={2} className="mb-3">
          <Form.Group id="phaseno">
          <Form.Label>Answer</Form.Label>
          <Form.Check inline 
            type="checkbox" name={"value"} 
            checked={newOption.value} 
            onChange={(event)=>setNewOption({...newOption,value:!newOption.value})} />
            </Form.Group>
          </Col>
          {formMode==="ChoiceEdit"?<Col md={2} className="mb-1">
          <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{processEditOption(event)}}/>
          </Col>:<Col md={2} className="mb-1">
          <FontAwesomeIcon icon={faPlus} className="me-2" onClick={(event)=>{processNewOption(event)}}/>
          </Col>}
        </Row>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleUpdate(event)}}>{formMode==="Add"?"Add":"Update"}</Button>
        </div>
      </Form>
      </Card.Body>
      </Card>
      </Col>
      </Row>
      </div>:null} 


    {formType==="Exam"?<div className='text-center'>
      <button className='btn-success rounded text-center' onClick={(event)=>{handleSubmit(event)}}>Submit</button> 
    </div>:null}
  </div>
  </>
  )
}
