import React from 'react';
import { Card } from 'react-bootstrap';

//const config = require('../../config.json');

export default function FileAttachments(props) {
    const {idProof, certProof}=props;

  /*  useEffect(()=>{
        const tIDProofName=applicationNo+"_idproof.jpeg";
        setIDProof(`${config.s3.profileUrl}${applicationNo}${"/docs/"}${tIDProofName}`);
        const tcertProofName=applicationNo+"_certproof.jpeg";
        setCertProof(`${config.s3.profileUrl}${applicationNo}${"/docs/"}${tcertProofName}`);
        console.log("IDProof : ",`${config.s3.profileUrl}${applicationNo}${"/docs/"}${tIDProofName}`);
    },[])*/
    
  return (
      <>
    <div>FileAttachments</div>
    <Card>
        <p>Photo ID Proof:</p>
    {idProof?<img src={idProof} alt="IDProof"/>:<p>No Files Attached</p>}
    </Card>
    <Card>
        <p>UG/PG Certificate:</p>
    {certProof? <img src={certProof} alt="CERT Proof"/>:<p>No Files Attached</p>}
    </Card>
    </>
  )
}
