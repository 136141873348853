import React, { useState, useContext } from 'react';
//import axios from "axios";
import {Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
//const config = require('../../config.json');


export default function AddImage(props) {
    const { handleImageError, alertDetails, setAlertDetails, alertClose } = useContext(ProductContext)
   // const inputRef = React.useRef();
   // const triggerFileSelectPopup = () => inputRef.current.click();
   // const [image, setImage] = useState('');
    const { setImageFile, profileImage, formType}=props;
    const [loaded, setLoaded] = useState(true);
    const [images, setImages]=useState([]);

    //const [loader, showLoader, hideLoader] = useFullPageLoader(); 

   const handleFileSelection =async (event)=>{
     event.preventDefault();
     setLoaded(false);
   // console.log("Files Selected : ", event.target.files);
     if (event.target.files && event.target.files.length > 0 && event.target.files.length < 6) {
     // await setImageFile(event.target.files);
     //  console.log("Image File : ", imageFile);
     //  console.log("Filename : ", event.target.files[0].name);
      const selectedFIles =[];
      const targetFiles =event.target.files;
      const targetFilesObject= [...targetFiles]
      targetFilesObject.map((file)=>{
         return selectedFIles.push(URL.createObjectURL(file))
      })
      setImageFile(event.target.files);
      setImages(selectedFIles);
       //console.log("selected file : ", event.target.files[0].name);
      // var tprofile = document.getElementById("profile");
      // tprofile.src=URL.createObjectURL(event.target.files[0]);
   }else if(event.target.files.length > 6){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Maximum only 5 files can be attached!"
      }); 
   }
   setLoaded(true);
  }

  
    return (
      <div   style={{textAlign:"center"}}> 
       <Preloader show={loaded ? false : true} />
       <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
       </Alert>

       <div style={{ textAlign:'center', justifyContent:'center', marginBottom:'10px'}}>
         {images.length>0?
                    <div className="row">
                    {
                    images.map((url, index)=>{
                        return (
                            <div className="col-sm-3" key={index}>
                            <div className="card">
                            <img src={url} alt="new images" style={{width:"6rem", height:"6rem", marginBottom:'15px', borderRadius:'8px', border:'2px solid lightgrey'}}/>
                            </div>
                            </div>
                        )
                    })
                    }
                    </div>:
          <img  id="profile" src={profileImage} 
            alt="Profile" style={{width:"12rem", height:"12rem", marginBottom:'15px', borderRadius:'8px', border:'2px solid lightgrey'}} />}
            <input
          type="file"
          accept="image/*"
          multiple
        //  ref={inputRef}
          onChange={handleFileSelection}
          onError={handleImageError}
          style={{textAlign:'center', justifyContent:'center', borderTopRightRadius:'8px', borderBottomRightRadius:'8px', backgroundColor:'white', border:'2px solid lightblue' }}
          disabled={formType==="View"?true:false}
        />
        
       </div>
        </div>
      
    )

}