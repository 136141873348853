import React , {useState, useContext, useEffect } from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {ProductContext} from "../../ProductProvider";
import {Breadcrumb, BreadcrumbItem, Alert } from "reactstrap";
import RequestList from './RequestList';
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";
import useFileDownloader from "./useFileDownloader";

const config = require('../../config.json');


export default function QueriesOpen() {

    const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, generateDate, fetchOpenQueries, openQueries } = useContext(ProductContext);
    //const [searchResults, setSearchResults]=useState([]);
    const [loaded, setLoaded] = useState(true);
    const [downloadFile, downloaderComponentUI] = useFileDownloader();
    const [comments, setComments]= useState('');

      useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          fetchOpenQueries();
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
       // console.log(batchYear);
      },[loggedUser])

     /* const fetchMyRequest =async()=>{
        try {
            setLoaded(false);
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const searchOption="AdminOpenQuery";
                const searchValue="Query";
                const res = await axios.get(`${config.api.invokeURL}/inbox?searchValue=${searchValue}&searchOption=${searchOption}`,{
                    headers:{
                        Authorization: access_token,
                        'x-api-key': config.api.key
                    }
                });
                //console.log("res DAta : ", res);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: res.data.length + " - Queries found"
                }); 
                window.scrollTo(0,0); 
               /* var i=0;
                while(i<res.data.length){
                //  console.log("before value : ", res.data[i].Book_Classification_No);
                 const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===res.data[i].Book_Classification_No);
                 res.data[i].Book_Classification_No=tempClassValue[0].name;
                 //console.log("temp class vale : ", tempClassValue);
                 //console.log("After update : ", res.data[i].Book_Classification_No);
    
                 i++;
                }*/
                
               /* setSearchResults(res.data);
                //setSearchResults(msgTemplates);
                setLoaded(true);
                //hideLoader();
            
        } catch (error) {
            setLoaded(true);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            window.scrollTo(0,0); 
        }
      }*/

      const handleUpdateRequest=async(event, req, action)=>{
          event.preventDefault();
        // console.log("Item : ", req);
          try {
              setLoaded(false);

                const tCloseDate= await generateDate();
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const { msgID }=req;
                var resolutionNotesValue="";
                if(comments===""){
                  resolutionNotesValue=action;
                }else{
                  resolutionNotesValue=comments;
                }
                const params = {
                    "msgID": msgID,
                    "msgStatus": action,
                    "msgAcknowledgeBy": loggedUser.name,    
                    "msgValidTill": tCloseDate,
                    "msgResolutionNotes":resolutionNotesValue
                };
          //     console.log(" Params : ", params);
          const msgid=msgID;
          // await axios.post(`${config.api.invokeUrl}/user_request/${reqid}`, params,{
            await axios.patch(`${config.api.invokeURL}/inbox/${msgid}`, params,{
              headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async()=>{
              //console.log("response : ", response); 
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message:msgID + " - "+action+" Successfully"
              }); 
              // handleCancelClick(event);
              //resetRequestValues();
              fetchOpenQueries();
              setLoaded(true);
            })
            .catch((error)=>{
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              setLoaded(true);
            })
          
        
        }catch (err) {
              setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + err.message
        }); 
        setLoaded(true);
        }
        
      }

      /*const assignInitialValues=()=>{
        const current_datetime = new Date();
        const tusername=loggedUser.name.trim().replace(/\s/g, "");
        const tempUserId= tusername.substring(0,4);
        var tempCreateDate="";
        var tempCreateTime="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        //const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
        const accCreateDate = tempCreateDate.concat(date_year,"-",month_value,"-", date_value);
        //const accCreateTime = tempCreateTime.concat(hours_value,":", minutes_value);
    
        //setUserId(accountNo);
        return accCreateDate;
      
      
      }*/

      const handleAttachmentDownload=async(event, searchresult)=>{
        event.preventDefault();
        console.log("Event");
          try{
            const fetchFile=searchresult.attachmentName;
            const bucketName=config.s3.attachmentBucketName;
            //const bucketName=`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}`;
            /*const params={
                fileName:fetchFile,
                bucketName:`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}/${propsFile.domainId}`
            };*/
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
              headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
           ).then(async(response)=>{
                console.log("Response : ", response);
                const file ={
                    name:searchresult.attachmentName,
                    file:response.data.downloadURL,
                    filename:fetchFile
                };
                //console.log("url file : ", file);
                downloadFile(file);
            }).catch((error)=>{
                //console.log("Error : ", error);
                //setDispMsg("Error Occured - ", error.message);
            })        
          }catch(error){
              //console.log("Error : ", error);
          }
      
  
      }
      
  return (
    <div className="m-2">
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.DashboardOverview.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Messages
    </BreadcrumbItem>
    <BreadcrumbItem >
      Open
    </BreadcrumbItem>
    </Breadcrumb>
    <h5 className="mb-4">Open Queries</h5>
    <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}</Alert>
    <RequestList comments={comments} setComments={setComments} searchResults={openQueries} handleUpdateRequest={handleUpdateRequest} handleAttachmentDownload={handleAttachmentDownload}/>
    {downloaderComponentUI}
    </div>
  )
}
