import React, { useEffect, useState, useContext } from 'react';
import { Form, Col, Row, Card, Button} from '@themesberg/react-bootstrap';
import TimerSample from '../../components/examTimer/TimerSample';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Preloader from "../../components/Preloader";
import {Alert } from "reactstrap";

const config = require('../../config.json');


export default function DescriptiveModal(props) {
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, handleProcessAssessment } = useContext(ProductContext);
    const {questionID, formType, displayQuestions, toggle}= props;
    const [response, setResponse]=useState([]);
    const [initials, setInitials] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [questionDetails, setQuestionDetails] = useState({questionID:"", questionTitle:"", keys:""});
    const [loaded, setLoaded] = useState(false);
    const [questionView, setQuestionView]= useState([]);
    const [disabledValue, setDisabledValue]= useState(true);

    useEffect(()=>{
        //console.log("Sample Questions : ", questionID);
        setResponse(questionID.assessmentQuestions);
        setQuestionView(displayQuestions);
        //console.log("Questions : ", displayQuestions);
        if(formType==="Response View"||formType==="View"){
          setDisabledValue(true);
        }else{
          setDisabledValue(false);
        }
        setInitials(true);
        setLoaded(true);
        return()=>{
          alertClose();
        }
    },[])

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
          setLoaded(false);
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          //const clientID="VJDEPTH";
         // const registrationno=loggedUser.nickname;
         // await axios.get(`${config.api.invokeURL}/dashboard/scores/${registrationno}`,{
           //   headers: {
             //   Authorization: access_token,
              //  'x-api-key': config.api.key
              //}}, 
          //).then(async(res)=>{
              //console.log("Res : ", res);
              const todayDate=generateDate();
              const tassessmentID=questionID.questionID+"#"+loggedUser.nickname;
                const params={
                  registrationNo:loggedUser.nickname,
                  assessmentID:tassessmentID,
                  courseCode:questionID.questionID,
                  courseName:questionID.courseName,
                  assessmentStatus:'Pending Evaluation',
                  totalMarksObtained:"0",
                  totalMarks:"0",
                  assessmentGrade:"NA",
                  assessmentResult:"NA",
                  assessmentCategory:"Online",
                  assessmentResponse:response,
                  createdBy:loggedUser.nickname,
                  createdOn:todayDate,
                  updatedBy:loggedUser.nickname,
                  updatedOn:todayDate,
                  assessmentRemarks:"",
                  examDate:todayDate,
                  examType:"Descriptive",
                  evaluatedBy:"",
                  evaluatedOn:""
              }
              //console.log("Params :", params);
              await handleProcessAssessment(params);
              toggle();
              //setMyAssessmentSheet(response.data);
              setLoaded(true);
       /*   }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
          })    
        */

        }catch(error){

        }
    }

    const handleRespnse=(event, item)=>{
        var tempResponse=[...response];
        const tindex= tempResponse.find(titem => titem.questionID === item.questionID)
        const index =tempResponse.indexOf(tindex);
         tempResponse[index].response=event.target.value;
         setResponse(tempResponse);
       
    }

    const  handleCheckProcess=(event, item)=>{
        event.preventDefault();
       // setExamDetails(item);
      //  console.log("Item : ", item);
        setQuestionDetails(item);
        setFormMode("Update");
    }

    const handleValidation=()=>{
      var returnValue="Failed";
      if(questionDetails.questionID===""){
        returnValue="Failed";
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Question ID is empty. Select the Existing Question or Click Add to add a new Question"
      }); 
      window.scrollTo(0,0);
      }else if(questionDetails.questionTitle===""){
        returnValue="Failed";
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Question Value cannot be Empty"
      }); 
      window.scrollTo(0,0);
      }else if(questionDetails.keys===""){
        returnValue="Failed";
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Answer value cannot be Empty"
      }); 
      window.scrollTo(0,0);
      }else{
        returnValue="Passed";
      }
      return returnValue;
    }

    const handleUpdate=async(event)=>{
        event.preventDefault();
        try{
          setLoaded(false);
          const validationResult= await handleValidation();
          if(validationResult==="Passed"){
            var updatedQuestions="";
            var updatedQA="";
            if(formMode==="Add"){
                updatedQuestions= processAddQuestionDetails();
                updatedQA=processAddQADetails();
            }else{
                updatedQuestions= processQuestionDetails();
                updatedQA=processQADetails();
            }
            const todayDate=generateDate();
            const params={
                courseCode:questionID.courseCode,
                questionID:questionID.questionID,
                assessmentQuestions:updatedQuestions,
                assessmentAnswers:updatedQA,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate,
                category:"UpdateQuestion"
            }
           // console.log("Params : ", params);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            await axios.patch(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  setQuestionDetails({questionID:"", questionTitle:"", keys:""});
                  setAlertDetails({
                      ...alertDetails,
                      style:"success",
                      open:true,
                      message: "Successfully Updated"
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);            
                })
                .catch((error)=>{
                //  console.log("Error :", error);
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })
              }
              setLoaded(true);
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
        }
    }

    const processAddQuestionDetails=()=>{
          const tempResponse={};
          const tempAssessment=questionID.assessmentQuestions;
          tempResponse.questionID=questionDetails.questionID;
          tempResponse.questionTitle=questionDetails.questionTitle;
        //  tempResponse.answer=questionDetails.answer;
          tempAssessment.push(tempResponse);
         // console.log("Exam Details : ", tempAssessment);
          // questionResponse=tempResponse[index];
         //  setAssessmentDetails(tempResponse);
           return tempAssessment; 
     }

     const processAddQADetails=()=>{
        const tempResponse={};
        const tempAssessment=questionID.assessmentAnswers;
        tempResponse.questionID=questionDetails.questionID;
        tempResponse.questionTitle=questionDetails.questionTitle;
        tempResponse.keys=questionDetails.keys;
        tempAssessment.push(tempResponse);
     //   console.log("Question Details : ", tempAssessment);
        // questionResponse=tempResponse[index];
       //  setAssessmentDetails(tempResponse);
         return tempAssessment;
   }
 

    const processQuestionDetails=()=>{
       // console.log("Exam Details : ", examDetails);
       // const tempResponse="";
        var tempResponse=[...questionID.assessmentQuestions];
       //  console.log("Temp Response : ", tempResponse);
         const tindex= tempResponse.find(titem => titem.questionID === questionDetails.questionID)
         const index =tempResponse.indexOf(tindex);
      //   console.log("Temp Response : ", tempResponse[index]);
         tempResponse[index].questionTitle=questionDetails.questionTitle;
         // questionResponse=tempResponse[index];
        //  setAssessmentDetails(tempResponse);
          return tempResponse;
    }

    const processQADetails=()=>{
         var tempResponse=[...questionID.assessmentAnswers];
          const tindex= tempResponse.find(titem => titem.questionID === questionDetails.questionID)
          const index =tempResponse.indexOf(tindex);
          tempResponse[index].questionTitle=questionDetails.questionTitle;
          tempResponse[index].keys=questionDetails.keys;
          return tempResponse;
    }
 

    const handleAdd=(event)=>{
        event.preventDefault();
        const newID=questionID.assessmentQuestions.length+1;
      //  console.log("New ID : ", newID);
        setQuestionDetails({...questionDetails, questionID:newID, questionTitle:"",keys:""});
        setFormMode("Add");
    }
   

  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <h4>Descriptive Modal Exam</h4>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {formType==="Edit"? <Button active variant="outline-primary" size="sm" onClick={(event)=>{handleAdd(event)}}>Add</Button>
        :null}
        <div className="d-flex">
        {/*<ExamTimer />*/}
        {formType==="Exam"? <TimerSample />:null}
        </div>
        </div>

    {initials && questionView.map((item, index)=>
    <Row key={index} className='card p-3'>
            <Col className='pt-2' scope="row" >
            {formType==="Edit"?  <span>
            <Form.Check inline type="radio" name="test" value={item.questionID} onClick={(event)=>handleCheckProcess(event, item)}/>
            Q.{index+1} {item.questionTitle} 
            </span>:<span>Q.{index+1} {item.questionTitle} </span>}
             <span className='p-2'>   </span>
            </Col>
            {formType==="Response View"?
            <Col className='pt-2'>
            <Form.Control as="textarea" rows={3} name={"Q"+item.questionID} disabled={disabledValue} value={item.response}/>
            </Col>:formType==="Edit"?
             <Col className='pt-2'>
              <Form.Control as="textarea" rows={3} name={"Q"+item.questionID} disabled={disabledValue} value={item.keys}/>
             </Col>:<Col className='pt-2'>
            <Form.Control as="textarea" rows={3} name={"Q"+item.questionID} disabled={disabledValue} onChange={event=>handleRespnse(event,item)}/>
            </Col>}
            </Row>
    )}
    {formType==="Edit"?<div>
        <Row>
        <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">{formMode} Question Details</h5>
        <Form>
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="coursetitle">
                <Form.Label>Question No</Form.Label>
                <Form.Control required type="text" value={questionDetails.questionID}
                disabled/>
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Question Title</Form.Label>
                <Form.Control type="text" value={questionDetails.questionTitle}
                onChange={(event)=>setQuestionDetails({...questionDetails, questionTitle:event.target.value})} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
          <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Answer</Form.Label>
                <Form.Control as="textarea" rows={3} name={"Q"} 
                value={questionDetails.keys}
                onChange={(event)=>setQuestionDetails({...questionDetails, keys:event.target.value})}/>
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleUpdate(event)}}>{formMode==="Add"?"Add":"Update"}</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
        </Col>

            </Row>
            </div>:null} 

    {formType==="Exam"?<div className='text-center'>
        <button className='btn-success rounded text-center' onClick={(event)=>{handleSubmit(event)}}>Submit</button> 
    </div>:null}
    </>
  )
}
