import React,{useState, useContext, useEffect} from 'react';
import { Col, Row, Form, InputGroup } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
//import Preloader from "../../components/Preloader";
import { Alert  } from "reactstrap";
//import axios from "axios";
//import { Auth } from 'aws-amplify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function CalendarForm(props) {
    const {calendarDetails, setCalendarDetails, isDisabled} = props;
    const {  alertDetails, alertClose} = useContext(ProductContext);
    const [batchYear, setBatchYear] = useState([]);

    useEffect(() => {
        processBatchYear();
        alertClose();
    }, [])

    const processBatchYear=()=>{
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var tbatchYear=[];
        for(var i=0; i<10;i++){
          tbatchYear.push(date_year-i);
        }
        tbatchYear.push("All");
        setBatchYear(tbatchYear);
        if(!isDisabled){
          setCalendarDetails({...calendarDetails, 
            audience:date_year,
            eventType:"Online Class",
            audience:"NA",
            presenter:"",
            location:"",
            comments:"",
            header:""
        });
        }
        //fetchCurrentYearDetails(date_year);
      }

  return (
    <>
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    {/*<Row>
    <Col md={6} className="mb-3">
    <Form.Label>Select Batch:  </Form.Label>

    <InputGroup>    
          <Form.Select value={calendarDetails.audience} 
              onChange={(event)=>{setCalendarDetails({...calendarDetails, audience:event.target.value})}}>
             {batchYear.map(item => {
                return (
                <option key={item} value={item}>{item}</option>
                );
              })}
           </Form.Select>
        </InputGroup>
    </Col>
    <Col md={6} className="mb-3">
    <InputGroup>
      <Form.Check key="1" inline label="All Day Event" 
            type="checkbox" name="All Day Event" 
            //checked={response?response[index].choices[tindex].value:response[index].choices[tindex].value} 
            checked={calendarDetails.allDay} 
            //disabled={disabledValue}
            onChange={(event)=>setCalendarDetails({...calendarDetails,allDay:!calendarDetails.allDay})} />
        </InputGroup>
            </Col>
            </Row>*/}

    <Row>
    <Col md={4} className="mb-3">
    <Form.Label>Start Date & Time:  </Form.Label>
      </Col>
      <Col md={8} className="mb-3">
    <DatePicker
      selected={calendarDetails.startFrom}
      onChange={(date) => setCalendarDetails({...calendarDetails, startFrom:date})}
      showTimeSelect
      dateFormat="MMM d, yyyy h:mm aa"
      disabled={isDisabled}
    />
    </Col>
    </Row>
    <Row>
    <Col md={4} className="mb-3">
      <Form.Label>End Date & Time:  </Form.Label>
      </Col>
    <Col md={8} className="mb-3">
    <DatePicker
      selected={calendarDetails.endTo}
      onChange={(date) => setCalendarDetails({...calendarDetails, endTo:date})}
      showTimeSelect
      dateFormat="MMM d, yyyy h:mm aa"
      disabled={isDisabled}

    />
    </Col>
    </Row>
    <Row>
    <Col md={4} className="mb-3">
    <Form.Label>Title:  </Form.Label>
    </Col>
    <Col md={8} className="mb-3">
    <Form.Control type="text" value={calendarDetails.header}  disabled={isDisabled}
          onChange={(event)=>{setCalendarDetails({...calendarDetails, header:event.target.value})}}/>
    </Col>
    </Row>
    <Row>
    <Col md={4} className="mb-3">
    <Form.Label>Event Type:  </Form.Label>
    </Col>    
    <Col md={8} className="mb-3">
     <InputGroup>
          <Form.Select value={calendarDetails.eventType}  disabled={isDisabled}
           onChange={(event)=>{setCalendarDetails({...calendarDetails, eventType:event.target.value})}}>
                <option value="Online Class">Online Class</option>
                <option value="Offline Class">Offline Class</option>
                  <option value="Exams">Exams</option>
                  <option value="Seminars">Seminars</option>
                </Form.Select>
        </InputGroup>
    </Col>
    </Row>
    <Row>
    <Col md={4} className="mb-3">
      <Form.Label>Description:  </Form.Label>
    </Col>
    <Col md={8} className="mb-3">
      <InputGroup>
          <Form.Control type="text" value={calendarDetails.comments} disabled={isDisabled}
          onChange={(event)=>{setCalendarDetails({...calendarDetails, comments:event.target.value})}}/>
        </InputGroup>
      </Col>
    </Row>
    <Row>
    <Col md={4} className="mb-3">
      <Form.Label>Presenters:  </Form.Label>
    </Col>
    <Col md={8} className="mb-3">
      <InputGroup>
          <Form.Control type="text" value={calendarDetails.presenter} disabled={isDisabled}
          onChange={(event)=>{setCalendarDetails({...calendarDetails, presenter:event.target.value})}}/>
        </InputGroup>
      </Col>
      </Row>
      <Row>
      <Col md={4} className="mb-3">
      <Form.Label>Location:  </Form.Label>
      </Col>    
      <Col md={8} className="mb-3">
      <InputGroup>
          <Form.Control type="text" value={calendarDetails.location}  disabled={isDisabled}
          onChange={(event)=>{setCalendarDetails({...calendarDetails, location:event.target.value})}}/>
        </InputGroup>
      </Col>
    </Row>

    </>
  )
}
