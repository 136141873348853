import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import { faEye, faClock, faCheck, faCalendar, faCancel, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import {  Table, CardBody, CardTitle, CardSubtitle, CardText, Badge} from 'reactstrap';
import { Button } from '@themesberg/react-bootstrap';
import ViewRequestForm from './ViewRequestForm';
import {ProductContext} from '../../ProductProvider';
//import 'balloon-css';

export default function RequestList(props) {
  const { loggedUser } = useContext(ProductContext);
  const {searchResults, handleUpdateRequest, handleAttachmentDownload, comments, setComments} = props;
  const [collapsed, setCollapsed] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState('');

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
     }

  const handleViewRequest =(event, searchresult)=>{
    event.preventDefault();
    setSelectedRequest(searchresult);
    toggle();
  }

  return (
    <div className="m-2">
    <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
      <ModalHeader toggle={toggle}>
      </ModalHeader>
      <ModalBody>
          <ViewRequestForm comments={comments} setComments={setComments} selectedRequest={selectedRequest} handleUpdateRequest={handleUpdateRequest} handleAttachmentDownload={handleAttachmentDownload}/>
      </ModalBody>
      <ModalFooter>
      <Button onClick={event=>handleUpdateRequest(event,selectedRequest,"Resolved")}>
        Resolve
      </Button>
      {' '}

      <Button onClick={event=>handleUpdateRequest(event,selectedRequest,"Cancelled")}>
        Cancel
      </Button>

      {' '}
      <Button onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
    </Modal>

      {searchResults.length>0?<Table hover responsive>
        <tbody >
        {searchResults.map((searchresult,index) =>(
        <tr className='card align-items-center' style={{flexDirection:'row', width:'100%', height:'150px', justifyContent:'space-between', border:'2px solid white'}} key={index}>
          <td className='card align-items-center' style={{flexDirection:'row', width:'100%', height:'150px', justifyContent:'space-between', border:'2px solid white'}}>
      <CardBody>
      <CardTitle tag="h5">
     {searchresult.msgSubject}
      </CardTitle>
      <CardSubtitle className="mb-2 text-muted" tag="h6">
      <FontAwesomeIcon icon={faCalendar} className="me-2" /> {searchresult.msgCreationDate}  
      <FontAwesomeIcon icon={faClock} className="me-2" /> {searchresult.msgCreationTime}  
      <Badge className='m-3 ' color={searchresult.msgStatus==="New"?"primary":searchresult.msgStatus==="Closed"?"success":searchresult.msgStatus==="Cancelled"?"warning":"info"}>
      {searchresult.msgStatus}
      </Badge>
      </CardSubtitle>
      <CardText>
        {searchresult.msgDescription}
        <br />
        {searchresult.fileAttached && searchresult.attachmentName?<span> <FontAwesomeIcon icon={faPaperclip} className="me-2" onClick={(event)=>{handleAttachmentDownload(event, searchresult)}}/> {searchresult.attachmentName}  </span>:null}
      </CardText>
      </CardBody>
      </td>
      <td className='card align-items-center' style={{flexDirection:'row', height:'150px', justifyContent:'space-between', border:'2px solid white'}}>
      <FontAwesomeIcon icon={faEye} id="TooltipExample" className="me-2" onClick={(event)=>{handleViewRequest(event, searchresult)}}/>
      <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleUpdateRequest(event, searchresult,"Cancelled")}}/>
    {loggedUser.isAdmin? <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleUpdateRequest(event, searchresult, "Resolved")}}/>:null}
      </td>
      </tr>              
    ))}
        </tbody>
        </Table>:<h3>No Queries Found</h3>}

    </div>
  )
}
