import React,{useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
//import { TransactionsTable } from "../../components/Tables";
import { CSVLink } from "react-csv";
import {downloadFeeHeaders} from '../../ProData';
import ListFeeDetails from "./ListFeeDetails";
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";

//import { faSearch, faEye, faEdit, faDownload, faFileDownload } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const { alertClose, loggedUser, validateLogin } = useContext(ProductContext);
  const [applicationDetails, setApplicationDetails]=useState([]);
  const csvLinkEl = React.createRef();
  const [tabValue, setTabValue]=useState("Home")
  //const [selectedBatch, setSelectedBatch] = useState('');
  const componentRef = React.useRef(null);
  //const [batchYear, setBatchYear] = useState([]);

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  /*const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSelectedBatch(date_year);
    //fetchCurrentYearDetails(date_year);
  }*/


  return (
    <>
    <Helmet>
      <title>Fee Management - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Fee Management - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Fee Management - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Fee Details</Breadcrumb.Item>
            <Breadcrumb.Item active>Fee Break Ups</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Manage Fees</h4>
       
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadFeeHeaders}
              filename="download.csv"
              data={applicationDetails}
              ref={csvLinkEl}
            />

          </ButtonGroup>
        </div>
      </div>

      {/*<div className="table-settings">
        <Row className="justify-content-between align-items-center">
        <Col md={6} className="mb-3">
        <Form.Group className="div-inline" id="searchstud">
           <Form.Select defaultValue="Cash"  value={selectedBatch} 
              onChange={(event)=>{setSelectedBatch(event.target.value)}}>
             {batchYear.map(item => {
                return (
                <option key={item} value={item}>{item}</option>
                );
              })}
           </Form.Select>
        </Form.Group>
      </Col>
      </Row>
            </div>*/}

      <div ref={componentRef} className="mb-4">
      <Tabs fill defaultActiveKey="Home" id="uncontrolled-tab-example"       
     activeKey={tabValue}
     onSelect={(k) => setTabValue(k)}
      className="mb-3">
    
    <Tab eventKey="Home" title="Pending">
   {tabValue==="Home"?<ListFeeDetails applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Pending"/>:null}
  </Tab>
  <Tab eventKey="Approved" title="Partial">
  {tabValue==="Approved"?<ListFeeDetails  applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Partial"/>:null}
  </Tab>
  <Tab eventKey="Declined" title="Full Payment">{tabValue}
  {tabValue==="Declined"?<ListFeeDetails  applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Full"/>:null}
  </Tab>
</Tabs>
      </div>

    </>
  );
};
