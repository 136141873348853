import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import { Auth } from 'aws-amplify';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
//import { faSearch, faEye, faEdit, faDownload, faEllipsisH, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Button} from '@themesberg/react-bootstrap';
import { Button, Table } from '@themesberg/react-bootstrap';
const config = require('../../config.json');

export default function ListFeeDetails(props) {
    const {applicationDetails, setApplicationDetails, status } = props;
    const { alertClose, alertDetails, setAlertDetails } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    //const [selectedItem, setSelectedItem]=useState('');
  //  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
  const [collapsed, setCollapsed] = useState(true);
 /* const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
    occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
    addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
    const [idProof, setIDProof]=useState('');
  const [certProof, setCertProof]=useState('');*/


    useEffect(()=>{
        //console.log("props : " , props);
        alertClose();
        fetchApplicationDetails();
    },[])

    const toggle = () => {
        //   console.log("clicked");
        
             setCollapsed(!collapsed);
         }

    const fetchApplicationDetails=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;      
           // const clientID="VJDEPTH";
            const fetchOption="FeeStatus";
           // await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-pending?clientID=${clientID}&fetchKey=${applicationStatus}`,{
            await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${status}&fetchOption=${fetchOption}`, {
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
                setApplicationDetails(response.data);
                var statusMsg="";
                if(status==="Full"){
                  statusMsg=" - Students Fees Fully Paid";
                }else if(status==="Partial"){
                  statusMsg=" - Students Phase 1 Fees completed";
                }else if(status==="Pending"){
                  statusMsg=" - Students Yet to make any Fees";
                }
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + statusMsg
                });  
              //  console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        }
    }

  return (
      <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*View*/}
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={toggle}>
                    </ModalHeader>
                    <ModalBody>    
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <Table hover responsive className="user-table align-items-center">
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Registration No
                </th>
                <th>
                    Name
                </th>
                <th>
                    Course Status
                    </th>
                <th>
                    Application Status
                </th>
                
                </tr>
            </thead>
            <tbody>
            {applicationDetails.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.registrationNo}
            </td>
            <td>
                {item.title}.{item.applicantName}
            </td>
            <td>
                {item.courseStatus}
            </td>
            <td>
                {item.applicationStatus}
            </td>
            
            </tr>)}
            </tbody>
            </Table>

    </>
  )
}
