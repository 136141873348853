import React, { useEffect, useState, useContext } from "react";
import { Button } from '@themesberg/react-bootstrap';
import {Alert, Table} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form } from 'react-bootstrap';
import { Auth } from "aws-amplify";
import axios from "axios";

import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import VideoUploader from "./VideoUploader";
//import PosterUploader from "./PosterUploader";
const config = require('../../config.json');

export default function AddSectionVideos(props) {
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, setVideoFormType, videoDetails, setVideoDetails } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    //const [viewOnly, setViewOnly] = useState(true);
    const [videoFile, setVideoFile] = useState();
    //const [posterFile, setPosterFile] = useState();
    const [localVideoPart, setLocalVideoPart]=useState({id:"A",title:"", uploadedBy:"", uploadedOn:""});
    const {selectedItem, setSelectedItem} = props;
    useEffect(()=>{
        if(!loggedUser.isAuthenticated){
        resetValues();
        validateLogin();
      }else{
        alertClose();
      }
        return () => {
            setVideoFormType("New");
            resetValues();
            alertClose();
          }
      },[])  

    /*const generateSubscriberID=()=>{
        const current_datetime = new Date();
        //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
        //const tfName= fName.substring(0,2).toUpperCase();
        //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
        //const tlName= lName.substring(0,2).toUpperCase();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
        return accountNo;
    }*/

    const handleVideoNewAdd=async(event)=>{
        event.preventDefault();
        try{
        alertClose();
        const validationResult=validateFields();
        if(validationResult==="passed"){
          setLoaded(false);
          //const tvideoid= videoDetails.videoID;
          const videoid=selectedItem.videoID;
          const todayDate=await generateDate();
          const tempPart=String.fromCharCode(65+(selectedItem.videoPartDetails.length));
          const tempVideoID=videoid+tempPart;
          const localVideoDetails=selectedItem.videoPartDetails;
          const localVideoObject={
            id:tempPart,
            title:localVideoPart.title,
            uploadedBy:loggedUser.name,
            updatedOn:todayDate
          };
          localVideoDetails.push(localVideoObject);
          //const {videoTitle, videoID, publishDate, videoStatus, courseCategory, description, remarks, endDate}=selectedItem;
          const tupdatedOn=await generateDate();
          //const videoid=videoID;
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const params={
            videoID: videoid, 
            videoPartDetails:localVideoDetails,
            updatedBy:loggedUser.name,
            updatedOn:tupdatedOn
          };
          //console.log("Params : ", params);

          await axios.patch(`${config.api.invokeURL}/video/addvideo`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              //console.log("response : ", response); 
              await postVideoOnS3(videoid, tempVideoID, videoFile);              
              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: videoid + " - Successfully Updated."
            }); 
            //props.toggle();
            //props.fetchVideoDetails();
            window.scrollTo(0,0);
            setLoaded(true);
            }).catch((error)=>{
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true);
            })
        }
        setLoaded(true);
          }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
        }
    }
    
    
    
    /*const handleVideoNew=async(event)=>{
      event.preventDefault();
      try{
      //  console.log("value : ", emailCodeEntered, emailCodeReceived);
      alertClose();
      const validationResult=validateFields();
      if(validationResult==="passed"){
        setLoaded(false);
        const tvideoid= await generateSubscriberID();
        const videoid="VID"+tvideoid;
        const todayDate=await generateDate();
        const tempPart=String.fromCharCode(65+(videoDetails.videoPartDetails.length));
        const tempVideoID=videoid+tempPart;
        const localVideoDetails=[];
        const localVideoObject={
          id:tempPart,
          title:localVideoPart.title,
          uploadedBy:loggedUser.name,
          updatedOn:todayDate
        };
        localVideoDetails.push(localVideoObject);
        const {videoTitle, publishDate,videoStatus, description, courseCategory, remarks, endDate }=videoDetails;
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        var tremarks="";
        var tendDate="";
        if(videoStatus==="Active"){
          tremarks="NA";
          tendDate="NA";
        }else{
          tremarks=remarks;
          tendDate=endDate;
        }
        const params={
          videoID: videoid, 
          videoTitle:videoTitle,
          publishDate:publishDate, 
          videoStatus:videoStatus, 
          description:description, 
          endDate:tendDate, 
          remarks:tremarks,
          courseCategory:courseCategory,
          sourceURL:"NA",
          updatedBy:loggedUser.name, 
          updatedOn:todayDate,
          videoPartDetails:localVideoDetails
        };
        //console.log("Params : ", params);
        await axios.post(`${config.api.invokeURL}/video/${videoid}`, params,{
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }})
          .then(async(response)=>{
            //console.log("Response : ", response);
            //await postOnS3(videoid, videoFile);
            await postVideoOnS3(videoid, tempVideoID, videoFile);
            //await postPosterOnS3(videoid, posterFile);

            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: videoid + " - Successfully Registered."
          }); 
          window.scrollTo(0,0);
          resetValues();
          setLoaded(true);
          }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
          })
          setLoaded(true);
      }
        }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      window.scrollTo(0,0);
      setLoaded(true);
      }
    }*/
  
    const postVideoOnS3 = async(videoid, videoFileName,timageFile)=>{
      try{
          var keyName="videos/"+videoid;
          var newFileName=videoFileName+".mp4";      
        var file = timageFile;
        var blob = file.slice(0, file.size, 'video/mp4'); 
        const newFile = new File([blob], newFileName, {type: 'video/mp4'});
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const bucketName=config.s3.docsbucketName;
        const fileType="video/mp4";
        await axios.get(`${config.api.invokeURL}/video/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}&keyName=${keyName}&fileType=${fileType}`,{
              headers:{
                  Authorization: access_token,
                  'x-api-key': config.api.key
              }
          }).then(async(response)=>{
              //console.log("Response : ", response);
            const res=await fetch(response.data.uploadURL, { method: 'PUT',
              headers:{
                  "Content-Type":"video/mp4"
                },body: newFile});
              //  console.log("Res : ", res);
                if(res.status===200){
              //  setNewProfileImage(newFileName);
                //  fetchGalleryImages();
                  // setProfileImage(newFileName);
                    //var timestamp = new Date().getTime();     
                  // var tprofile = document.getElementById("profile");
                    //var imgsrc="";
                    //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                    //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                  // tprofile.src=imgsrc;
                  //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                    //console.log("tprofle : ", tprofile.src);
                }
          }).catch((error)=>{
              //console.log("error:", error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
            // setDispMsg(error.message);
            // hideLoader();
          })
      
      }catch(error){
        //console.log("error1:", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      }
    
    }

    const removeVideoOnS3 = async(videoid, videoFileName)=>{
        try{
            var keyName="videos/"+videoid;
            var newFileName=videoFileName+".mp4";   
            keyName=keyName+"/"+videoid+newFileName;   
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const bucketName=config.s3.docsbucketName;
          const inputParams={
            bucketName:bucketName,
            keyName:keyName,
          }
          //console.log("Params : ", inputParams);
          await axios.post(`${config.api.invokeURL}/video/delvideo`, inputParams,{
                headers:{
                    Authorization: access_token,
                    'x-api-key': config.api.key
                }
            }).then(async(response)=>{
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message:"Video Successfully Deleted"
                }); 
            }).catch((error)=>{
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
            })
        
        }catch(error){
          //console.log("error1:", error);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        }
      }

    /*const postPosterOnS3 = async(pdfFileName,timageFile)=>{
      try{
        var keyName="videos/posters";
        var newFileName=pdfFileName+".jpeg";      
        const bucketName=config.s3.bucketName;
        //console.log("bucket name : ", bucketName);
        var file = timageFile;
        var blob = file.slice(0, file.size, 'image/jpeg'); 
        const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;   
        const fileType="image/jpeg";  
        await axios.get(`${config.api.invokeURL}/video/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}&keyName=${keyName}&fileType=${fileType}`,{
          headers:{
                  Authorization: access_token,
                  'x-api-key': config.api.key
              }
          }).then(async(response)=>{
            //console.log("Response : ", response);
            const res=await fetch(response.data.uploadURL, { method: 'PUT',
              headers:{
                  "Content-Type":"image/jpeg"
                },body: newFile});
                if(res.status===200){
                }
          }).catch((error)=>{
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
              window.scrollTo(0,0);   
          })
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
      window.scrollTo(0,0);   
      }
  
    }*/
  
    const resetValues=()=>{
      const todayDate= generateDate();
      setVideoDetails({...videoDetails, videoID:'', videoTitle:'', publishDate:todayDate, videoStatus:'Active', description:'', endDate:todayDate, remarks:'', courseCategory:'', sourceURL:''});
    }
  
    const validateFields=()=>{
      //const {videoTitle,publishDate, description, courseCategory, videoStatus}=videoDetails;      
          var returnValue="passed";
          if(!videoFile ){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Attach Video File"
          }); 
          returnValue="failed";
        }else  if(localVideoPart.title===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Video Uploaded Title cannot be empty"
      }); 
    returnValue="failed";
    }else {
      returnValue="passed";
    }
    window.scrollTo(0,0);
    return returnValue;
    }

    const handleDeleteVideoPart=async(event, titem)=>{
      event.preventDefault();
      try{
      setLoaded(false);
      //console.log("Item : ", titem);
      //console.log("videoDetails :", selectedItem.videoPartDetails);
      let tempCart = [...selectedItem.videoPartDetails];
      tempCart = tempCart.filter(item => item.id !==titem.id);
     // console.log("temp cart :", tempCart);
     await setSelectedItem({
      ...selectedItem,
      videoPartDetails:[...tempCart]
    });
    const tupdatedOn=await generateDate();
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    const params={
      videoID: selectedItem.videoID, 
      videoPartDetails:[...tempCart],
      updatedBy:loggedUser.name,
      updatedOn:tupdatedOn
    };
    //console.log("Params : ", params);

    await axios.patch(`${config.api.invokeURL}/video/addvideo`, params,{
      headers: {
        Authorization: access_token,
        'x-api-key':config.api.key
      }})
      .then(async(response)=>{
        await removeVideoOnS3(selectedItem.videoID, titem.id);              
        //console.log("response : ", response); 
        //await removeVideoOnS3(videoid, tempVideoID);              
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: selectedItem.videoID + " - Successfully Updated."
      }); 
      //props.toggle();
      //props.fetchVideoDetails();
      window.scrollTo(0,0);
      setLoaded(true);
      }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Error Occured : " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
      })
      setLoaded(true);
    }catch(error){
        //console.log("error1:", error);
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      }
  }

    
  
  return (
    <>
    <div>Add Videos</div>
    <div>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
     
      <div>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        <Card>
          <Row className='container p-2'>
                <Col sm={4} className="mb-3">
                <Form.Group id="videoTitle">
                <Form.Label>Video Title</Form.Label>
                <Form.Control required type="text"  value={localVideoPart.title}
                    onChange={(event)=>{setLocalVideoPart({...localVideoPart,
                    title:event.target.value})}}
                />
              </Form.Group>
                  </Col>
                    <Col sm={6} className="mb-3">
                 <VideoUploader fileName="test"  alertDetails={alertDetails} setAlertDetails={setAlertDetails} setVideoFile={setVideoFile}/>
                 </Col> 
                {/*} <Col sm={2} className="mb-3">
                 <div className="mt-3 text-center">
                  <Button variant="primary" type="submit" onClick={(event)=>{handleAddVideo(event)}}>Add Video</Button>
                </div> 
                    </Col>  */}       
          </Row>
        {selectedItem.videoPartDetails ?
        <Table hover responsive>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
            Title
            </th>
            <th>
              Date Uploaded
            </th>
            <th>
            Uploaded By
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {selectedItem.videoPartDetails.map((item, index)=>
          <tr key={index}>
            <th scope="row">
            {index+1}
            </th>
            <td>
            {item.title}
            </td>
            <td>
              {item.uploadedBy}
            </td>
            <td>
              {item.updatedOn}
            </td>
            <td><FontAwesomeIcon icon={faTrash} onClick={event=>{handleDeleteVideoPart(event, item)}}/></td>
          </tr>)}
          </tbody>
        </Table>:null}
          </Card>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleVideoNewAdd(event)}}>Save</Button>
          </div>
</Form>
</Card.Body>
</Card>
</div>
</div>
</>
)
}
