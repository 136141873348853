import React,{useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Row, Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from "react-router-dom";
//import { TransactionsTable } from "../../components/Tables";
//import ListRegistrationStatus from "./ListRegistrationStatus";
import { CSVLink } from "react-csv";
import {downloadAssessmentHeaders} from '../../ProData';
import ListEvaluation from "./ListEvaluation";
import CompletedEvaluation from "./CompletedEvaluation";
import {Routes} from '../../routes';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";

//import { faSearch, faEye, faEdit, faDownload, faFileDownload } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const [applicationDetails, setApplicationDetails]=useState([]);
  const csvLinkEl = React.createRef();
  const [tabValue, setTabValue]=useState("Home")
  const { alertClose, loggedUser, validateLogin } = useContext(ProductContext);
  const componentRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
      validateLogin();
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  return (
    <>
    <Helmet>
      <title>Evaluation - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Evaluation - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Evaluation - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>      <Link to={Routes.LandingPage.path}>
            <FontAwesomeIcon icon={faHome} /></Link>    </Breadcrumb.Item>
            <Breadcrumb.Item>Evaluation</Breadcrumb.Item>
            <Breadcrumb.Item active>Manage Evaluation</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Manage Evaluation</h4>
       
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Evaluation"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadAssessmentHeaders}
              filename="download.csv"
              data={applicationDetails}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
       
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          {/* <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
         <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>*/}
        </Row>
      </div>

<div ref={componentRef} className="table-settings mb-4">
<Tabs fill defaultActiveKey="Home" id="uncontrolled-tab-example"       
     activeKey={tabValue} onSelect={(k) => setTabValue(k)} className="mb-3">
  <Tab eventKey="Home" title="Pending Evaluation">
   {tabValue==="Home"?<ListEvaluation applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Pending Evaluation"/>:null}
  </Tab>
  <Tab eventKey="Initiated" title="Initiated">
   {tabValue==="Initiated"?<ListEvaluation applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Initiated"/>:null}
  </Tab>
  <Tab eventKey="Approved" title="Completed Evaluation">
  {tabValue==="Approved"?<CompletedEvaluation />:null}
  </Tab>
</Tabs>
</div>
    </>
  );
};
