import React, {useEffect, useState, useContext} from 'react';
import { faEye, faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import MCQModal from '../exam/MCQModal';
import YesNoModal from '../exam/YesNoModal';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
//import ScoreView from '../assessment/ScoreView';
//import { sampleQuestionTable } from '../../ProData';
//import userEvent from '@testing-library/user-event';
import QuestionSheetForm from '../exam/QuestionSheetForm';
import AssignExamForm from '../exam/AssignExamForm';
import DescriptiveModal from '../exam/DescriptiveModal';
const config = require('../../config.json');


export default function ShowSubjectExam(props) {
  const {courseCode} = props;
  const { alertClose, alertDetails, setAlertDetails } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [initials, setInitials] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [questionCollapsed, setQuestionCollapsed] = useState(true);
  const [assignExamCollapsed, setAssignExamCollapsed]= useState(true);
  const [questionID, setQuestionID]=useState();
  const [formType, setFormType] = useState("View");
  const [questionTable, setQuestionTable] = useState([]);
  const [displayQuestions, setDisplayQuestions]=useState([]);

  useEffect(()=>{
    fetchData(courseCode);
    setInitials(true);
  },[])

  const fetchData=async()=>{
    try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //const clientID="VJDEPTH";
      //const applicationStatus=status;
      //const fetchid="All";
      await axios.get(`${config.api.invokeURL}/dashboard/exam/list-all-questions?fetchid=${courseCode}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
          //console.log("Res : ", response);
          setQuestionTable(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length +" - Questions Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          //console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
      })  
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
  }

  const handleQuestionDisplay =(event, item, mode)=>{
    event.preventDefault();
    console.log("Item :", item);
    setQuestionID(item);
    setDisplayQuestions(item.assessmentQuestions);
    setFormType(mode);
    toggle();
  }

  const toggle = () => {
      // console.log("clicked");
         setCollapsed(!collapsed);
  }

  const questionToggle = () => {
  // console.log("clicked");
      setQuestionCollapsed(!questionCollapsed);
  }

  const assignExamToggle = () => {
  // console.log("clicked");
      setAssignExamCollapsed(!assignExamCollapsed);
  }

  const handleQuestionSheet=(event)=>{
    event.preventDefault();
    questionToggle();

  }

  /*const handleAssignExam=(event,item)=>{
    event.preventDefault();
  //  console.log("item :",item);
    setQuestionID(item);
    assignExamToggle();

  }*/

  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

      {/*View && Edit*/}
      <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
              <ModalHeader toggle={toggle}>
              {questionID?<p>{questionID.courseName} - {questionID.assessmentCategory} Exam</p>:null}
              </ModalHeader>
              <ModalBody> 
                {questionID && questionID.assessmentType==="YesNo"? 
                <YesNoModal questionID={questionID} formType={formType} displayQuestions={displayQuestions}/>:
                (questionID && questionID.assessmentType==="MCQ")
                ?<MCQModal questionID={questionID} formType={formType} displayQuestions={displayQuestions}/>:
                <DescriptiveModal questionID={questionID} formType={formType} displayQuestions={displayQuestions}/>}
              </ModalBody>
              <ModalFooter>
          {' '}
          <Button onClick={toggle}>
              Close
          </Button>
          </ModalFooter>
      </Modal>
      
      {/*New Question Sheet*/}
      <Modal toggle={questionToggle} isOpen={!questionCollapsed} style={{top:'10%'}}>
              <ModalHeader toggle={questionToggle}>
              New Question Paper
              </ModalHeader>
              <ModalBody>  
                <QuestionSheetForm questionTable={questionTable}/>
              </ModalBody>
              <ModalFooter>
          {' '}
          <Button onClick={questionToggle}>
              Close
          </Button>
          </ModalFooter>
      </Modal>

      {/*Assign Exam*/}
      <Modal toggle={assignExamToggle} isOpen={!assignExamCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={assignExamToggle}>
                      <p>Assign Exam</p>
                    </ModalHeader>
                    <ModalBody>  
                      <AssignExamForm questionID={questionID}/>
                    </ModalBody>
                    <ModalFooter>
                {' '}
                <Button onClick={assignExamToggle}>
                    Close
                </Button>
                </ModalFooter>
      </Modal>

    <div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} variant="secondary" className="text-dark me-2" onClick={(event)=>{handleQuestionSheet(event)}}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
          {/*<div className="d-flex">
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Active"
          />
  </div>*/}
          </div>
        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Question ID
                </th>
                <th>
                    Status
                </th>
                <th>
                    Assessment Type
                </th>
               <th>
                    Action
                </th>
                
                </tr>
            </thead>
            <tbody>
            {initials && questionTable.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>
                {item.questionID}
            </td>
            <td>
                {item.assessmentStatus}
            </td>
            <td>
               {item.assessmentType}
            </td>
            
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleQuestionDisplay(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Questions
              </Dropdown.Item>
             {/*} <Dropdown.Item className="text-success" onClick={(event)=>{handleQuestionDisplay(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Edit Questions
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleAssignExam(event, item)}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Assign Exam
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleQuestionDisplay(event, item, "Exam")}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Exam Mode
            </Dropdown.Item>*/}
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>
    </div>
    </>
  )
}
