import React, {useState, useContext, useEffect} from 'react';
import RegistrationForm from './RegistrationForm';
import { Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import CourseSelection from './CourseSelection';
import ApplicationStatus from './ApplicationStatus';
import IDProof from './IDProof';
import CertificateProof from './CertificateProof';
import AcademicInfo from './AcademicInfo';
import { Col, Row, Button } from 'react-bootstrap';
import axios from "axios";
import {Auth} from 'aws-amplify';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;



export default function NewRegistration() {

    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin } = useContext(ProductContext);
    const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
    occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'+91',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
    addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
    const [loaded, setLoaded] = useState(true);
    const [optedCourse, setOptedCourse]=useState({courseName:'',courseCategory:''});
    const [selectedCourse, setSelectedCourse]=useState({});
    const [isCourseShortlisted, setIsCoursseShortlisted]= useState(false);
    const [applicationProcessed, setApplicationProcessed]=useState(false);
    const [app, setApp]=useState({appNo:'', appMsg:''});
    const [idProofFile, setIDProofFile]= useState('');
    const [certProofFile, setCertProofFile]=useState('');
    const [imageFile, setImageFile] = useState('');

    useEffect(()=>{
      if(loggedUser.isAuthenticated){
        alertClose();
        resetValues();
      }else{
      validateLogin();
      alertClose();
      resetValues();
      }
      return()=>{
        alertClose();
        resetValues();
      }
    },[loggedUser])

    const generateApplicationNo=()=>{
        const current_datetime = new Date();
        const fName=profileDetails.firstName.trim().replace(/\s/g, "");
        //const tusername=memberInfo.name.trim().replace(/\s/g, "");
        const tfName= fName.substring(0,2).toUpperCase();
        const lName=profileDetails.lastName.trim().replace(/\s/g, "");
        //const tusername=memberInfo.name.trim().replace(/\s/g, "");
        const tlName= lName.substring(0,2).toUpperCase();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,tfName, tlName);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
      
    }

    const handleNewForm=()=>{
        resetValues();
        setApp({...app, appNo:'', appMsg:''});
        setApplicationProcessed(false);
        alertClose();
      }

      const resetValues=()=>{
        setProfileDetails({...profileDetails,title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
        occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'+91', mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'Delhi',
        addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent', degreeDetails:[]});
        setImageFile('');
        setIDProofFile('');
        setCertProofFile('');
        //setEmailValidated(false);
        setIsCoursseShortlisted(false);
      //  setIsEmailValidated(false);
       // setIsFormSubmitted(false);
       // initializeValues();
      }

      const validateFields=()=>{
        const {title,firstName, dateOfBirth, gender, maritialStatus,
          occupation, religion, mobileNumber, emailID, addressStreet, addressState,
          addressPincode, nationality, motherTongue, englishProficiency}=profileDetails;
          var returnValue="passed";
          if(!emailID.match(validRegex)){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Email address is not in right format!"
          }); 
          returnValue="failed";
          }else if(title===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Title cannot be Empty"
          }); 
          returnValue="failed";
        }else  if(firstName===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Name cannot be Empty"
        }); 
        returnValue="failed";
      }else  if(dateOfBirth===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Date of Birth cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(gender===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Gender cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(maritialStatus===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Maritial Status cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(occupation===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Occupation cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(religion===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Religion cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(mobileNumber===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Mobile Number cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(emailID===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Email Address cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(addressState===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Address State cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(addressStreet===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Address cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(addressPincode===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Address pincode cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(nationality===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Nationality cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(motherTongue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Mother Tongue cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(englishProficiency===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "English Proficiency cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(imageFile===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Attach your Photo. Photo is must for registration!"
      }); 
      returnValue="failed";
      }else  if(idProofFile===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Attach one Govt approved photo ID proof! Its must."
      }); 
      returnValue="failed";
      }else  if(certProofFile===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Attach UG/PG certificate. It's mandatory for registration."
      }); 
      returnValue="failed";
      }else  {
        returnValue="passed";
      
      }
      window.scrollTo(0,0);
      return returnValue;
      }

      const postOnS3 = async(applicationno,timageFile,inputType)=>{
        try{
            //  console.log("NewImage : ", newImage);
            var tKeyName="";
            var newFileName="";
            if(inputType==="Profile"){
              tKeyName="avatar/"+applicationno;   
              newFileName=applicationno+".jpeg";
            }else if (inputType==="IDProof"){
              tKeyName="avatar/"+applicationno+"/docs";  
              newFileName=applicationno+"_idproof.jpeg"; 
            }else if (inputType==="CERTProof"){
              tKeyName="avatar/"+applicationno+"/docs";  
              newFileName=applicationno+"_certproof.jpeg"; 
            }   
          
          //console.log("newfilename :", newFileName);
         // const newImage="";
          var file = timageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
         // console.log("New file : ", newFile);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const bucketName=config.s3.bucketName + tKeyName;
         // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
         // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
          await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
               headers:{
                   Authorization: access_token,
                   'x-api-key': config.api.key
               }
           }).then(async(response)=>{
             //  console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"image/jpeg"
                 },body: newFile});
               //  console.log("Res : ", res);
                 if(res.status===200){
                //  setNewProfileImage(newFileName);
                  //  fetchGalleryImages();
                    // setProfileImage(newFileName);
                     //var timestamp = new Date().getTime();     
                    // var tprofile = document.getElementById("profile");
                     //var imgsrc="";
                     //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                     //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                     //console.log("tprofle : ", tprofile.src);
                 }
            }).catch((error)=>{
               // console.log("error1:", error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
             // setDispMsg(error.message);
             // hideLoader();
           })
        
        }catch(error){
         // console.log("error1:", error);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        }
      
      }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
        //  console.log("value : ", emailCodeEntered, emailCodeReceived);
        alertClose();
        const validationResult=validateFields();
        if(validationResult==="passed"){

          setLoaded(false);
          const {title,firstName, middleName, lastName, dateOfBirth, gender, maritialStatus,
          occupation, religion, denomination, diocese, areaCode, mobileNumber, emailID, landlineNumber, addressStreet, addressState,
          addressPincode, nationality, motherTongue, englishProficiency, degreeDetails}=profileDetails;
          const clientID = "VJDEPTH";
          const applicationno= await generateApplicationNo();
          const applicationDate=await generateDate();
          const applicationStatus="Pending Approval";
          const avatar= applicationno+".jpeg";
          const idproofFile=applicationno+"_idproof.jpeg"; 
          const certproofFile=applicationno+"_certproof.jpeg";  
          const tcourseStatus="Pending Approval";
          const tcoursePayment="Pending";   
          const tapplicantName=firstName+ " "+middleName+ " "+lastName;    
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;

          const params={
            clientID:clientID, applicationNo: applicationno, applicationDate: applicationDate, applicationStatus:applicationStatus,
            title:title,firstName:firstName, middleName:middleName, lastName:lastName, dateOfBirth:dateOfBirth, gender:gender, maritialStatus:maritialStatus,
            occupation:occupation, religion:religion, denomination:denomination, diocese:diocese, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, landlineNumber:landlineNumber, addressStreet:addressStreet, addressState:addressState,
            addressPincode:addressPincode, nationality:nationality, motherTongue:motherTongue, englishProficiency:englishProficiency, selectedCourse:selectedCourse, degreeDetails:degreeDetails, avatar:avatar, idproofFile:idproofFile, 
            certproofFile:certproofFile, courseStatus:tcourseStatus, coursePayment:tcoursePayment, applicantName:tapplicantName};
          //console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
           //   console.log("response : ", response); 
              await postOnS3(applicationno, imageFile,"Profile");
              await postOnS3(applicationno, idProofFile,"IDProof");
              await postOnS3(applicationno, certProofFile,"CERTProof");
              setApplicationProcessed(true);
              setApp({...app, appNo:applicationno, appMsg:"Congratulations and Your Application Registration is successful. Your application will be reviewed throughly and further updates will be shared via Email. You can also get the latest updates from our portal."})
              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: applicationno + " - Successfully Registered."
            }); 
            window.scrollTo(0,0);
            resetValues();
            setLoaded(true);
            }).catch((error)=>{
           //   console.log("error : ", error);
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true);
            })
        }
          
          }catch(error){
      //      console.log("error : ", error);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
        }
      }

  return (
      <>
          <Helmet>
      <title>Student Registration - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Student Registration - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Student Registration - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {!isCourseShortlisted && !applicationProcessed?
        <CourseSelection optedCourse={optedCourse} setOptedCourse={setOptedCourse} setIsCoursseShortlisted={setIsCoursseShortlisted} setSelectedCourse={setSelectedCourse}/>
        :null}
        { isCourseShortlisted ? 
        <div>
    <RegistrationForm formType={"Edit"} profileDetails={profileDetails} setProfileDetails={setProfileDetails} setImageFile={setImageFile}/>
    <Row>
            <AcademicInfo  formType={"Edit"} form={profileDetails} setForm={setProfileDetails} alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
          </Row>
          <Row className="p-4">
            <Col md={6}>
            <IDProof fileName="test"  alertDetails={alertDetails} setAlertDetails={setAlertDetails} setIDProofFile={setIDProofFile}/>
          
            </Col>
            <Col md={6}>
            <CertificateProof fileName="test" alertDetails={alertDetails} setAlertDetails={setAlertDetails} setCertProofFile={setCertProofFile}/>

            </Col>
          </Row>
          
          <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
          </div>
       
    </div>
    :null}
    {applicationProcessed  ? <ApplicationStatus app={app}  handleNewForm={handleNewForm}/>:null}
    </>
  )
}
