import React, { useEffect, useContext,useState } from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { Card  } from "reactstrap";
import { Col, Row } from '@themesberg/react-bootstrap';
//import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { ChartsGoogle } from './ChartsGoogle';

const config = require('../../config.json');

export default function CoursePaymentInsights() {
    const { alertDetails, setAlertDetails } = useContext(ProductContext);
    //const [coursePInsights, setCoursePInsights] = useState('');
    const [loaded, setLoaded] = useState(true);
    //const [regYearCount, setRegYearCount] = useState();
    const [googleRegData, setGoogleRegData]= useState();
    const [isRegYearCount, setIsRegYearCount]=useState(false);
    useEffect(()=>{
        fetchRegDetails()
    },[])

    const fetchRegDetails=async()=>{
        try{      
            
              setLoaded(false);
              const session = await Auth.currentSession();
              const access_token=session.idToken.jwtToken;  
            await axios.get(`${config.api.invokeURL}/dashboard/snapshot/coursepayment`, {
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }
            }).then(async(response) => {
            // console.log("response : ", response);
             setGoogleRegData(response.data);
             setIsRegYearCount(true);

            // processData(response.data)
              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length + " - student's records found!"
            });  
              setLoaded(true);
      
          }).catch((error)=>{
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          });        
          setLoaded(true);
        })
      
          }catch(error){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          });        
          setLoaded(true);
          }
        }
     
    return (
            <>
                            <Preloader show={loaded ? false : true} />

    {/*sRegYearCount?
    <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Registration (Last 5 years)"
            value="10,567"
            percentage={10.57}
            regYearCount={regYearCount}
          />
        </Col>
        </Row>:null*/}
       
        {isRegYearCount?
    <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <ChartsGoogle regYearCount={googleRegData} titleMsg="Payment Status"/>
                  </Col>
        </Row>:null}
       
    </>
  )
}
