import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Table } from '@themesberg/react-bootstrap';
//import {Input } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { courseMaterialData } from '../../ProData';
import Preloader from "../../components/Preloader";
import ProgressBarStatus from "../../components/ProgresssBarStatus";
import LessonJourney from "./LessonJourney";
import {Helmet} from 'react-helmet-async';

//import { TransactionsTable } from "../../components/Tables";
const config = require('../../config.json');

export default () => {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [batchYear, setBatchYear] = useState([]);
  const [selectedBatch, setSelectedBatch]=useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loaded, setLoaded]=useState(true);
  const componentRef = React.useRef(null);
  const [viewJourneyCollapsed, setViewJourneyCollapsed]=useState(true);
  const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
    occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
    addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
  //const [collapsed, setCollapsed]=useState(true);
  //const [historyCollapsed, setHistoryCollapsed]=useState(true);

  //const [selectedItem, setSelectedItem] = useState('');
  //const [courseOpted, setCourseOpted] = useState('');
  //const [payMsg, setPayMsg] = useState('');
  //const [currentPayStatus, setCurrentPayStatus] = useState('');
  //const [payAmount, setPayAmount] = useState('');
  //const [enrollCourse, setEnrollCourse]=useState({});
  //const [feeBreakUp, setFeeBreakUp] =useState([]); 
  //const [searchParam] = useState(["firstName","applicationNo"]);
  
  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSelectedBatch(date_year);
    fetchCurrentYearDetails(date_year);
  }

  const fetchCurrentYearDetails=async(searchValue)=>{
   // event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        //const searchTopic="BatchFeeStatus";
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken; 
        //const searchValue="21-VJD-1530";
      //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        await axios.get(`${config.api.invokeURL}/dashboard/scores/batchwise?batchno=${searchValue}`, {  
           headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       // console.log("Response : ", response);
        if(response.data.length>0)
        {
       //     console.log("order status")
            //const tTempResponse=response.data;
            initializeValues(response.data);
           // var courseCompleted=0;
          /*  for(const i in tTempResponse){
                if(tTempResponse[i].assessmentResult==="PASS"){
                    //console.log("value : ", tTempResponse[i].registrationNo);
                    courseCompleted=courseCompleted+1;
                }
            }*/
              //const tProgressValue=((courseCompleted/tTempResponse.length)*100).toFixed(2);
              //setProgressValue(tProgressValue);
              
           // setSearchResults(response.data);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: searchValue + " Batch - courses completion details loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const initializeValues=async(rawData)=>{
    var uniqueArray = removeDuplicates("registrationNo",rawData );
    var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
   // setCoursesAvailable(sortedcourseArray);
   // console.log("Courses Name : ", sortedcourseArray);
    await progressValueCreation(sortedcourseArray, rawData);
    /*uniqueArray = removeDuplicates("courseOption");
    var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
    setCourseOptionAvailable(sortedOptionArray);
    setOptedCourse({...optedCourse,
      courseName:sortedcourseArray[0].courseName,
      courseOption:sortedOptionArray[0].courseOption
    });*/
  }

  const progressValueCreation=(sortedcourseArray, rawData)=>{
     //console.log("inside progress value creation");
      var progressObject={};
      var progressArray=[];
      const totalCourse1 = courseMaterialData.filter((item) => item.courseCode.startsWith("1"));
      const totalCourse2 = courseMaterialData.filter((item) => item.courseCode.startsWith("2"));
      for(const item in sortedcourseArray){
        var courseCompleted=0;
        var totalCourses=0;
        var course1Completed=0;
        var course2Completed=0;

        for(const i in rawData){
            if(sortedcourseArray[item].registrationNo===rawData[i].registrationNo){
                if(rawData[i].assessmentResult==="PASS"){
                   // console.log("value : ", rawData[i].registrationNo);
                    courseCompleted=courseCompleted+1;
             }
             if(rawData[i].assessmentResult==="PASS"&& rawData[i].courseCode.startsWith("1")){
              // console.log("value : ", rawData[i].registrationNo);
               course1Completed=course1Completed+1;
              } else if(rawData[i].assessmentResult==="PASS"&& rawData[i].courseCode.startsWith("2")){
                     course2Completed=course2Completed+1;
              }
             totalCourses=totalCourses+1;
            }
        }
        const tcompleted=(courseCompleted/totalCourses)*100;
        const tcompleted1=(course1Completed/totalCourse1.length)*100;
        const tcompleted2=(course2Completed/totalCourse2.length)*100;
        progressObject.registrationNo=sortedcourseArray[item].registrationNo;
        progressObject.courseCompleted=courseCompleted;
        progressObject.course1Completed=course1Completed;
        progressObject.course2Completed=course2Completed;
        progressObject.totalCourses=totalCourses;
        progressObject.completedPercentage=tcompleted;
        progressObject.completedPercentage1=tcompleted1;
        progressObject.completedPercentage2=tcompleted2;

        progressArray.push(progressObject);
        progressObject={};
      }
      //console.log("Array : ", progressArray);
      setSearchResults(progressArray);
  }

  const removeDuplicates=(tprop, coursesOffered)=> {
    var newArray = [];
    var lookupObject  = {};
    for(var i in coursesOffered) {
       lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
       //console.log("item : ",coursesOffered[i][tprop] );
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

  const fetchApplicationDetails=async(event, searchValue)=>{
    event.preventDefault();
    fetchCurrentYearDetails(searchValue);
   /* try{
      if(batchYear===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const searchTopic="Batch";
      await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
       //   Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
      // console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
       //     console.log("order status")
            setSearchResults(response.data);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Profile loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }*/
  }

  const journeyToggle = () => {
    //   console.log("clicked");
          setViewJourneyCollapsed(!viewJourneyCollapsed);
      }

  const handleJourneyView =(event, item)=>{
    event.preventDefault();
    //console.log("item : ", item);
    setProfileDetails(item);
    setViewJourneyCollapsed(!viewJourneyCollapsed);
  }



  /*   const [q, setQ] = useState("");

     function search(items) {
      return items.filter((item) => {
          return searchParam.some((newItem) => {
              return (
                  item[newItem]
                      .toString()
                      .toLowerCase()
                      .indexOf(q.toLowerCase()) > -1
              );
          });
      });
  }*/

return (
    <>
        <Helmet>
      <title>Batch - Course - Admin Office - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Batch - Course - Admin Office - VJDepth - Distance Education Programme in Theology" />
      <meta name="keywords" content="Batch - Course - Admin Office - VJDepth - Distance Education Programme in Theology" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
      </Alert>
     {/*View Journey*/}
     <div>
        <Modal toggle={journeyToggle} isOpen={!viewJourneyCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={journeyToggle}>
                      Journey of {profileDetails.registrationNo}
                    </ModalHeader>
                    <ModalBody>    
                    <div className="text-center">
                    <LessonJourney profileDetails={profileDetails}/>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={journeyToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        </div>

        {/*Payment History
     <div >
          <Modal toggle={historyToggle} isOpen={!historyCollapsed} style={{top:'10%'}} >
          <div >
             <ModalHeader toggle={historyToggle}>
                    </ModalHeader>
                    <ModalBody>
                      <HistoryView selectedItem={selectedItem} />
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={historyToggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
     
        </div>*/}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item> Course Journey</Breadcrumb.Item>
            <Breadcrumb.Item active> Batch View</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Batch wise - course Completion Details</h4>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
      </div>*/}
      </div>

      <div className="table-settings">
       
      {/*<Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
        <Form.Group >
           <Form.Select defaultValue="Cash"  value={selectedBatch} 
                                  onChange={(event)=>{setSelectedBatch(event.target.value)}}>
             {batchYear.map(item => {
                                return (
                                <option key={item} value={item}>{item}</option>
                                );
                              })}
           </Form.Select>
             </Form.Group>
            </Col>
          <Col  className="mb-3" md={4}>
           <Button variant="primary" type="submit" onClick={(event)=>{fetchApplicationDetails(event, selectedBatch)}}>Search</Button>
         
       
          </Col>
        </Row>*/}

        <Row className="justify-content-between align-items-center">
        <Col md={4} className="mb-3">
          
        
        
      
       <Form.Group id="searchstud">
           <Form.Select defaultValue="Cash"  value={selectedBatch} 
                                  onChange={(event)=>{setSelectedBatch(event.target.value)}}>
             {batchYear.map(item => {
                                return (
                                <option key={item} value={item}>{item}</option>
                                );
                              })}
           </Form.Select>
             </Form.Group>
      </Col>
       <Col md={4}>
       <Button variant="primary" type="submit" onClick={(event)=>{fetchApplicationDetails(event, selectedBatch)}}>Search</Button>
         
       
         
     </Col>
         {/*} <Col className="mb-3" xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)}/>
              <Button variant="primary" type="submit" >Submit</Button>
            </InputGroup>
                            </Col>*/}
        </Row>
        </div>

      <Table ref={componentRef} hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Registration No
                </th>
                <th>
                    Coursed Completed
                </th>
                <th>
                    Phase 1 Progress
                    </th>  
                    <th>
                    Phase 2 Progress
                    </th>  
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {searchResults.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.registrationNo}
            </td>
            <td>
               {item.courseCompleted}
            </td>
            <td> 
            <span><ProgressBarStatus  progressValue={item.completedPercentage1} dispValue="Phase 1 - "/>  {item.completedPercentage1.toFixed(2)}%</span>
            </td>
            <td> 
            <span><ProgressBarStatus  progressValue={item.completedPercentage2} dispValue="Phase 2 - "/>  {item.completedPercentage2.toFixed(2)}%</span>
            </td>
            <td> 
            <i className='fa fa-play' style={{color:"blue"}} onClick={(event)=>{handleJourneyView(event, item)}}> </i>               </td>
            </tr>)}
            </tbody>
            </Table>
    </>
  );
};
