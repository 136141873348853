import React, {useState, useEffect, useContext} from 'react';
import {ProductContext} from "../../ProductProvider";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
import { Auth } from 'aws-amplify';
import axios from 'axios';
const config = require('../../config.json');

export default function AssignExamForm(props) {
    const {questionID, assignExamToggle} = props;
    const { alertClose, alertDetails, setAlertDetails, generateDate, loggedUser } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [batchYear, setBatchYear] = useState([]);
    const [selectedBatch, setSelectedBatch]=useState('');
    const [startDate, setStartDate]=useState('');
    const [endDate, setEndDate]=useState('');

    useEffect(()=>{
        alertClose();
        processBatchYear();
        setLoaded(true);
    },[])

    const processBatchYear=()=>{
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var tbatchYear=[];
        for(var i=0; i<10;i++){
          tbatchYear.push(date_year-i);
        }
        setBatchYear(tbatchYear);
        setSelectedBatch(date_year);
    }

    const validationCriteria=()=>{
      var returnValue="Failed";
      if(selectedBatch===""){
        returnValue= "Failed";
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Select the Batch"
        }); 
      }else if(startDate===""){
        returnValue= "Failed";
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Select the Start Date"
        }); 
      }else if(endDate===""){
        returnValue= "Failed";
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Select the Batch"
        }); 
      }else{
        returnValue="Passed";
      }
      return returnValue;
    }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
            const validationResult=await validationCriteria();
            if(validationResult==="Passed"){
            const todayDate=generateDate();
            const params={
                courseCode:questionID.courseCode,
                questionID:questionID.questionID,
                assessmentAssignedTo:selectedBatch,
                assessmentOpenDate:startDate,
                assessmentEndDate:endDate,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate,
                category:"AssignExam"
            }
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            await axios.patch(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Successfully Assigned"
                }); 
                window.scrollTo(0,0);
                  setLoaded(true);  
                  assignExamToggle();          
                })
                .catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })
              }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true); 
        }
    }

  return (
    <>
      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
      {alertDetails.message}
      </Alert>

      <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <h5 className="mb-4">Question Sheet</h5>
         <Form>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Course Name</Form.Label>
                 <Input type="text" name="courseName"
                                  value={questionID.courseName} 
                                  disabled
                                >
                  </Input>
               </Form.Group>
         </Row>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Assessment Type</Form.Label>
                 <Input type="text" name="courseOption"
                                 disabled
                                  value={questionID.assessmentType} 
                                >
                  </Input>
               </Form.Group>
         </Row>
    <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Select Batch</Form.Label>
                <Form.Select value={selectedBatch} 
                  onChange={(event)=>{setSelectedBatch(event.target.value)}}>
                  {batchYear.map(item => {
                    return (
                    <option key={item} value={item}>{item}</option>
                    );
                  })}
           </Form.Select>              
           </Form.Group>
            </Col>
    </Row>
    <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Start Date</Form.Label>
                <Input type="date" value={startDate}
                onChange={(event)=>{setStartDate(event.target.value)}}
                >
                </Input>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>End Date</Form.Label>
                <Input type="date" value={endDate}
                onChange={(event)=>{setEndDate(event.target.value)}}
                >
                </Input>
              </Form.Group>
            </Col>

    </Row>


         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Update</Button>
           </div>
         </Form>
         </Card.Body>
      </Card>
    </>
  )
}
