import React, { useEffect, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { faEllipsisH, faEraser, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Tab, Tabs } from 'react-bootstrap';

import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import AssignExamForm from './AssignExamForm';
const config = require('../../config.json');

export default function ShowAssignedExam() {
    const { alertClose, alertDetails, setAlertDetails, validateLogin, loggedUser, generateDate } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [questionTable, setQuestionTable] = useState([]);
    //const [tabValue, setTabValue]=useState("1st Semester");
    const [assignExamCollapsed, setAssignExamCollapsed]= useState(true);
    const [questionID, setQuestionID]=useState();


    useEffect(()=>{
        if(loggedUser.isAuthenticated){
            alertClose();
            fetchAssignedExam();
            }else{
              validateLogin();
            //history.push(Routes.DashboardOverview.path);
        }
    },[loggedUser])

    const fetchAssignedExam=async()=>{
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const clientID="VJDEPTH";
          //const applicationStatus=status;
          //const fetchid="All";
          const fetchOption="All";
          const batch="1";
          await axios.get(`${config.api.invokeURL}/dashboard/exam/assigned-exam?fetchid=${batch}&fetchOption=${fetchOption}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
            ).then((response)=>{
              //console.log("Res : ", response);
              setQuestionTable(response.data);
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length +" - Questions Found"
              });  
              //console.log(response);
              setLoaded(true);
          }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
          })  
      }catch(error){
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
      }
      }
      const assignExamToggle = () => {
        // console.log("clicked");
            setAssignExamCollapsed(!assignExamCollapsed);
        }

      const handleSubmit=async(event, item, type )=>{
        event.preventDefault();
        try{
            const todayDate=generateDate();
            var params="";
            if(type==="Clear"){
                params={
                    courseCode:item.courseCode,
                    questionID:item.questionID,
                    assessmentAssignedTo:"NA",
                    assessmentOpenDate:"NA",
                    assessmentEndDate:"NA",
                    updatedBy:loggedUser.nickname,
                    updatedOn:todayDate,
                    category:"AssignExam"
                }
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                await axios.patch(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
                    headers: {
                      Authorization: access_token,
                      'x-api-key':config.api.key
                    }})
                    .then(async(response)=>{
                    setAlertDetails({
                        ...alertDetails,
                        style:"success",
                        open:true,
                        message: "Successfully Cleared"
                    }); 
                    window.scrollTo(0,0);
                    fetchAssignedExam();
                    setLoaded(true);            
                    })
                    .catch((error)=>{
                        setAlertDetails({
                          ...alertDetails,
                          style:"warning",
                          open:true,
                          message: "Error Occured : " + error.message
                      }); 
                      window.scrollTo(0,0);
                      setLoaded(true);
                    })
    
            }else{
                setQuestionID(item);
                assignExamToggle();            
            }
            setLoaded(true);
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true); 
        }
    }


  return (
    <>
    <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

              {/*Assign Exam*/}
      <Modal toggle={assignExamToggle} isOpen={!assignExamCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={assignExamToggle}>
                    </ModalHeader>
                    <ModalBody>  
                      <AssignExamForm questionID={questionID} assignExamToggle={assignExamToggle}/>
                    </ModalBody>
                    <ModalFooter>
                {' '}
                <Button onClick={assignExamToggle}>
                    Close
                </Button>
                </ModalFooter>
      </Modal>

    <div>Assigned Exam's</div>
    {/*<div className="table-settings mb-4">
    <Tabs fill defaultActiveKey="Home" id="uncontrolled-tab-example"       
     activeKey={tabValue}
     onSelect={(k) => setTabValue(k)}
      className="mb-3">
    
    <Tab eventKey="1st Semester" title="1st Semester">
   {tabValue==="Home"?<ListRegistrationStatus applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Pending Approval"/>:null}
    </Tab>
    <Tab eventKey="2nd Semester" title="2nd Semester">
    {tabValue==="Approved"?<ListRegistrationStatus applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Approved"/>:null}
    </Tab> 
    </Tabs>
  </div>*/}
    <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Question ID</th>
                <th>Course Name</th>
                <th>Assigned Batch</th>
               <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {questionTable.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.questionID}</td>
            <td>{item.courseName}</td>
            <td>{item.assessmentAssignedTo}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleSubmit(event, item, "Clear")}} >
                <FontAwesomeIcon icon={faEraser} className="me-2" /> Clear
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleSubmit(event, item, "Reassign")}} >
                <FontAwesomeIcon icon={faRefresh} className="me-2" /> Reassign
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>
    </>
  )
}
