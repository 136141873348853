import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {Tooltip } from "reactstrap";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const config = require('../../config.json');

export default function ImageDisplay(props) {
   // const [view, setView]=useState(false);

   /* const toggle=()=>{
        setView(!view);
    }*/
    return (
        <div className="card box" style={{margin:'10px'}} key={props.doctorName}>
            {props.doctorName}
                        <img src={`${config.s3.invokeUrl}${props.imageSrc}`} alt="1" height="200" width="200" style={{borderRadius:'10px'}}/> <br />
                        <FontAwesomeIcon id="TooltipExample" icon={faTrash} className="me-2" onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}/>
                        {/*<Tooltip
                            flip
                            target="TooltipExample"
                            toggle={toggle}
                        >
                            Hello world!
    </Tooltip>*/}
                        {/*<button onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}>Remove</button>*/}

        </div>
    )
}
